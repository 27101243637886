import React, {
  useState,
  createContext,
  useRef,
  useEffect,
  useCallback,
} from 'react'
import { nanoid } from 'nanoid'
import dayjs from 'dayjs'
import useLocalStorageState from 'use-local-storage-state'
import toast from 'react-hot-toast'
import congrats from '../data/congrats'

const TodoContext = createContext()

export const TodoProvider = ({ children }) => {
  const [muteTodoCompleteDing, setMuteTodoCompleteDing] = useLocalStorageState(
    'muteTodoCompleteDing',
    false,
  )
  const timerRef = useRef(45 * 60)
  const todoTimerRef = useRef([])

  const defaultTodos = [
    {
      id: nanoid(5),
      task: 'Add New Task',
      isComplete: false,
      priority: '2',
      timer: 1,
      deadline: dayjs().format('YYYY-MM-DD'),
      timeLeft: 1 * 60,
      timeCompleted: 0,
    },
    {
      id: nanoid(5),
      task: 'Double Click to edit Task',
      isComplete: false,
      priority: '2',
      timer: 2,
      deadline: dayjs().format('YYYY-MM-DD'),
      timeLeft: 2 * 60,
      timeCompleted: 0,
    },
    {
      id: nanoid(5),
      task: 'Add widgets & customize',
      isComplete: false,
      priority: '2',
      timer: 2,
      deadline: dayjs().format('YYYY-MM-DD'),
      timeLeft: 2 * 60,
      timeCompleted: 0,
    },
    {
      id: nanoid(5),
      task: 'Change Background from settings',
      isComplete: false,
      priority: '1',
      timer: 5,
      deadline: dayjs().format('YYYY-MM-DD'),
      timeLeft: 5 * 60,
      timeCompleted: 0,
    },
    {
      id: nanoid(5),
      task: 'Change search-engine by clicking on search engine logo ',
      isComplete: false,
      priority: '1',
      timer: 5,
      deadline: dayjs().format('YYYY-MM-DD'),
      timeLeft: 5 * 60,
      timeCompleted: 0,
    },
    {
      id: nanoid(5),
      task: 'Explore Flow',
      isComplete: false,
      priority: '1',
      timer: 5,
      deadline: dayjs().format('YYYY-MM-DD'),
      timeLeft: 5 * 60,
      timeCompleted: 0,
    },
  ]

  const [todos, setTodos] = useLocalStorageState('todos', {
    defaultValue: defaultTodos,
  })
  const [finishedTodos, setFinishedTodos] = useLocalStorageState(
    'finishedTodos',
    { defaultValue: [] },
  )

  const [todo, setTodo] = useState({
    id: nanoid(5),
    task: '',
    priority: 4,
    isComplete: false,
    timer: 45,
    deadline: dayjs().format('YYYY-MM-DD'),
    timeLeft: 45 * 60,
    timeCompleted: 0,
  })
  //handle state while adding the task from form - AddTodo
  const handleChange = (e) => {
    setTodo({
      ...todo,
      [e.target.name]: e.target.value,
      timeLeft: timerRef.current.value * 60,
    })
  }

  //Add todo to the todos array
  const handleAddTodo = (e) => {
    e.preventDefault()
    setTodos([...todos, todo])
    setTodo({
      id: nanoid(5),
      task: '',
      priority: 4,
      isComplete: false,
      timer: '45',
      deadline: dayjs().format('YYYY-MM-DD'),
      timeLeft: 45 * 60,
      timeCompleted: 0,
    })
  }
  //Add todo to the top of todos array
  const handleAddTodoToTop = (e) => {
    e.preventDefault()
    setTodos([todo, ...todos])
    setTodo({
      id: nanoid(5),
      task: '',
      priority: 4,
      isComplete: false,
      timer: '45',
      deadline: dayjs().format('YYYY-MM-DD'),
      timeLeft: 45 * 60,
      timeCompleted: 0,
    })
  }

  // delete todo from todos array
  const handleDeleteTodo = (id) => {
    let updatedTodos = [...todos].filter((todo) => todo.id !== id)
    setTodos(updatedTodos)
    toast.success('Todo deleted')
  }
  const handleFinishedDelete = (id) => {
    let updatedFinishedTodos = [...finishedTodos].filter(
      (todo) => todo.id !== id,
    )
    setFinishedTodos(updatedFinishedTodos)
  }
  // Confetti on todo complete
  const canvasStyles = {
    position: 'fixed',
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  }

  const refAnimationInstance = useRef(null)

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance
  }, [])

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 1 },
        particleCount: Math.floor(300 * particleRatio),
      })
  }, [])

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 90,
      startVelocity: 70,
    })

    makeShot(0.3, {
      spread: 60,
    })

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 1.2,
    })

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.5,
    })

    makeShot(0.2, {
      spread: 100,
      startVelocity: 80,
    })
  }, [makeShot])

  // Oncomplete - change isComplete to true
  const handleComplete = (id) => {
    fire()
    const ding2 = new Audio(process.env.PUBLIC_URL + '/assets/sounds/ding.wav')
    ding2.volume = 0.1
    ding2.play()

    const newTodos = todos.map((todo) => {
      if (todo.id === id) {
        toast.success(congrats[Math.floor(Math.random() * congrats.length)])
        return {
          ...todo,
          isComplete: true,
        }
      }
      return todo
    })

    setTodos(newTodos)
    setIsRunning(false)

    const newFinishedTodo = newTodos.filter((todo) => todo.id === id)

    setFinishedTodos([newFinishedTodo[0], ...finishedTodos])

    const newUpdatedTodos = todos.filter((todo) => todo.id !== id)
    setTodos(newUpdatedTodos)
  }

  const handleUndoComplete = (id) => {
    const undoTodo = finishedTodos.map((todo) => {
      if (todo.id === id) {
        return {
          ...todo,
          isComplete: false,
        }
      }
      return todo
    })
    setFinishedTodos(undoTodo)
    const undoCompleteTodos = undoTodo.filter((todo) => todo.id === id)
    setTodos([...todos, undoCompleteTodos[0]])
    const newUpdatedFinishedTodos = finishedTodos.filter(
      (todo) => todo.id !== id,
    )
    setFinishedTodos(newUpdatedFinishedTodos)
  }
  useEffect(() => {
    todoTimerRef.current = todoTimerRef.current.slice(0, todos.length)
  }, [todos])

  const handleUpdate = (id, e, index) => {
    const newTodos = todos.map((todo) => {
      if (todo.id === id) {
        if (e.target.name === 'timer') {
          return {
            ...todo,
            [e.target.name]: e.target.value,
            timeLeft: todoTimerRef.current[index]?.value * 60,
          }
        } else {
          return {
            ...todo,
            [e.target.name]: e.target.value,
          }
        }
      }
      return todo
    })
    setTodos(newTodos)
  }

  const clearFinishedTodos = () => {
    setFinishedTodos([])
  }
  // ********************************** Timer (Pomodoro) context **************************************************//

  const [isRunning, setIsRunning] = useLocalStorageState('isRunning', {
    defaultValue: false,
  })

  const [timeLeftState, setTimeLeftState] = useState(todos[0]?.timeLeft || 2700) //In seconds

  // const [totalFocusedTime, setTotalFocusedTime] = useTotalFocusedTime(0);

  const handleReset = () => {
    setIsRunning(false)

    const updatedTodos = todos.map((todo, index) => {
      if (index === 0) {
        setCountdownTimer(dayjs().add(todos[0].timer, 'minutes'))
        return {
          ...todo,
          timeLeft: todo.timer * 60,
        }
      }
      return todo
    })
    setTodos(updatedTodos)
  }

  const handleChangeTime = (val) => {
    const updatedTodos = todos.map((todo, index) => {
      if (index === 0) {
        const newTime = parseFloat(todo.timeLeft / 60) + parseInt(val)
        setCountdownTimer(
          dayjs()
            .add(parseInt(newTime * 60), 'seconds')
            .format('YYYY-MM-DDTHH:mm:ss.sssZ'),
        )
        return {
          ...todo,
          timeLeft: parseInt(newTime * 60),
        }
      }
      return todo
    })
    setTodos(updatedTodos)
  }
  // const showTotalFocusedTime = () => {
  //   alert(`Total time spent on focused: ${totalFocusedTime}`);
  // };
  const handleTimerUpdate = useCallback(
    (timeDelta) => {
      const updatedTodos = todos.map((todo, index) => {
        if (index === 0) {
          return {
            ...todo,
            timeLeft: timeDelta.total / 1000,
            timeCompleted: todo.timeCompleted + 1,
          }
        }
        return todo
      })
      setTodos(updatedTodos)
    },
    [setTodos, todos],
  )

  const [timer, setTimer] = useState(todos[0]?.timer)
  const [timeLeft, setTimeLeft] = useState(todos[0]?.timeLeft)
  const [countdownTimer, setCountdownTimer] = useState(
    dayjs().add(timeLeft, 'seconds').format('YYYY-MM-DDTHH:mm:ss.sssZ'),
  )
  useEffect(() => {
    if (timeLeft !== todos[0]?.timeLeft || todos[0]?.timer !== timer) {
      setCountdownTimer(
        dayjs()
          .add(todos[0]?.timeLeft, 'seconds')
          .format('YYYY-MM-DDTHH:mm:ss.sssZ'),
      )
    }
  }, [timeLeft, timer, todos])

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600)
    const minutes = Math.floor(time / 60) % 60
    const seconds = time % 60
    return `${hours >= 1 ? `${hours}:` : ''}${
      minutes < 10 ? '0' + minutes : minutes
    }:${seconds < 10 ? '0' + seconds : seconds}`
  }

  const handleTimerComplete = () => {
    setIsRunning(false)
    if (!muteTodoCompleteDing) {
      const ding = new Audio(process.env.PUBLIC_URL + '/assets/sounds/ding.wav')
      ding.play()
    }
    // set todos[0].timeleft to 0
    const updatedTodos = todos.map((todo, index) => {
      if (index === 0) {
        return {
          ...todo,
          timeLeft: 0,
        }
      }
      return todo
    })
    setTodos(updatedTodos)
  }

  return (
    <TodoContext.Provider
      value={{
        timer,
        muteTodoCompleteDing,
        timeLeft,
        setCountdownTimer,
        countdownTimer,
        timeLeftState,
        handleTimerUpdate,
        // showTotalFocusedTime,
        isRunning,
        setIsRunning,
        handleReset,
        handleChangeTime,
        formatTime,
        timerRef,
        todoTimerRef,
        todos,
        todo,
        finishedTodos,
        canvasStyles,
        handleTimerComplete,
        getInstance,
        fire,
        handleUndoComplete,
        setTodos,
        handleChange,
        handleAddTodo,
        handleAddTodoToTop,
        handleDeleteTodo,
        handleFinishedDelete,
        handleComplete,
        handleUpdate,
        clearFinishedTodos,
        setMuteTodoCompleteDing,
      }}
    >
      {children}
    </TodoContext.Provider>
  )
}
export default TodoContext
