import axios from 'axios'
import React, { useContext } from 'react'

import { useQuery } from '@tanstack/react-query'
import MainContext from '../../../context/MainContext'
import { CheckCircleFill, PlayFill } from 'react-bootstrap-icons'

const BgVideoPanel = () => {
  const { setVideo, setBackgroundType } = useContext(MainContext)
  const [queryType, setQueryType] = React.useState('collections') // collections or search
  const [query, setQuery] = React.useState('nature')

  const videobgInstance = axios.create({
    baseURL: 'https://api.pexels.com/v1/',
    headers: {
      Authorization: `${process.env.REACT_APP_PEXELS_API_KEY}`,
    },
  })
  const fetchVideos = async () => {
    const response =
      queryType === 'collections'
        ? await videobgInstance.get('collections/vozyi7e?per_page=30')
        : await videobgInstance.get(
            `videos/search?query=${query}&per_page=40&orientation=landscape&size=medium&min_width=1920&min_height=1080&max_duration=45`,
          )
    const res =
      queryType === 'collections' ? response.data.media : response.data.videos
    return res
  }
  const { data, isLoading, isError, error } = useQuery(
    ['videos', query, queryType],
    fetchVideos,
  )
  const handlePexelVideo = (e, video) => {
    e.preventDefault()
    setVideo({
      url: video.video_files.filter(
        (video) =>
          (video.quality === 'hd' &&
            video.height >= '1920' &&
            video.width >= 1080) ||
          video.quality === 'hd',
      )[0].link,
      thumbnail: video.image,
      preview_url: video.video_files.filter(
        (video) => video.quality === 'sd',
      )[0].link,
      videographer: video.user.name,
      videographer_url: video.user.url,
      name: video.user.name,
      credit_url: video.url,
    })
    setBackgroundType('video')
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="z-10 flex flex-wrap gap-1 lg:gap-2 ">
        {/* TODO add tooltip photos by pexels */}
        <span>
          <img
            src="/assets/svg/pexels.svg"
            className="h-8 w-8"
            alt="pexels logo"
          />
        </span>
        <button
          onClick={() => {
            setQueryType('collections')
          }}
          className="btn-sec text-xs sm:text-sm"
        >
          Flow
        </button>
        <button
          onClick={() => {
            setQueryType('search')
            setQuery('nature')
          }}
          className="btn-sec text-xs sm:text-sm"
        >
          Nature
        </button>
        <button
          onClick={() => {
            setQueryType('search')
            setQuery('ocean')
          }}
          className="btn-sec text-xs sm:text-sm"
        >
          Ocean
        </button>
        <button
          onClick={() => {
            setQueryType('search')
            setQuery('space')
          }}
          className="btn-sec text-xs sm:text-sm"
        >
          Space
        </button>
        <button
          onClick={() => {
            setQueryType('search')
            setQuery('city')
          }}
          className="btn-sec text-xs sm:text-sm"
        >
          city
        </button>
        <button
          onClick={() => {
            setQueryType('search')
            setQuery('calm')
          }}
          className="btn-sec text-xs sm:text-sm"
        >
          Calm
        </button>
      </div>
      <div className="scrollbar  grid max-h-[400px] grid-cols-2 place-items-center gap-2 overflow-hidden overflow-y-auto lg:max-h-[480px] lg:grid-cols-3 ">
        {isLoading &&
          Array.from({ length: 20 }).map((_, i) => (
            <div
              key={i}
              role="status"
              className="group relative flex h-[100px] w-[170px] animate-pulse items-center justify-center overflow-hidden  rounded-lg bg-gray-300 dark:bg-gray-600"
            >
              <PlayFill size={30} />

              <span className="sr-only">Loading...</span>
            </div>
          ))}
        {isError && <p>{error.message}</p>}

        {data &&
          data.map((video) => {
            return (
              <div
                key={video.id}
                className="group group relative max-h-[100px] max-w-[170px] overflow-hidden rounded-lg bg-gray-400 "
              >
                <img
                  src={video.image}
                  alt=""
                  className={`block h-full w-full  object-cover group-hover:hidden`}
                />
                <video
                  muted
                  loop
                  autoPlay
                  src={
                    video.video_files.filter(
                      (video) => video.quality === 'sd',
                    )[0].link
                  }
                  className={`hidden h-full w-full object-cover group-hover:block `}
                  alt={video.user.name}
                ></video>
                <span>
                  <button
                    onClick={(e) => handlePexelVideo(e, video)}
                    className="absolute bottom-1 right-1 hidden rounded bg-white px-2 py-1 text-black group-hover:block"
                  >
                    <CheckCircleFill size={24} />
                  </button>
                </span>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default BgVideoPanel
