import React, { useState, useContext } from 'react'
import HabitContext from '../../../context/HabitContext'

const Habit = ({ habit, date }) => {
  const { deleteHabit, logHabit } = useContext(HabitContext)
  const [checked, setChecked] = useState(false)
  const [data, setData] = useState(null)

  const id = habit.id
  return (
    <div className="card-item  flex items-center justify-between rounded px-2 py-1">
      <p className="capitalize">{habit.title}</p>
      <div className="flex items-center gap-2">
        {habit.type === 'number' ? (
          <div>
            <input
              type="number"
              className="input-item"
              min="0"
              max="100"
              step="1"
              placeholder="number"
            />
            <span>{habit.unit + 's'}</span>
          </div>
        ) : habit.type === 'time' ? (
          <>
            {habit.unit === 'hours' && (
              <input
                type="number"
                placeholder="hours"
                name="hours"
                max={24}
                min={0}
                step={1}
                defaultValue={0}
              />
            )}
            <input
              type="number"
              placeholder="minutes"
              name="minutes"
              max={59}
              min={0}
              step={1}
              defaultValue={0}
            />
          </>
        ) : (
          <input
            type="checkbox"
            name="BinaryCheckbox"
            id="binaryCheckbox"
            value={checked}
          />
        )}
        <button
          onClick={() => logHabit(id)}
          className="rounded bg-black px-2 py-1 text-white"
        >
          Log
        </button>
        <button
          onClick={() => deleteHabit(id, date)}
          className="rounded-md bg-red-900 px-2 py-1 text-white"
        >
          Delete
        </button>
      </div>
    </div>
  )
}

export default Habit
