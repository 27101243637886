import React, { useContext, useState } from 'react'
import { Popover } from '@headlessui/react'
import MainContext from '../../../context/MainContext'
import WidgetWrapper from '../../ui/WidgetWrapper'
// import TimeZone from './TimeZone';
// import AddTimeZone from './AddTimeZone';

const Time = () => {
  const { time, date } = useContext(MainContext)
  const [timezones, setTimezones] = useState([])
  //TODO add ability to customize time format
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            aria-label="time widget"
            id="time"
            className={`outline-none`}
          >
            <WidgetWrapper open={open}>
              <span className="text-xl">
                {time.replace('AM', '').replace('PM', '')}
              </span>
              <span
                className={`text-sm ${
                  open
                    ? 'text-gray-200  dark:text-gray-700'
                    : 'dark:text-gray-300'
                }  `}
              >
                {date}
              </span>
            </WidgetWrapper>
          </Popover.Button>
          {/* <Popover.Panel className="absolute top-[75px] right-0 w-80 z-10 my-card">
            <div className="flex flex-col items-center justify-center ">
              <TimeZone timezone="America/Anchorage" title="work" />
              <AddTimeZone />
            </div>
          </Popover.Panel> */}
        </>
      )}
    </Popover>
  )
}

export default Time
