import React, { useContext } from 'react'
import AuthContext from '../../context/AuthContext'
import getInitials from '../../helpers/getInitials'

const Profile = ({ user }) => {
  const { logout } = useContext(AuthContext)

  const { name, email, photoURL } = user

  return (
    <div className=" flex flex-col items-center gap-2">
      {photoURL ? (
        <img
          src={photoURL}
          alt={name || email.split('@')[0]}
          className="h-12 w-12 rounded-full"
        />
      ) : (
        <div className="flex h-12 w-12 items-center justify-center rounded-full bg-white">
          <span className="text-2xl font-medium text-slate-800">
            {getInitials(name || email.split('@')[0])}
          </span>
        </div>
      )}
      <span className="font-medium capitalize">
        {name || email.split('@')[0]}
      </span>
      <span className="font-medium text-gray-200">{email}</span>
      <button className="btn-sec" onClick={logout}>
        Logout
      </button>
    </div>
  )
}

export default Profile
