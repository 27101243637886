import React, { useState, createContext } from 'react'
import useLocalStorageState from 'use-local-storage-state'
import { nanoid } from 'nanoid'

const WidgetContext = createContext()

export const WidgetProvider = ({ children }) => {
  //Widgets
  const [widgets, setWidgets] = useLocalStorageState('widgets', {
    defaultValue: [
      { type: 'counter', id: nanoid(8), title: 'Streak', count: 0 },
      {
        city: 'Los Angeles',
        id: nanoid(8),
        timezone: 'America/Los_Angeles',
        title: 'Pacific Time',
        type: 'timezone',
      },
    ],
  })

  let [countdowns, setCountdowns] = useLocalStorageState('countdowns', {
    defaultValue: [],
  })
  const [timezones, setTimezones] = useState([])

  return (
    <WidgetContext.Provider
      value={{
        widgets,
        countdowns,
        timezones,
        setCountdowns,
        setTimezones,
        setWidgets,
      }}
    >
      {children}
    </WidgetContext.Provider>
  )
}
export default WidgetContext
