import React from 'react'
import { useContext, useState } from 'react'
import AuthContext from '../../context/AuthContext'

const Login = ({ setAuthScreen }) => {
  // const router = useRouter();
  const [error, setError] = useState(null)
  const [formInput, setFormInput] = useState({
    email: '',
    password: '',
  })
  const { email, password } = formInput

  const { handleGoogleSignin, login } = useContext(AuthContext)

  const handleLogin = async (e) => {
    e.preventDefault()
    try {
      await login(email, password)
      // router.push("/");
    } catch (error) {
      console.log(error.code)
      error.code === 'auth/user-not-found'
        ? setError('User not found')
        : error.code === 'auth/wrong-password'
        ? setError('Wrong password, did you signup using Google?')
        : setError(error.message)
    }
  }

  return (
    <div>
      <h1 className="text-center text-3xl font-semibold text-gray-700 dark:text-white">
        Flow
      </h1>
      <div className="-mx-2 mt-6 flex items-center">
        <button
          onClick={handleGoogleSignin}
          type="button"
          className="mx-2 flex w-full transform items-center justify-center rounded-md bg-blue-500 px-6 py-2 text-sm font-medium text-white transition-colors duration-200 hover:bg-blue-400 focus:bg-blue-400 focus:outline-none"
        >
          <svg className="mx-2 h-4 w-4 fill-current" viewBox="0 0 24 24">
            <path d="M12.24 10.285V14.4h6.806c-.275 1.765-2.056 5.174-6.806 5.174-4.095 0-7.439-3.389-7.439-7.574s3.345-7.574 7.439-7.574c2.33 0 3.891.989 4.785 1.849l3.254-3.138C18.189 1.186 15.479 0 12.24 0c-6.635 0-12 5.365-12 12s5.365 12 12 12c6.926 0 11.52-4.869 11.52-11.726 0-.788-.085-1.39-.189-1.989H12.24z"></path>
          </svg>

          <span className="mx-2 hidden sm:inline">Sign in with Google</span>
        </button>
      </div>

      <div className="mt-4 flex items-center justify-between">
        <span className="w-1/5 border-b border-gray-500 dark:border-gray-400 lg:w-1/5"></span>

        <p className="select-none text-center text-xs uppercase text-gray-500 hover:underline dark:text-gray-300">
          or login with Email
        </p>

        <span className="w-1/5 border-b border-gray-500 dark:border-gray-400 lg:w-1/5"></span>
      </div>

      <form className="mt-6" onSubmit={(e) => handleLogin(e)}>
        <div>
          <label htmlFor="Email" className="input-label">
            Email
          </label>
          <input
            type="email"
            autoComplete="email"
            value={email}
            required
            onChange={(e) =>
              setFormInput({ ...formInput, email: e.target.value })
            }
            className="input-item mt-1 w-full"
          />
        </div>

        <div className="mt-4">
          <div className="flex items-center justify-between">
            <label htmlFor="password" className="input-label">
              Password
            </label>

            <span
              onClick={() => setAuthScreen('forgot-password')}
              className="cursor-pointer text-xs text-gray-700 hover:underline dark:text-gray-200"
            >
              Forget Password?
            </span>
          </div>
          <input
            type="password"
            value={password}
            autoComplete="current-password"
            required
            onChange={(e) =>
              setFormInput({ ...formInput, password: e.target.value })
            }
            className="input-item mt-1 w-full"
          />
          {error ? (
            <p className="mt-1  text-sm text-red-600 ">{error}</p>
          ) : null}
        </div>

        <div className="mt-6 flex justify-center">
          <button className="btn-cta w-64 ">Login</button>
        </div>
      </form>

      <p className="mt-8  text-center font-light text-gray-800 dark:text-gray-200">
        Don&apos;t have an account?{' '}
        <button
          onClick={() => setAuthScreen('register')}
          className="font-medium text-gray-800 hover:underline dark:text-gray-200"
        >
          {' '}
          Create One
        </button>
      </p>
    </div>
  )
}

export default Login
