import React, { createContext, useState, useEffect } from 'react'
import useLocalStorageState from 'use-local-storage-state'
import { nanoid } from 'nanoid'

const SiteBlockerContext = createContext()

export const SiteBlockerProvider = ({ children }) => {
  const [blockList, setBlockList] = useLocalStorageState('blockList', {
    defaultValue: [],
  })
  const [newBlockURL, setNewBlockURL] = useState('')
  const [isFocusing, setIsFocusing] = useState(false)
  const [enableBlocking, setEnableBlocking] = useLocalStorageState(
    'enableBlocking',
    {
      defaultValue: false,
    },
  )

  const normalizeUrl = (url) => {
    return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('/')[0]
  }

  const addToBlockList = (e) => {
    e.preventDefault()

    const blockURLObj = {
      id: nanoid(8),
      url: normalizeUrl(newBlockURL.toLowerCase()),
    }
    setBlockList([...blockList, blockURLObj])
    setNewBlockURL('')
  }
  const removeFromBlockList = (id) => {
    setBlockList(blockList.filter((item) => item.id !== id))
  }

  useEffect(() => {
    chrome?.storage?.local.set({ blockListKey: blockList }, (result) => {
      // console.log(blockList);
    })
  }, [blockList])

  useEffect(() => {
    chrome?.storage?.local.set({ isFocusingKey: isFocusing }, (result) => {
      // console.log(isFocusing);
    })
  }, [isFocusing])

  useEffect(() => {
    chrome.storage?.local.set(
      { enableBlockingKey: enableBlocking },
      (result) => {
        // console.log(enableBlocking);
      },
    )
  }, [enableBlocking])
  return (
    <SiteBlockerContext.Provider
      value={{
        isFocusing,
        setIsFocusing,
        enableBlocking,
        setEnableBlocking,
        addToBlockList,
        newBlockURL,
        setNewBlockURL,
        blockList,
        removeFromBlockList,
      }}
    >
      {children}
    </SiteBlockerContext.Provider>
  )
}

export default SiteBlockerContext
