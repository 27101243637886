// To avoid CORS - No Access-Control-Allow-Origin header is present on the requested resource. error
// we add a script tag to the page to get the search suggestions

export function getSuggestions(query) {
  return new Promise((resolve, reject) => {
    if (!window.suggestionResults) {
      window.suggestionResults = {}
    }

    const id = 'i' + Math.random().toString(36).slice(2) // add unique id to each request

    window.suggestionResults[id] = (data) => {
      // Resolve the suggestions
      resolve(data[1])

      if (window.suggestionResults) {
        delete window.suggestionResults.id
      }

      const scriptToRemove = document.getElementById('suggestionsQuery' + id)
      if (scriptToRemove !== null) {
        scriptToRemove.remove()
      }
    }

    const scriptToAdd = document.createElement('script')

    scriptToAdd.id = 'suggestionsQuery' + id
    scriptToAdd.onerror = reject
    scriptToAdd.src =
      'https://www.google.com/complete/search?client=chrome&q={query}&callback={callback}'
        .replace('{query}', query)
        .replace('{callback}', `suggestionResults.${id}`)

    document.head.appendChild(scriptToAdd)
  })
}
