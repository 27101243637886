import React, { useContext } from 'react'
import { Trash } from 'react-bootstrap-icons'
import SiteBlockerContext from '../../../context/SiteBlockerContext'
import getFavicon from '../../../helpers/getFavicon'

const BlockedSite = ({ id, url }) => {
  const { removeFromBlockList } = useContext(SiteBlockerContext)
  const favicon = getFavicon(url)
  return (
    <li className="flex w-full items-center justify-between rounded-md bg-white px-2 py-1 text-slate-800">
      <div className="flex items-center gap-2">
        <img src={favicon} alt="" className="h-6 w-6" />
        <p>{url}</p>
      </div>
      <Trash
        size={14}
        className="cursor-pointer"
        onClick={() => removeFromBlockList(id)}
      />
    </li>
  )
}

export default BlockedSite
