import React, { useContext, useRef, useEffect } from 'react'
import TodoContext from '../../context/TodoContext'
import dayjs from 'dayjs'
import {
  Bell,
  BellSlash,
  ArrowCounterclockwise,
  Play,
  Pause,
} from 'react-bootstrap-icons'
import FocusTodoItem from './FocusTodoItem'
import Countdown, { zeroPad } from 'react-countdown'

const TodoFocus = () => {
  const {
    muteTodoCompleteDing,
    setMuteTodoCompleteDing,
    handleTimerComplete,
    timeLeft,
    setCountdownTimer,
    countdownTimer,
    todos,
    isRunning,
    setIsRunning,
    handleTimerUpdate,
    formatTime,
    // showTotalFocusedTime,
    handleReset,
    handleChangeTime,
  } = useContext(TodoContext)
  const countdownRef = useRef()

  const renderer = ({ hours, minutes, seconds, total }) => {
    return (
      <span className={`${isRunning ? 'block' : 'hidden'}`}>
        {`${hours > 0 ? hours + ':' : ''}${zeroPad(minutes)}:${zeroPad(
          seconds,
        )}`}
      </span>
    )
  }

  // This is to fix the issue of mis match when the focusTodo is removed from dom and inserted again
  useEffect(() => {
    setCountdownTimer(
      dayjs()
        .add(todos[0]?.timeLeft, 'seconds')
        .format('YYYY-MM-DDTHH:mm:ss.sssZ'),
    )
  }, [todos, setCountdownTimer])

  useEffect(() => {
    if (todos.length > 0) {
      if (isRunning) {
        countdownRef.current.api.start()
      } else {
        countdownRef.current.api.pause()
      }
    }
  }, [isRunning, todos])
  return (
    <>
      <div id="main" className="main relative z-10 gap-1 ">
        <div className=" card-item flex flex-col gap-4 rounded-md p-2">
          <span className="text-center text-6xl">
            <>
              <Countdown
                autoStart={false}
                ref={countdownRef}
                date={countdownTimer.toString()}
                renderer={renderer}
                onTick={(timeDelta) => handleTimerUpdate(timeDelta)}
                onComplete={() => handleTimerComplete()}
              />

              <p className={`${isRunning ? 'hidden' : 'block'}`}>
                {formatTime(todos[0].timeLeft)}
              </p>
            </>
          </span>
          <div className="flex items-center justify-between">
            {!isRunning ? (
              <button
                aria-label="mute"
                onClick={() => setMuteTodoCompleteDing((prev) => !prev)}
              >
                {muteTodoCompleteDing ? (
                  <BellSlash className="small-icon" size={16} />
                ) : (
                  <Bell className="small-icon" size={16} />
                )}
              </button>
            ) : (
              <div className="w-7"></div>
            )}

            <button
              aria-label="reduce time"
              disabled={todos[0]?.timeLeft <= 300}
              onClick={() => handleChangeTime(-5)}
              className="small-icon disabled:cursor-not-allowed disabled:opacity-75"
            >
              -5
            </button>
            <button
              aria-label="start timer"
              onClick={() => setIsRunning((prev) => !prev)}
              className="circle "
            >
              {isRunning ? <Pause size={28} /> : <Play size={28} />}
            </button>
            <button
              aria-label="increase time"
              onClick={() => handleChangeTime(5)}
              className="small-icon"
            >
              +5
            </button>
            {!isRunning ? (
              <ArrowCounterclockwise
                onClick={handleReset}
                className="small-icon"
                size={16}
              />
            ) : (
              <div className="w-7"></div>
            )}
          </div>
        </div>
      </div>
      {todos.length > 0 ? (
        <FocusTodoItem todo={todos[0]} />
      ) : (
        <div className="card-item flex h-24 flex-col items-center justify-center gap-1 rounded-lg">
          <p className="text-lg">Well Done</p>
          <p>Take a break or add new Tasks</p>
        </div>
      )}
    </>
  )
}

export default TodoFocus
