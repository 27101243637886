import React, { useState, createContext } from 'react'
import useLocalStorageState from 'use-local-storage-state'
import { nanoid } from 'nanoid'

const LinksContext = createContext()

const defaultLinks = [
  {
    id: nanoid(8),
    title: 'Gmail',
    url: 'https://mail.google.com',
  },
  {
    id: nanoid(8),
    title: 'Calendar',
    url: 'https://calendar.google.com',
  },
  {
    id: nanoid(8),
    title: 'Google Drive',
    url: 'https://drive.google.com',
  },
  {
    id: nanoid(8),
    title: 'Keep',
    url: 'https://keep.google.com',
  },
  {
    id: nanoid(8),
    title: 'Notion',
    url: 'https://www.notion.so',
  },
  {
    id: nanoid(8),
    title: 'Evernote',
    url: 'https://evernote.com/client/web',
  },
]

export const LinksProvider = ({ children }) => {
  const [links, setLinks] = useLocalStorageState('links', {
    defaultValue: defaultLinks,
  })
  //FIXME Uncomment for extension
  // if chrome storage sync doesn't have links key  then set it to defaultLinks
  //   chrome.storage.sync.get("links", function (result) {
  //     if (!result.links) {
  //       chrome.storage.sync.set({ links: defaultLinks });
  //     }
  //   });

  const [newLink, setNewLink] = useState({
    id: nanoid(8),
    title: '',
    url: '',
  })
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false)
  const [isEditLinkModal, setIsEditLinkModal] = useState(false)
  const [editedLink, setEditedLink] = useState({})
  const handleEditLink = (id) => {
    setEditedLink(links.find((item) => item.id === id && item))
    setIsEditLinkModal(true)
  }

  const onChange = (e) => {
    setNewLink({ ...newLink, [e.target.name]: e.target.value })
  }
  const onSubmit = (e) => {
    e.preventDefault()
    setLinks([...links, newLink])
    // chrome.storage.sync.set({ links: [...links, newLink] });
    setNewLink({
      id: nanoid(8),
      title: '',
      url: '',
    })
    setIsLinkModalOpen(false)
  }

  const removeLink = (e, id) => {
    e.preventDefault()
    const updatedLinks = links.filter((item) => item.id !== id)
    setLinks(updatedLinks)
    // chrome.storage.sync.set({ links: updatedLinks });
    setIsEditLinkModal(false)
  }

  const handleUpdate = (e, editedLink) => {
    e.preventDefault()
    const updatedLink = links.map((link) =>
      link.id === editedLink.id ? editedLink : link,
    )
    setLinks(updatedLink)
    // chrome.storage.sync.set({ links: updatedLink });
    setIsEditLinkModal(false)
  }

  //   chrome.storage.onChanged.addListener((changes, areaName) => {
  //     console.log(changes);
  //     if (areaName === "sync") {
  //       chrome.storage.sync.get(["links"], function (result) {
  //         const links = result.links;
  //         // console.log(links);
  //         setLinks(links);
  //       });
  //     }
  //   });

  return (
    <LinksContext.Provider
      value={{
        editedLink,
        setEditedLink,
        handleEditLink,
        links,
        setLinks,
        newLink,
        isLinkModalOpen,
        isEditLinkModal,
        setIsEditLinkModal,
        setNewLink,
        onChange,
        onSubmit,
        removeLink,
        setIsLinkModalOpen,
        handleUpdate,
      }}
    >
      {children}
    </LinksContext.Provider>
  )
}
export default LinksContext
