import React, { useState, useCallback, useEffect } from 'react'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import advanced from 'dayjs/plugin/advancedFormat'
import { Popover } from '@headlessui/react'
import AddTimeZone from './AddTimeZone'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import WidgetWrapper from '../../ui/WidgetWrapper'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanced)

const TimeZone = ({ tz }) => {
  const { title, timezone, city } = tz
  const getCurrentTime = useCallback(() => {
    return dayjs().tz(timezone).format('h:mm A')
  }, [timezone])
  const [time, setTime] = useState(getCurrentTime())

  const updateTime = useCallback(() => {
    setTime(getCurrentTime())
  }, [getCurrentTime])

  useEffect(() => {
    const timer = setInterval(updateTime, 1000)
    return () => clearInterval(timer)
  }, [updateTime])

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: tz.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Popover
      as="div"
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={`relative max-w-[150px] ${isDragging ? 'z-20' : ''} `}
    >
      {({ open }) => (
        <>
          <Popover.Button
            aria-label="timezone widget"
            id="time"
            className={`outline-none`}
          >
            <WidgetWrapper open={open} id={tz.id}>
              <span className="text-xl">{time}</span>
              <span
                className={`text-sm capitalize ${
                  open
                    ? 'text-gray-200  dark:text-gray-700'
                    : 'dark:text-gray-300'
                }  `}
              >
                {title.trim() || city}
              </span>
            </WidgetWrapper>
          </Popover.Button>
          <Popover.Panel className="my-card absolute top-[75px] right-0 z-20 w-80">
            {({ close }) => <AddTimeZone tz={tz} close={close} />}
          </Popover.Panel>
        </>
      )}
    </Popover>
  )
}

export default TimeZone
