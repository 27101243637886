import React, { useContext } from 'react'
import MainContext from '../../../context/MainContext'
import { CloudArrowUp } from 'react-bootstrap-icons'

const BgUploadPanel = () => {
  const {
    photo,
    video,
    setPhoto,
    setVideo,
    backgroundType,
    setBackgroundType,
  } = useContext(MainContext)
  const handleBackgroundTypeChange = (e) => {
    e.preventDefault()
    setBackgroundType(e.target.value)
  }
  return (
    <div className="flex w-full flex-col items-center justify-center gap-10  ">
      {/* local file input and convert it to base64 */}
      <h1 className=" mt-2 text-center ">Upload Your own background image</h1>
      <div className="flex items-center justify-between gap-4">
        <label className="my-card-base flex h-20 w-40 cursor-pointer flex-col items-center justify-center rounded-lg px-4 py-2 capitalize tracking-wide  shadow-lg ">
          <CloudArrowUp className="h-8 w-8" />
          <span className="mt-2 text-sm  leading-normal sm:text-base">
            Select a Photo
          </span>
          <input
            type="file"
            accept="image/*"
            className="hidden"
            onChange={(e) => {
              const file = e.target.files[0]
              const reader = new FileReader()
              reader.onload = (e) => {
                setBackgroundType('photo')
                setPhoto({
                  url: e.target.result,
                  name: file.name,
                })
              }
              reader.readAsDataURL(file)
            }}
          />
        </label>
        {/* <label className="w-40 h-20 flex flex-col items-center px-4 py-2 my-card-base text-blue rounded-lg shadow-lg tracking-wide uppercase  cursor-pointer ">
          <svg
            className="w-8 h-8"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
          </svg>
          <span className="mt-2 text-base leading-normal">Select a video</span>
          <input
            type="file"
            accept="video/*"
            className="hidden"
            onChange={(e) => {
              const file = e.target.files[0];
              console.log(file);
              const reader = new FileReader();
              reader.onload = (e) => {
                setBackgroundType("video");
                setVideo({
                  url: e.target.result,
                  name: file.name,
                });
              };
              reader.readAsDataURL(file);
            }}
          />
        </label> */}
      </div>
    </div>
  )
}

export default BgUploadPanel
