//This is widget wrapper with animations, transisitions and styles

import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'

const WidgetWrapper = ({ children, open, id }) => {
  return (
    <motion.div
      key={id}
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5 }}
      transition={{ duration: 0.2, ease: 'easeInOut' }}
      className={`touch-none ${
        open && 'bg-slate-900/75 text-white dark:bg-white dark:text-slate-800'
      }    widget group inline-flex flex-col items-center justify-center text-xl outline-none`}
    >
      {children}
    </motion.div>
  )
}

export default WidgetWrapper
