import React, { createContext, useState } from 'react'
import useLocalStorageState from 'use-local-storage-state'
import toast from 'react-hot-toast'
import congrats from '../data/congrats'
import { nanoid } from 'nanoid'

const HabitContext = createContext()

export const HabitProvider = ({ children }) => {
  const [addHabitModal, setAddHabitModal] = useState(false)
  const DEFAULT_HABITS = [
    {
      id: nanoid(5),
      title: 'Meditation',
      heatmap: [],
      type: 'time',
      unit: 'minutes',
      streak: 4,
      maxStreak: 10,
      vacationMode: false,
      streakActiveOn: [1, 2, 3, 4, 5, 6, 7],
    },
    {
      id: nanoid(5),
      title: 'Workout',
      heatmap: [],
      type: 'binary',
      streak: 20,
      maxStreak: 20,
      vacationMode: false,
      streakActiveOn: [1, 2, 3, 4, 5],
    },
  ]
  const [habits, setHabits] = useLocalStorageState('habits', {
    defaultValue: DEFAULT_HABITS,
  })
  const addHabit = (newHabit) => {
    setHabits([...habits, newHabit])
    toast.success('New Habit Created')
  }
  const editHabit = () => {
    console.log('habit Edited')
  }
  const deleteHabit = (id) => {
    let updatedHabits = [...habits].filter((habit) => habit.id !== id)
    setHabits(updatedHabits)
    toast.success('Habit deleted')
  }
  const logHabit = (id, data, date) => {
    const newHabits = habits.map((habit) => {
      if (habit.id === id) {
        toast.success(
          habit.habit + congrats[Math.floor(Math.random() * congrats.length)],
        )
        return {
          ...habit,
          heatmap: [
            ...habit.heatmap,
            { date: date, count: Math.floor(Math.random() * 10) },
          ],
        }
      }
      return habit
    })
    setHabits(newHabits)
  }
  return (
    <HabitContext.Provider
      value={{
        habits,
        addHabitModal,
        addHabit,
        editHabit,
        deleteHabit,
        logHabit,
        setAddHabitModal,
      }}
    >
      {children}
    </HabitContext.Provider>
  )
}

export default HabitContext
