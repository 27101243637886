import React, { useContext, useState } from 'react'
import { UiChecksGrid } from 'react-bootstrap-icons'
import { nanoid } from 'nanoid'
import Habit from './Habit'
import { Dialog, Popover } from '@headlessui/react'
import AddHabit from './AddHabit'
import HabitContext from '../../../context/HabitContext'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'

dayjs.extend(calendar)
const HabitTracker = () => {
  const [toggleHeatmap, setToggleHeatmap] = useState(false)
  const { habits, setAddHabitModal } = useContext(HabitContext)
  const [toggleDate, setToggleDate] = useState(true)
  const handleToggleDate = () => {
    setToggleDate(!toggleDate)
  }
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'))
  const handleChangeDate = (e) => {
    e.preventDefault()
    setDate(e.target.value)
  }
  return (
    <>
      <AddHabit />
      <Popover className="md:relative">
        {({ open }) => (
          <>
            <Popover.Button
              data-tip="Coming Soon"
              aria-label="soundscapes"
              id="soundscapes"
              disabled
              className={` disabled:tooltip-custom disabled:cursor-not-allowed disabled:opacity-80
            ${
              open
                ? 'md:bg-slate-900/75 md:text-white md:dark:bg-white md:dark:text-slate-800'
                : 'md:my-card-base text-slate-800 dark:text-white'
            } flex flex-col items-center justify-center rounded outline-none  md:h-16 md:w-28`}
            >
              <UiChecksGrid
                size={20}
                className="h-12 w-16 px-3 py-2 md:h-8 md:w-8 md:px-0 md:py-0"
              />
              <span className="hidden text-xs md:block ">Habit Tracker</span>
            </Popover.Button>
            <Popover.Panel
              className={` md:my-card md: md:my-card absolute bottom-14 left-5 z-10 flex h-[80vh] min-w-[90%] max-w-[90vw]  cursor-default flex-col gap-2 overflow-hidden rounded-lg bg-gray-800 p-4 outline-none xs:left-10 sm:w-[400px] sm:max-w-[400px] md:-bottom-[105px] md:right-0 md:left-[120px] md:h-[600px] md:w-[550px] md:max-w-none lg:w-[750px]`}
            >
              <div className="flex items-center justify-between">
                <p className="text-base font-medium">Habit Tracker</p>
                <div className="flex items-center gap-2">
                  <input
                    type="date"
                    name="date"
                    id="date"
                    className=" rounded px-1"
                  />
                  <label className="input-item flex cursor-pointer items-center gap-3">
                    <span className="text-sm">Heatmap</span>
                    <div className="relative">
                      <input
                        checked={toggleHeatmap}
                        onChange={() => setToggleHeatmap((prev) => !prev)}
                        name="openLinkInNewTab"
                        id="openLinkInNewTab"
                        type="checkbox"
                        className="peer sr-only"
                      />
                      <div className="toggle-line"></div>
                      <div className=" toggle-dot"></div>
                    </div>
                  </label>
                  <button
                    onClick={() => setAddHabitModal(true)}
                    className="btn-cta text-sm"
                  >
                    Add Habit
                  </button>
                </div>
              </div>
              <div className="mx-auto w-full border-b border-gray-400 dark:border-gray-400" />
              {habits.map((habit) => (
                <Habit habit={habit} date={date} />
              ))}
            </Popover.Panel>
          </>
        )}
      </Popover>
    </>
  )
}
export default HabitTracker
