import React, { useContext } from 'react'
import MainContext from '../../context/MainContext'
import { isExtension } from '../../helpers/isExtension'

const GeneralSettings = () => {
  const { toggleMenu, handleToggleMenuChange } = useContext(MainContext)

  return (
    <div className=" flex flex-col gap-3 ">
      <p className="rounded-lg px-2 text-center text-lg text-slate-800 dark:text-white ">
        Toggle Menu
      </p>
      <div className="form-control toggle-menu flex flex-col gap-1 ">
        <label className="toggle-label">
          <span className="text-lg">Links</span>
          <div className="relative">
            <input
              checked={toggleMenu.links}
              onChange={handleToggleMenuChange}
              name="links"
              id="links"
              type="checkbox"
              className="peer sr-only"
            />
            <div className="toggle-line"></div>
            <div className=" toggle-dot"></div>
          </div>
        </label>
        <label className="toggle-label">
          <span className="text-lg">Todos</span>
          <div className="relative">
            <input
              checked={toggleMenu.todos}
              onChange={handleToggleMenuChange}
              name="todos"
              id="todos"
              type="checkbox"
              className="peer sr-only"
            />
            <div className="toggle-line"></div>
            <div className=" toggle-dot"></div>
          </div>
        </label>
        <label className="toggle-label">
          <span className="text-lg">Search</span>
          <div className="relative">
            <input
              checked={toggleMenu.search}
              onChange={handleToggleMenuChange}
              name="search"
              id="search"
              type="checkbox"
              className="peer sr-only"
            />
            <div className="toggle-line"></div>
            <div className=" toggle-dot"></div>
          </div>
        </label>
        {isExtension() && (
          <label className="toggle-label">
            <span className="text-lg">Top Sites</span>
            <div className="relative">
              <input
                checked={toggleMenu.topsites}
                onChange={handleToggleMenuChange}
                name="topsites"
                id="topsites"
                type="checkbox"
                className="peer sr-only"
              />
              <div className="toggle-line"></div>
              <div className=" toggle-dot"></div>
            </div>
          </label>
        )}
        <label className="toggle-label">
          <span className="text-lg">Quotes</span>
          <div className="relative">
            <input
              checked={toggleMenu.quotes}
              onChange={handleToggleMenuChange}
              name="quotes"
              id="quotes"
              type="checkbox"
              className="peer sr-only"
            />
            <div className="toggle-line"></div>
            <div className=" toggle-dot"></div>
          </div>
        </label>
        <label className="toggle-label">
          <span className="text-lg">Widgets</span>
          <div className="relative">
            <input
              checked={toggleMenu.widgets}
              onChange={handleToggleMenuChange}
              name="widgets"
              id="widgets"
              type="checkbox"
              className="peer sr-only"
            />
            <div className="toggle-line"></div>
            <div className=" toggle-dot"></div>
          </div>
        </label>
        <label className="toggle-label">
          <span className="text-lg">Clock</span>
          <div className="relative">
            <input
              checked={toggleMenu.clock}
              onChange={handleToggleMenuChange}
              name="clock"
              id="clock"
              type="checkbox"
              className="peer sr-only"
            />
            <div className="toggle-line"></div>
            <div className=" toggle-dot"></div>
          </div>
        </label>
      </div>

      <label className="toggle-label">
        <span className="text-lg">Open links in new tab</span>
        <div className="relative">
          <input
            checked={toggleMenu.openLinkInNewTab}
            onChange={handleToggleMenuChange}
            name="openLinkInNewTab"
            id="openLinkInNewTab"
            type="checkbox"
            className="peer sr-only"
          />
          <div className="toggle-line"></div>
          <div className=" toggle-dot"></div>
        </div>
      </label>

      {isExtension() && (
        <>
          <p className="text-xl font-medium">Top sites</p>
          <label className="toggle-label">
            <span className="text-lg">Number of Rows</span>
            <div className="flex items-center gap-2">
              <span>1</span>
              <div className="relative">
                <input
                  checked={toggleMenu.topSitesSingleRow}
                  onChange={handleToggleMenuChange}
                  name="topSitesSingleRow"
                  id="topSitesSingleRow"
                  type="checkbox"
                  className="peer sr-only"
                />
                <div className="toggle-line"></div>
                <div className=" toggle-dot"></div>
              </div>
              <span>2</span>
            </div>
          </label>
        </>
      )}
    </div>
  )
}

export default GeneralSettings
