import React, { useContext } from 'react'
import getFavicon from '../../helpers/getFavicon'
import { Pencil } from 'react-bootstrap-icons'
import LinksContext from '../../context/LinksContext'
import MainContext from '../../context/MainContext'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

const LinkItem = ({ link, isSorting }) => {
  const { toggleMenu } = useContext(MainContext)

  const sortable = useSortable({ id: link.id })
  const {
    attributes,
    listeners,
    isDragging,
    setNodeRef,
    transform,
    transition,
  } = sortable

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const url = link.url
  // const hostname = new URL(link.url).hostname;
  const domain = url.replace(/.+\/\/|www.|\..+/g, '')
  const titleFull = link.title || domain
  const title = titleFull.substring(0, 13)
  const { handleEditLink } = useContext(LinksContext)

  const handleEdit = (id) => {
    handleEditLink(id)
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={`group relative rounded-lg  ${
        isDragging &&
        'z-10 bg-white  outline outline-gray-100 dark:bg-gray-900/90 dark:outline-none'
      } ${!isSorting && 'hover-item hover:shadow'} `}
    >
      <span
        className={`absolute hidden ${
          !isSorting && 'group-hover:block'
        } right-1 top-1 cursor-pointer rounded-full bg-slate-800 p-1 text-white dark:bg-white dark:text-slate-800`}
        onClick={() => handleEdit(link.id)}
      >
        <Pencil size={14} />
      </span>
      {isSorting ? (
        <div className="flex h-24 w-24 flex-col items-center justify-center gap-2 overflow-hidden">
          <img
            className="flex h-8 w-8 items-center justify-center"
            src={getFavicon(url)}
            alt="site"
          />
          <p className=" wrap flex items-center justify-center text-center text-sm capitalize leading-none">
            {title}
          </p>
        </div>
      ) : (
        <a
          href={url}
          target={toggleMenu.openLinkInNewTab ? '_blank' : '_self'}
          rel="noreferrer"
        >
          <div className="flex h-24 w-24 flex-col items-center justify-center gap-2 overflow-hidden">
            <img
              className="flex h-8 w-8 items-center justify-center"
              src={getFavicon(url)}
              alt="site"
            />
            <p className=" wrap flex items-center justify-center text-center text-sm capitalize leading-none">
              {title}
            </p>
          </div>
        </a>
      )}
    </div>
  )
}

export default LinkItem
