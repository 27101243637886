import { createContext, useEffect, useState } from 'react'
import { nanoid } from 'nanoid'
import {
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth'
import { auth } from '../utils/firebase'
import { getDoc, setDoc, doc, serverTimestamp } from 'firebase/firestore'
import { db } from '../utils/firebase'
// import { useRouter } from "next/router";
const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  //   const router = useRouter();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser({
          id: user.uid,
          email: user.email,
          name: user.displayName,
          photoURL: user.photoURL,
          isPremiumUser: user.isPremiumUser,
        })
      } else {
        setUser(null)
      }
      setLoading(false)
    })
    return () => unsubscribe()
  }, [])

  // Register with email and password
  const signup = async (email, password) => {
    try {
      await createUserWithEmailAndPassword(auth, email, password)
        .then((result) => {
          const userDetails = result.user
          const timestamp = serverTimestamp()
          try {
            setDoc(doc(db, 'users', userDetails.uid), {
              id: userDetails.uid,
              email: userDetails.email,
              name: userDetails.email.split('@')[0],
              photoURL: '',
              createdAt: timestamp,
              isPremiumUser: false,
              subscriptionStartAt: null,
              subscriptionEndAt: null,
              referrerId: null,
              referralId: nanoid(9),
              referralCount: 0,
              jobRole: '',
              interests: [],
            })
          } catch (error) {
            console.log(error)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    } catch (error) {
      console.log(error)
    }
  }

  // Login with email and password
  const login = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password)
  }

  // Google sign in with popup
  const provider = new GoogleAuthProvider()

  // Sign in with Google
  const handleGoogleSignin = async () => {
    try {
      const userCredentials = await signInWithPopup(auth, provider)

      const timestamp = serverTimestamp()
      const userDetails = {
        id: userCredentials.user.uid,
        email: userCredentials.user.email,
        name: userCredentials.user.displayName,
        photoURL: userCredentials.user.photoURL,
      }

      try {
        await getDoc(doc(db, 'users', userDetails.id)).then((docSnapshot) => {
          if (!docSnapshot.exists()) {
            setDoc(doc(db, 'users', userCredentials.user.uid), {
              ...userDetails,
              createdAt: timestamp,
              isPremiumUser: false,
              subscriptionStartAt: null,
              subscriptionEndAt: null,
              referrerId: null,
              referralId: nanoid(9),
              referralCount: 0,
              jobRole: '',
              interests: [],
            })
          } else {
            console.log('document exists')
            return
          }
        })
      } catch (e) {
        console.log(e)
      }

      //   router.push("/");
    } catch (error) {
      console.log(error)
    }
  }

  // Logout the user
  const logout = async () => {
    await signOut(auth).then(setUser(null))
  }

  const handleTest = async () => {
    // try {
    //   const userDoc = await getDoc(doc(db, "users", user.uid)).then((doc) => {
    //     if (!doc.exists) {
    //       console.log("document does not exist");
    //     } else {
    //       console.log("document exists");
    //     }
    //   });
    //   // console.log(user);
    // } catch (error) {
    //   console.log(error);
    // }
  }

  return (
    <AuthContext.Provider
      value={{
        login,
        signup,
        handleGoogleSignin,
        logout,
        handleTest,
        user,
      }}
    >
      {loading ? null : children}
    </AuthContext.Provider>
  )
}
export default AuthContext
