import React, { useContext, useState } from 'react'
import LinkItem from './LinkItem'
import LinksContext from '../../context/LinksContext'
import { Popover, Dialog } from '@headlessui/react'
import { motion } from 'framer-motion'
import { Grid } from 'react-bootstrap-icons'
import AddLink from './AddLink'

import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from '@dnd-kit/sortable'

import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'

const Links = () => {
  const [isSorting, setIsSorting] = useState(false)
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
  )

  function handleDragStart() {
    setIsSorting(true)
  }
  function handleDragEnd(event) {
    const { active, over } = event

    if (active.id !== over.id) {
      setLinks((links) => {
        const oldIndex = links.findIndex((link) => link.id === active.id)
        const newIndex = links.findIndex((link) => link.id === over.id)
        // chrome.storage.sync.set({
        //   links: arrayMove(links, oldIndex, newIndex),
        // });
        return arrayMove(links, oldIndex, newIndex)
      })
    }
    setIsSorting(false)
  }
  const {
    links,
    setLinks,
    removeLink,
    isLinkModalOpen,
    setIsLinkModalOpen,
    onChange,
    onSubmit,
    isEditLinkModal,
    setIsEditLinkModal,
    handleUpdate,
    newLink,
    editedLink,
    setEditedLink,
  } = useContext(LinksContext)

  const variants = {
    hidden: {
      opacity: 1,
    },
    visible: {
      opacity: 1,
    },
  }

  const handleChange = (e) => {
    setEditedLink({ ...editedLink, [e.target.name]: e.target.value })
  }

  return (
    <>
      {/* 👇🏻 Modal to add new links */}
      <Dialog
        open={isLinkModalOpen}
        // open={true}
        onClose={() => setIsLinkModalOpen(false)}
        className="relative z-50 "
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex items-center justify-center p-4">
          {/* The actual dialog panel  */}
          <Dialog.Panel className=" my-card mx-auto flex w-96  flex-col items-center p-4 text-base">
            <form onSubmit={onSubmit} className="flex w-full flex-col gap-2">
              <h1 className="text-center text-lg font-medium ">Add Link</h1>

              <div className="">
                <label htmlFor="url" className="input-label">
                  URL
                </label>
                <input
                  type="url"
                  id="url"
                  name="url"
                  onChange={onChange}
                  value={newLink.url}
                  className={`input-item mt-1 w-full `}
                  placeholder="https://example.com"
                  required
                  autoFocus
                  autoComplete="off"
                />
              </div>
              <div className="">
                <label htmlFor="title" className="input-label">
                  Title
                </label>
                <input
                  className="input-item mt-1 w-full"
                  onChange={onChange}
                  value={newLink.title}
                  autoComplete="off"
                  type="text"
                  name="title"
                  id="title"
                />
              </div>
              <button className="btn-cta text-lg">Add Link</button>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>
      {/* Modal to edit Links */}
      <Dialog
        open={isEditLinkModal}
        // open={true}
        onClose={() => setIsEditLinkModal(false)}
        className="relative z-50 "
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex items-center justify-center p-4">
          {/* The actual dialog panel  */}
          <Dialog.Panel className=" my-card mx-auto flex w-96  flex-col items-center p-4 text-base">
            <form className="flex w-full flex-col gap-2">
              <h1 className="text-center text-lg font-medium ">Edit Link</h1>

              <div className="flex w-full items-center justify-center gap-2">
                <label htmlFor="url">URL</label>
                <input
                  type="url"
                  id="url"
                  name="url"
                  onChange={handleChange}
                  value={editedLink.url}
                  className={`input-item w-full `}
                  placeholder="https://example.com"
                  required
                  autoFocus
                  autoComplete="off"
                />
              </div>
              <div className="flex w-full items-center justify-center gap-2">
                <label htmlFor="title">Title</label>
                <input
                  className="input-item w-full"
                  onChange={handleChange}
                  value={editedLink.title}
                  autoComplete="off"
                  type="text"
                  name="title"
                  id="title"
                />
              </div>
              <div className="flex flex-row-reverse items-center justify-center gap-2 ">
                <button
                  type="submit"
                  onClick={(e) => handleUpdate(e, editedLink)}
                  className="btn-cta text-base"
                >
                  Save
                </button>
                <button
                  className="btn-sec text-base"
                  onClick={(e) => removeLink(e, editedLink.id)}
                >
                  Delete
                </button>
              </div>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>
      <div className="absolute top-2 left-2">
        <Popover className="relative">
          {({ open }) => (
            <>
              <Popover.Button
                aria-label="favourite Links"
                className={`icon  ${
                  open &&
                  'bg-slate-900/75 text-white dark:bg-white dark:text-slate-800'
                }`}
              >
                <Grid size={24} className="" />
              </Popover.Button>
              <Popover.Panel
                as={motion.div}
                variants={variants}
                initial="hidden"
                animate="visible"
                className="my-card-base scrollbar absolute top-10 left-10 z-50  max-h-[400px] w-[328px] overflow-y-scroll rounded-lg p-2"
              >
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}
                  modifiers={[restrictToFirstScrollableAncestor]}
                >
                  <div className="grid grid-cols-3 gap-1">
                    <SortableContext
                      items={links}
                      strategy={rectSortingStrategy}
                    >
                      {links.length > 0 &&
                        links.map((link) => {
                          return (
                            <LinkItem
                              key={link.id}
                              link={link}
                              isSorting={isSorting}
                            />
                          )
                        })}
                    </SortableContext>

                    <AddLink />
                  </div>
                </DndContext>
              </Popover.Panel>
            </>
          )}
        </Popover>
      </div>
    </>
  )
}

export default Links
