import React, { useState, useContext } from 'react'
import { Combobox } from '@headlessui/react'
// import timezones from "../../../data/formattedTimezonesMini.json";
import timezones from '../../../data/formattedTimezonesFull'
import WidgetContext from '../../../context/WidgetContext'
import { useDebounce } from 'use-debounce'

const AddTimeZone = ({ tz, close }) => {
  const { id, city, timezone } = tz

  //Timezone select component - combobox

  const [selectedTimezone, setSelectedTimezone] = useState({
    name: city,
    timezone: timezone,
  })
  const [query, setQuery] = useState('')
  const [debouncedQuery] = useDebounce(query, 100)

  const filteredTimezone =
    debouncedQuery.length > 1 &&
    timezones.filter((timezone) => {
      const filtered =
        timezone.name.includes(debouncedQuery.toLowerCase()) ||
        timezone.timezone.includes(debouncedQuery.toLowerCase()) ||
        timezone.alternateName.includes(debouncedQuery.toLowerCase())
      return filtered
    })

  const { setWidgets } = useContext(WidgetContext)
  const addTimezone = (e) => {
    e.preventDefault()
    const title = e.target.title.value
    setWidgets((prev) =>
      prev.map((widget) =>
        widget.id === id
          ? {
              ...widget,
              city: selectedTimezone.name,
              timezone: selectedTimezone.timezone,
              title: title,
            }
          : widget,
      ),
    )
    close()
  }
  const onDelete = (e) => {
    e.preventDefault()
    setWidgets((prev) => prev.filter((widget) => widget.id !== id))
  }

  return (
    <form className="flex flex-col gap-2  " onSubmit={addTimezone}>
      <label htmlFor="timezone" className="input-label">
        Timezone
      </label>
      <div className="focus-within:card-item w-72 rounded-md ">
        <Combobox
          as="div"
          className=""
          value={selectedTimezone}
          onChange={setSelectedTimezone}
        >
          <Combobox.Input
            className="input-item w-full"
            placeholder="Type atleast 3 letters to search"
            autoComplete="off"
            name="timezone"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(timezone) => timezone.name}
          />
          <Combobox.Options className="scrollbar flex max-h-40 flex-col overflow-auto   ">
            {debouncedQuery.length > 1 &&
              filteredTimezone.map((timezone) => (
                <Combobox.Option key={timezone.name} value={timezone}>
                  {({ active, selected }) => (
                    <div
                      className={` flex flex-col  rounded p-1  ${
                        active ? 'bg-white text-slate-800' : ''
                      }`}
                    >
                      <span className="text-md capitalize">
                        {timezone.name}
                      </span>
                      <div className="flex items-center justify-between">
                        <span
                          className={`text-sm capitalize ${
                            active ? 'text-slate-600' : 'text-slate-100'
                          }`}
                        >
                          {timezone.alternateName}
                        </span>
                        <span
                          className={`text-xs capitalize ${
                            active ? 'text-slate-600' : 'text-slate-100'
                          }`}
                        >
                          {timezone.timezone}
                        </span>
                      </div>
                    </div>
                  )}
                </Combobox.Option>
              ))}
          </Combobox.Options>
        </Combobox>
      </div>
      <div className="w-full">
        <label htmlFor="title" className="input-label">
          Title
        </label>
        <input
          type="text"
          name="title"
          id="title"
          autoComplete="off"
          className="input-item mt-1 w-full"
          placeholder="title"
          defaultValue={timezone.title}
        />
      </div>

      <div className="flex flex-row-reverse items-center justify-between">
        <button className="btn-cta" type="submit">
          Add Timezone
        </button>
        <button className="btn-sec" onClick={onDelete}>
          Remove
        </button>
      </div>
    </form>
  )
}

export default AddTimeZone
