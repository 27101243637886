import React, { useContext, useState, useEffect } from 'react'
import { Dialog, Popover } from '@headlessui/react'
import Picker from '@emoji-mart/react'
import data from '@emoji-mart/data'
import { useForm } from 'react-hook-form'
import HabitContext from '../../../context/HabitContext'
import { nanoid } from 'nanoid'
const AddHabit = () => {
  const { addHabit, editHabit, deleteHabit, addHabitModal, setAddHabitModal } =
    useContext(HabitContext)

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState,
    formState: { errors, isSubmitSuccessful },
  } = useForm()
  const habitType = watch('type')

  const selectedColor = watch('color')
  const [isOpen, setIsOpen] = useState(false)
  const [selectedEmoji, setSelectedEmoji] = useState('🎯')

  const onSubmit = (data) => {
    const newHabit = {
      ...data,
      id: nanoid(5),
      emoji: selectedEmoji,
      heatmap: [],
      streak: 0,
      vacationMode: false,
      maxStreak: 0,
    }
    addHabit(newHabit)
    setAddHabitModal(false)
  }
  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset()
    }
  }, [formState, reset])

  return (
    <Dialog
      open={addHabitModal}
      onClose={() => setAddHabitModal(false)}
      className="relative z-50"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-black/50" aria-hidden="true" />

      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex items-center justify-center p-4">
        {/* The actual dialog panel  */}
        <Dialog.Panel className="my-card flex min-w-[350px] flex-col gap-4 p-4">
          <div>
            <Dialog.Title className="text-center text-lg">
              Start a new Habit
            </Dialog.Title>
            <Dialog.Description className="text-center text-sm">
              Start small and keep going
            </Dialog.Description>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-5"
          >
            {/* emoji */}
            <div className="flex gap-2">
              <Popover className="relative">
                <Popover.Button className="text-3xl">
                  {selectedEmoji}
                </Popover.Button>
                <Popover.Panel className="absolute -top-5 left-10">
                  <Picker
                    maxFrequentRows="1"
                    data={data}
                    emojiButtonSize={32}
                    emojiSize={24}
                    onEmojiSelect={(emoji) => setSelectedEmoji(emoji.native)}
                    previewPosition="none"
                  />
                </Popover.Panel>
              </Popover>
              {/* habit name */}

              <input
                type="text"
                autoComplete="off"
                {...register('title', { required: true })}
                className="input-item w-full"
                id="habit"
                required
                placeholder="Habit"
              />
            </div>
            <div className="flex justify-between">
              <select
                className="form-select rounded-lg dark:bg-slate-700"
                {...register('type')}
              >
                <option value="binary">Binary</option>
                <option value="number">Number</option>
                <option value="time">Time</option>
              </select>
              {habitType === 'number' ? (
                <div className="relative">
                  <label htmlFor="unit" className="input-label">
                    Singular Unit
                  </label>
                  <input
                    className="input-item"
                    type="text"
                    name="unit"
                    {...register('unit')}
                    placeholder="Eg.Mile, Pushup"
                    required
                  />
                </div>
              ) : (
                habitType === 'time' && (
                  <select
                    className="form-select rounded-lg dark:bg-slate-700 dark:text-white"
                    name="time"
                    id="time"
                    {...register('unit')}
                  >
                    <option value="hours">Hours</option>
                    <option value="minutes">Minutes</option>
                  </select>
                )
              )}
            </div>
            <div className="flex items-center gap-2 ">
              <select
                className="form-select rounded-lg dark:bg-slate-700"
                {...register('color')}
              >
                <option value="#f5f563">Yellow</option>
                <option value="#f24e12">Red</option>
                <option value="#00ff00">Green</option>
                <option value="#00ffff">Cyan</option>
              </select>
              <span className={`h-5 w-5  rounded-full bg-[${selectedColor}]`} />
            </div>
            {/* color */}
            {/* streak active on */}
            <input type="submit" className="btn-cta" value="Add Habit" />
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  )
}

export default AddHabit
