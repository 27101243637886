import React, { useContext, useState } from 'react'
import AuthContext from '../../context/AuthContext'
import { auth } from '../../utils/firebase'
import { sendPasswordResetEmail } from 'firebase/auth'
import { EnvelopeFill, EnvelopeSlashFill } from 'react-bootstrap-icons'

const ForgotPassword = ({ setAuthScreen }) => {
  const { user, logout } = useContext(AuthContext)
  const [error, setError] = useState(null)
  const [email, setEmail] = useState('')
  const [emailSent, setEmailSent] = useState(false)
  const handleResetPassword = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setEmailSent(true)
        setTimeout(() => {
          setAuthScreen('login')
        }, 30000)
      })
      .catch((error) => {
        setError(error.message)
        console.log(error)
      })
  }
  return (
    <div className="flex flex-col gap-2 ">
      <h1 className="text-center text-3xl font-semibold text-gray-700 dark:text-white">
        Flow
      </h1>

      {emailSent && !error ? (
        <div className="mt-10 flex flex-col items-center justify-center gap-2 text-base ">
          <span>
            <EnvelopeFill size={80} className="text-gray-700 dark:text-white" />
          </span>
          <h2 className="text-xl">Email Sent</h2>
          <span className="text-lg">
            click the password reset link sent to your mail
          </span>
          <span>Can&apos;t find password reset mail? check spam </span>
        </div>
      ) : !emailSent && error ? (
        <div className="mt-10 flex flex-col items-center justify-center gap-2 text-base ">
          <span>
            <EnvelopeSlashFill
              size={80}
              className="text-gray-700 dark:text-white"
            />
          </span>
          <h2 className="text-xl">No User found</h2>
          <span className="text-lg">
            Please check the email you entered and try again
          </span>
          <button
            onClick={() => setAuthScreen('register')}
            className="btn-sec w-64 font-medium  hover:underline"
          >
            {' '}
            Create account
          </button>
        </div>
      ) : (
        <>
          <p className="text-center text-lg">Reset Password</p>

          <div className="flex w-full flex-col items-center justify-center gap-2">
            <div className="w-full">
              <label htmlFor="Email" className="input-label">
                Email
              </label>
              <input
                type="email"
                autoComplete="email"
                value={email}
                required
                onChange={(e) => setEmail(e.target.value)}
                className="input-item mt-1 w-full"
                placeholder="example@mail.com"
              />
            </div>
            <button
              onClick={handleResetPassword}
              className="btn-sec w-64 text-base"
            >
              Reset Password
            </button>
            <button
              onClick={() => setAuthScreen('login')}
              className="font-medium text-gray-800 hover:underline dark:text-white"
            >
              {' '}
              Sign in instead
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default ForgotPassword
