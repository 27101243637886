import React, { useState, useContext } from 'react'
import { Popover } from '@headlessui/react'
import { PersonFill } from 'react-bootstrap-icons'
import Login from './Login'
import AuthContext from '../../context/AuthContext'
import Profile from './Profile'
import Signup from './Signup'
import ForgotPassword from './ForgotPassword'
import getInitials from '../../helpers/getInitials'

const User = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const { user } = useContext(AuthContext)
  const [authScreen, setAuthScreen] = useState('login')

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            aria-label="profile"
            className="border-0 outline-none "
          >
            {!user ? (
              <span
                className={`icon  ${
                  open &&
                  'bg-slate-900/75 text-white dark:bg-white dark:text-slate-800 '
                }`}
              >
                <PersonFill size={24} className="" />
              </span>
            ) : (
              <div className="inline-flex h-8 w-8 items-center justify-center rounded ">
                <span className=" h-8 w-8">
                  {user.photoURL ? (
                    <img
                      src={user.photoURL}
                      alt={user.name || user.email.split('@')[0]}
                      className="h-8 w-8 rounded"
                    />
                  ) : (
                    <div className="flex h-8 w-8 items-center justify-center rounded bg-white">
                      <span className="text-xl font-medium text-slate-900">
                        {getInitials(user.name || user.email.split('@')[0])}
                      </span>
                    </div>
                  )}
                </span>
              </div>
            )}
          </Popover.Button>

          <Popover.Panel className="my-card absolute bottom-0 left-[120px] z-10 h-[500px] w-[400px] sm:w-[450px]">
            {user ? (
              <Profile user={user} />
            ) : authScreen === 'login' ? (
              <Login setAuthScreen={setAuthScreen} />
            ) : authScreen === 'register' ? (
              <Signup setAuthScreen={setAuthScreen} />
            ) : (
              <ForgotPassword setAuthScreen={setAuthScreen} />
            )}
          </Popover.Panel>
        </>
      )}
    </Popover>
  )
}

export default User
