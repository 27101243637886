import React, { useState, useEffect, Fragment, useRef } from 'react'
import { Search } from 'react-bootstrap-icons'
import { Listbox, Transition } from '@headlessui/react'
import useLocalStorageState from 'use-local-storage-state'
import getFavicon from '../helpers/getFavicon'
import axios from 'axios'

import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox'
import { getSuggestions } from '../helpers/getSuggestions'
import { isExtension } from '../helpers/isExtension'

const Search_Providers = [
  { name: 'Google', url: 'https://www.google.com/search?q=', icon: 'google' },
  {
    name: 'Bing',
    url: 'https://www.bing.com/search?q=',
    icon: 'bing',
  },
  {
    name: 'Brave',
    url: 'https://search.brave.com/search?q=',
    icon: 'brave',
  },
  {
    name: 'DuckDuckGo',
    url: 'https://duckduckgo.com/?q=',
    icon: 'duckduckgo',
  },
  {
    name: 'YouTube',
    url: 'https://www.youtube.com/results?search_query=',
    icon: 'youtube',
  },
]

const Searchbar = () => {
  const [searchProvider, setSearchProvider] = useLocalStorageState(
    'searchProvider',
    {
      defaultValue: 'Google',
    },
  )

  const search = Search_Providers.find(
    (search) => search.name === searchProvider,
  )

  const [selected, setSelected] = useLocalStorageState('selected', {
    defaultValue: Search_Providers[0],
  })
  const changeSearchProvider = (e) => {
    setSelected(e)
    setSearchProvider(e.name)
  }

  const [query, setQuery] = useState('')
  const [suggestions, setSuggestions] = useState([])

  const handleQueryChange = (e) => {
    setQuery(e.target.value)
  }
  // handle web search suggestions in extension / server.
  //FIXME CORS error in chrome extension
  const handleGetSuggestions = (query) => {
    axios
      .get(`https://www.google.com/complete/search?client=chrome&q=${query}`)
      .then((res) => res.data)
      .then((res) => setSuggestions(res[1]))
      .catch((err) => console.log(err.response))
  }
  //getSuggestions() function can be used in web version
  useEffect(() => {
    isExtension() && process.env.NODE_ENV === 'production'
      ? handleGetSuggestions(query)
      : getSuggestions(query).then((suggestions) => {
          setSuggestions(suggestions)
        })
  }, [query])
  const queryRef = useRef(query)

  const isValidURL = (url) => {
    try {
      return Boolean(new URL(url))
    } catch (error) {
      return false
    }
  }

  const handleSearch = () => {
    const searchQuery = queryRef.current.value || query
    if (searchQuery.length > 0) {
      if (isValidURL(searchQuery)) {
        window.location.href = searchQuery
      } else {
        window.location.href = `${search.url}${encodeURIComponent(searchQuery)}`
      }
    }
  }

  const handleSelectSuggestion = (suggestion) => {
    if (isValidURL(suggestion)) {
      window.location.href = suggestion
    } else {
      window.location.href = `${search.url}${encodeURIComponent(suggestion)}`
    }
  }

  return (
    <Combobox
      aria-label="search"
      // style={{ width: "500px", marigin: "0" }}
      className={`relative z-10 mb-2 hidden w-[350px]   divide-y divide-gray-100 rounded-lg text-base  font-normal   shadow-2xl  duration-200   focus-within:bg-white md:block xl:w-[500px] ${
        suggestions.length > 0
          ? 'rounded-b-none bg-white text-slate-800'
          : 'my-card-base text-slate-800'
      }  `}
    >
      <div className="flex items-center gap-1 p-1 ">
        <Listbox
          className="relative cursor-default focus:outline-none  "
          value={selected}
          onChange={changeSearchProvider}
        >
          <div className="relative mt-2">
            <Listbox.Button
              aria-label="select search provider"
              className="flex items-center justify-center  rounded px-1 text-gray-500 focus:bg-gray-200  focus:outline-none"
            >
              <img
                className="h-10 w-10"
                src={`/assets/svg/${selected.icon}.svg`}
                alt=""
              />
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="search-provider-card absolute bottom-14 text-base">
                {Search_Providers.map((provider) => (
                  <Listbox.Option
                    key={provider.name}
                    value={provider}
                    className={({ active }) =>
                      ` hover-item relative cursor-pointer  select-none rounded py-2 pl-8 pr-4 text-slate-800  dark:text-white `
                    }
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? 'font-semibold' : 'font-normal'
                          } flex items-center gap-2 `}
                        >
                          <img
                            className="h-6 w-6"
                            src={`assets/svg/${provider.icon}.svg`}
                            alt=""
                          />

                          {provider.name}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                            <span className="absolute flex h-3 w-3">
                              <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-sky-400 opacity-75"></span>
                              <span className="relative inline-flex h-3 w-3 rounded-full bg-sky-500"></span>
                            </span>
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
        <ComboboxInput
          ref={queryRef}
          placeholder="Search"
          className={` peer h-12 w-full border-0 bg-transparent text-base text-slate-800 placeholder:text-gray-500 focus:outline-none placeholder:focus:text-gray-400 placeholder:dark:text-gray-400 `}
          onChange={handleQueryChange}
          onKeyDown={(e) => {
            e.key === 'Enter' && handleSearch()
          }}
        />
        <button
          aria-label="search"
          onClick={() => handleSearch()}
          className="rounded p-2 text-gray-500  ring-slate-400 duration-200 focus:outline-none focus:ring-1   peer-focus:text-slate-800 dark:text-gray-400"
        >
          <Search size="20" />
        </button>
      </div>
      {suggestions && suggestions.length > 0 && (
        <ComboboxPopover
          portal={false}
          style={{ width: '500px' }}
          className="scrollbar   absolute z-10 mb-2 max-h-80 overflow-auto  rounded-b-lg bg-white text-base  shadow-2xl duration-200"
        >
          <ComboboxList className="w-full bg-white  px-2 py-1">
            {suggestions.slice(0, 10).map((suggestion, index) =>
              isValidURL(suggestion) ? (
                <ComboboxOption
                  value={suggestion}
                  onClick={() => handleSelectSuggestion(suggestion)}
                  key={index}
                  className="flex w-full items-center gap-3 rounded p-1  text-gray-500"
                >
                  <span>
                    <img
                      src={getFavicon(suggestion)}
                      alt=""
                      className="h-6 w-6"
                    />
                  </span>
                  <p className="">{suggestion}</p>
                </ComboboxOption>
              ) : (
                <ComboboxOption
                  value={suggestion}
                  onClick={() => handleSelectSuggestion(suggestion)}
                  key={index}
                  className="flex w-full items-center gap-4 rounded p-1 text-gray-500 "
                >
                  <span className="ml-1">
                    <Search size="14" />
                  </span>
                  <p className="">{suggestion}</p>
                </ComboboxOption>
              ),
            )}
          </ComboboxList>
        </ComboboxPopover>
      )}
    </Combobox>
  )
}

export default Searchbar
