import React, { useContext } from 'react'
import getFavicon from '../helpers/getFavicon'
import MainContext from '../context/MainContext'

const Topsites = () => {
  const { topSites, toggleMenu } = useContext(MainContext)
  return (
    <>
      {topSites.length > 0 && (
        <div
          className={`long-card grid grid-cols-10 justify-between gap-2 ${
            toggleMenu.topSitesSingleRow ? 'grid-rows-1' : 'grid-rows-2'
          }  items-center  `}
        >
          {topSites
            .slice(0, toggleMenu.topSitesSingleRow * 10)
            .map((topsite, index) => {
              const { title, url } = topsite
              const favicon = getFavicon(url)

              return (
                <div
                  key={index}
                  data-tip={title}
                  className="tooltip tooltip-bottom hover-item flex h-10 w-10 items-center justify-center rounded-lg"
                >
                  {/* BUG : If the internet connection is not available it keeps searching for favicon */}
                  <a href={url}>
                    <img
                      className="h-8 w-8 text-sky-500"
                      src={favicon}
                      alt="favicon"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null // prevents looping
                        currentTarget.src = 'image_path_here'
                      }}
                    />
                  </a>
                </div>
              )
            })}
        </div>
      )}
    </>
  )
}

export default Topsites
