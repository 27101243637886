import React, { Fragment } from 'react'
import { Popover, Tab } from '@headlessui/react'

//icons
import { Gear } from 'react-bootstrap-icons'
import GeneralSettings from './GeneralSettings'
import BackgroundSettings from './background/BackgroundSettings'
import SiteBlocker from '../settings/siteblocker/SiteBlocker'
import { isExtension } from '../../helpers/isExtension'
import isMobile from '../../helpers/isMobile'

const Settings = () => {
  return (
    <Popover className="md:relative">
      {({ open }) => (
        <>
          <Popover.Button
            aria-label="Settings"
            className={`md:icon   ${
              open &&
              'flex items-center justify-center md:bg-slate-900/75 md:text-white md:dark:bg-white md:dark:text-slate-800 '
            }`}
          >
            <Gear
              size={20}
              className=" md:8 h-12 w-16 px-3 py-2 md:h-6 md:w-auto  md:px-0 md:py-0"
            />
          </Popover.Button>
          <Popover.Panel className="md:my-card-base  absolute bottom-14 right-5  h-[80vh] w-11/12 rounded-lg  bg-gray-800 p-2 sm:w-[550px] md:bottom-0 md:right-0 md:left-20 md:h-[520px] lg:h-[600px] lg:w-[750px]  ">
            <Tab.Group as="div" className="flex flex-col gap-2 md:flex-row ">
              <Tab.List className="flex w-full flex-row items-start gap-2 p-1 md:w-[155px] md:flex-col md:gap-4">
                {!isMobile() && (
                  <Tab as={Fragment}>
                    {({ selected }) => (
                      <button
                        className={`w-full rounded-lg px-2 py-1 text-center text-sm focus:outline-none md:text-left md:text-base
                        ${
                          selected &&
                          'bg-gray-900 font-medium text-white dark:bg-white dark:text-slate-800'
                        }`}
                      >
                        General
                      </button>
                    )}
                  </Tab>
                )}
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`w-full rounded-lg px-2 py-1 text-center text-sm focus:outline-none md:text-left md:text-base
                        ${
                          selected &&
                          'bg-gray-900 font-medium text-white dark:bg-white dark:text-slate-800'
                        }`}
                    >
                      Background
                    </button>
                  )}
                </Tab>
                {isExtension() && (
                  <Tab as={Fragment}>
                    {({ selected }) => (
                      <button
                        className={`w-full rounded-lg px-2 py-1 text-center text-sm focus:outline-none md:text-left md:text-base
                        ${
                          selected &&
                          'bg-gray-900 font-medium text-white dark:bg-white dark:text-slate-800'
                        }`}
                      >
                        Site Blocker
                      </button>
                    )}
                  </Tab>
                )}
              </Tab.List>
              <Tab.Panels className="h-[468px] w-full border-t-2 border-l-gray-300 px-1 py-2 dark:border-l-gray-500 sm:px-2 md:border-t-0 md:border-l-2 lg:h-[570px]  ">
                {!isMobile() && (
                  <Tab.Panel>
                    <GeneralSettings />
                  </Tab.Panel>
                )}
                <Tab.Panel>
                  <BackgroundSettings />
                </Tab.Panel>
                {isExtension() && (
                  <Tab.Panel>
                    <SiteBlocker />
                  </Tab.Panel>
                )}
              </Tab.Panels>
            </Tab.Group>
          </Popover.Panel>
        </>
      )}
    </Popover>
  )
}

export default Settings
