import { Dialog } from '@headlessui/react'
import { useState, useEffect } from 'react'
import { YinYang, PlayFill, PauseFill } from 'react-bootstrap-icons'

const Meditation = () => {
  const [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }
  const [timer, setTimer] = useState(10 * 60)
  const [isRunning, setIsRunning] = useState(false)
  const formatTime = (time) => {
    const hours = Math.floor(time / 3600)
    const minutes = Math.floor(time / 60) % 60
    const seconds = time % 60
    return `${hours >= 1 ? `${hours}:` : ''}${
      minutes < 10 ? '0' + minutes : minutes
    }:${seconds < 10 ? '0' + seconds : seconds}`
  }

  useEffect(() => {
    if (isRunning & (timer > 0)) {
      const intervalId = setInterval(() => {
        setTimer((prev) => prev - 1)
      }, 1000)

      return () => clearInterval(intervalId)
    }
    if (timer === 0) {
      const bells = new Audio(
        process.env.PUBLIC_URL + '/assets/sounds/bells.wav',
      )
      bells.play()
      setIsRunning(false)
      setTimer(10 * 60)
    }
  }, [isRunning, timer])

  return (
    <>
      <div className="flex w-full items-center justify-center rounded hover:cursor-pointer md:h-16 md:p-0">
        <button
          type="button"
          onClick={openModal}
          className={`${
            isOpen
              ? 'md:bg-slate-900/75 md:text-white md:dark:bg-white md:dark:text-slate-800'
              : 'md:my-card-base text-slate-800 dark:text-white'
          } flex flex-col items-center justify-center rounded outline-none  md:h-16 md:w-28`}
        >
          <YinYang
            size={24}
            className={`h-12 w-16 px-3 py-2 md:h-7 md:w-7 md:px-0 md:py-0`}
          />
          <span className="hidden text-xs md:block">Meditate</span>
        </button>
      </div>

      <Dialog
        as="div"
        open={isOpen}
        className="relative z-10"
        onClose={closeModal}
      >
        <div className="fixed inset-0 bg-white bg-opacity-20 dark:bg-black dark:bg-opacity-60" />

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center">
            <Dialog.Panel className="my-card-base  oultine-none flex h-[100vh] w-full transform flex-col items-center justify-center gap-4 overflow-hidden border-0 p-6 text-left align-middle transition-all ">
              {!isRunning ? (
                <p className="text-medium text-2xl">Start Meditation</p>
              ) : (
                <p className="text-medium text-2xl">Close Your eyes</p>
              )}
              <div className="">
                <div className="flex h-full w-full flex-col items-center justify-center gap-2">
                  <div className=" flex h-[250px]  w-[250px] animate-organic  items-center justify-center bg-gradient-to-br from-sky-400 to-green-400 text-white md:h-[300px] md:w-[300px] ">
                    <span className=" text-6xl font-medium md:text-8xl">
                      {formatTime(timer)}
                    </span>
                  </div>
                  <button onClick={() => setIsRunning((prev) => !prev)}>
                    {isRunning ? (
                      <PauseFill
                        size={60}
                        className=" outline-none
                          "
                      />
                    ) : (
                      <PlayFill size={60} className="outline-none" />
                    )}
                  </button>
                </div>
              </div>
              <div className="flex gap-4">
                <div
                  onClick={() => {
                    setIsRunning(false)
                    setTimer(5 * 60)
                  }}
                  className="btn-sec hover:btn-cta flex cursor-pointer text-center"
                >
                  5 Mins
                </div>
                <div
                  onClick={() => {
                    setIsRunning(false)
                    setTimer(10 * 60)
                  }}
                  className="btn-sec hover:btn-cta cursor-pointer text-center"
                >
                  10 Mins
                </div>
                <div
                  onClick={() => {
                    setIsRunning(false)
                    setTimer(15 * 60)
                  }}
                  className="btn-sec hover:btn-cta cursor-pointer text-center"
                >
                  15 Mins
                </div>
                <div
                  onClick={() => {
                    setIsRunning(false)
                    setTimer(20 * 60)
                  }}
                  className="btn-sec hover:btn-cta cursor-pointer text-center"
                >
                  20 Mins
                </div>
              </div>
              <div className="mt-10">
                <button
                  type="button"
                  className="btn-sec  hover:btn-cta cursor-pointer"
                  onClick={() => {
                    setIsRunning(false)
                    setTimer(10 * 60)
                    closeModal()
                  }}
                >
                  I'm Done, I feel Great!
                </button>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default Meditation
