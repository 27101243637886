import React, { useContext, useState } from 'react'
import {
  Trash,
  CalendarDate,
  HourglassBottom,
  HourglassTop,
} from 'react-bootstrap-icons'

import TodoContext from '../../context/TodoContext'
import ThemeContext from '../../context/ThemeContext'

import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

dayjs.extend(calendar)
const TodoItem = ({ todo, done = false, index }) => {
  //@dnd-kit/sortable
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: todo.id })
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const {
    id,
    task,
    priority,
    isComplete,
    timer,
    timeLeft,
    deadline,
    timeCompleted,
  } = todo
  const {
    todoTimerRef,
    formatTime,
    handleDeleteTodo,
    handleUpdate,
    handleComplete,
    handleUndoComplete,
    handleFinishedDelete,
  } = useContext(TodoContext)

  const { theme } = useContext(ThemeContext)
  const priorityColor =
    priority === '1'
      ? 'bg-red-500'
      : priority === '2'
      ? 'bg-orange-500'
      : priority === '3'
      ? 'bg-blue-500'
      : 'bg-gray-600'
  const priorityBorder =
    priority === '1'
      ? 'border-l-red-500'
      : priority === '2'
      ? 'border-l-orange-500'
      : priority === '3'
      ? 'border-l-blue-500'
      : 'border-l-gray-600'

  const [toggleDate, setToggleDate] = useState(true)
  const handleToggleDate = () => {
    setToggleDate(!toggleDate)
  }
  const [toggleTimeLeft, setToggleTimeLeft] = useState(true)
  const [readOnly, setReadOnly] = useState(true)
  const isAfter = dayjs().isAfter(dayjs(deadline), 'day')
  const isSame = dayjs().isSame(dayjs(deadline), 'day')

  const handleTodoComplete = (id) => {
    handleComplete(id)
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={` todo-item touch-none ${
        isDragging ? 'z-20 bg-white shadow-xl dark:bg-slate-800' : 'hover-item'
      }  ${priorityBorder} ${
        done ? 'cursor-default' : 'cursor-grab active:cursor-grabbing'
      } `}
    >
      <div className="flex items-center gap-1 ">
        <input
          aria-label="todo checkbox"
          className="form-checkbox h-5 w-5 cursor-pointer rounded-full checked:bg-emerald-500 checked:hover:bg-emerald-500 focus:ring-0 focus:checked:bg-emerald-500 active:ring-0 dark:bg-neutral-600 dark:checked:bg-emerald-500"
          name="isComplete"
          type="checkbox"
          checked={isComplete}
          onChange={() =>
            isComplete ? handleUndoComplete(id) : handleTodoComplete(id)
          }
        />
        {!readOnly && !isComplete ? (
          <input
            className={`focus:todo-input w-full cursor-auto bg-transparent p-1 ring-blue-400 focus:outline-none focus:ring-1`}
            type="text"
            autoFocus
            readOnly={readOnly}
            name="task"
            value={task}
            onBlur={() => setReadOnly(!readOnly)}
            onChange={(e) => handleUpdate(id, e)}
          />
        ) : (
          <p
            className="w-full cursor-pointer select-none p-1 text-base "
            onClick={() => setReadOnly(!readOnly)}
          >
            {task}
          </p>
        )}
      </div>

      <div className="flex items-center justify-between">
        <div className="flex w-full items-center">
          {!toggleDate && !isComplete ? (
            <input
              className={`todo-badge w-36 text-sm leading-4  `}
              type="date"
              autoFocus
              color-scheme={theme}
              value={deadline}
              name="deadline"
              onChange={(e) => handleUpdate(id, e)}
              onBlur={handleToggleDate}
            />
          ) : (
            <span
              className={`todo-badge flex items-center gap-1 px-2 py-1 text-sm ${
                isAfter
                  ? 'text-red-500'
                  : isSame
                  ? 'text-amber-500'
                  : 'text-slate-700  dark:text-slate-200'
              } ${isComplete && 'text-emerald-500 dark:text-emerald-400 '} `}
              onClick={handleToggleDate}
            >
              <CalendarDate
                size={16}
                className="text-slate-800 dark:text-white"
              />
              <p>
                {dayjs(deadline).isValid()
                  ? dayjs(deadline).calendar(null, {
                      lastDay: '[Yesterday]',
                      sameDay: '[Today]',
                      nextDay: '[Tomorrow]',
                      lastWeek: 'ddd, D MMM',
                      nextWeek: 'ddd, D MMM',
                      sameElse: 'ddd, D MMM',
                    })
                  : 'Add Deadline'}
              </p>
            </span>
          )}
        </div>
        <div className="flex items-center gap-3">
          {toggleTimeLeft && !isComplete ? (
            <span
              onClick={() => setToggleTimeLeft(false)}
              className="todo-badge flex items-center gap-1 text-sm"
            >
              <HourglassTop size={16} />
              <span>{formatTime(timeLeft)}</span>
            </span>
          ) : !toggleTimeLeft && !isComplete ? (
            <input
              data-id={id}
              min="0"
              step="5"
              autoFocus
              className="todo-badge w-16 px-2 py-1 text-sm"
              type="number"
              name="timer"
              id="timer"
              ref={(el) => (todoTimerRef.current[index] = el)}
              value={timer}
              onBlur={() => setToggleTimeLeft(true)}
              onChange={(e) => !isComplete && handleUpdate(id, e, index)}
            />
          ) : (
            <div className="todo-badge flex items-center gap-1">
              <HourglassBottom size={16} className="h-4 w-4" />
              <p>{formatTime(timeCompleted)}</p>
            </div>
          )}

          <select
            name="priority"
            className={`cursor-pointer rounded px-2 py-1  text-xs font-medium text-white  ${priorityColor}  flex appearance-none items-center justify-center  focus:outline-none`}
            value={priority}
            onChange={(e) => !isComplete && handleUpdate(id, e)}
            disabled={isComplete}
          >
            <option value="1" className=" bg-red-500 font-medium ">
              P1
            </option>
            <option value="2" className="  bg-orange-500   font-medium">
              P2
            </option>
            <option value="3" className=" bg-blue-500   font-medium">
              P3
            </option>
            <option value="4" className="  bg-gray-600  font-medium">
              P4
            </option>
          </select>

          <button
            aria-label="delete"
            onClick={() =>
              isComplete ? handleFinishedDelete(id) : handleDeleteTodo(id)
            }
          >
            <Trash className="text-gray-800 dark:text-gray-300" size={20} />
          </button>
        </div>
      </div>
    </div>
  )
}

export default TodoItem
