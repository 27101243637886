import React, { useContext } from 'react'
import AddWidget from '../components/widgets/AddWidget'
import Countdown from '../components/widgets/countdown/Countdown'
import Counter from '../components/widgets/counter/Counter'
import Time from '../components/widgets/time/Time'
import MainContext from '../context/MainContext'
import WidgetContext from '../context/WidgetContext'
import { AnimatePresence } from 'framer-motion'

import {
  closestCenter,
  DndContext,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  restrictToHorizontalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers'
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable'
import TimeZone from '../components/widgets/time/TimeZone'

const Widgets = () => {
  const { toggleMenu } = useContext(MainContext)

  const { widgets, setWidgets } = useContext(WidgetContext)
  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 5,
    },
  })
  const touchSensor = useSensor(TouchSensor, {
    // Press delay of 250ms, with tolerance of 5px of movement
    activationConstraint: {
      delay: 250,
      tolerance: 5,
    },
  })

  const sensors = useSensors(pointerSensor, touchSensor)

  function handleDragEnd(event) {
    const { active, over } = event

    if (active.id !== over.id) {
      setWidgets((widgets) => {
        const oldIndex = widgets.findIndex((widget) => widget.id === active.id)
        const newIndex = widgets.findIndex((widget) => widget.id === over.id)

        return arrayMove(widgets, oldIndex, newIndex)
      })
    }
  }
  // TODO add info on hover
  // TODO add weekly major tasks checklist with deadlines
  return (
    <div className="absolute top-0 right-0">
      <DndContext
        modifiers={[restrictToHorizontalAxis, restrictToParentElement]}
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <div
          id="widgets"
          className="widgets group-widgets scrollbar duration-400 flex flex-row-reverse flex-wrap items-center gap-2 "
        >
          <AnimatePresence>{toggleMenu.clock && <Time />}</AnimatePresence>
          <AnimatePresence>
            {toggleMenu.widgets && (
              <>
                <SortableContext
                  items={widgets}
                  strategy={horizontalListSortingStrategy}
                >
                  {widgets.length > 0 &&
                    widgets.map((widget) =>
                      widget.type === 'countdown' ? (
                        <Countdown key={widget.id} countdown={widget} />
                      ) : widget.type === 'counter' ? (
                        <Counter key={widget.id} counter={widget} />
                      ) : widget.type === 'timezone' ? (
                        <TimeZone key={widget.id} tz={widget} />
                      ) : // ) : widget.type === "metrics" ? (
                      //   <Metrics key={widget.id} metrics={widget} />
                      null,
                    )}
                </SortableContext>
                <AddWidget />
              </>
            )}
          </AnimatePresence>
        </div>
      </DndContext>
    </div>
  )
}

export default Widgets
