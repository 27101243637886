//TODO add !! to the end of the sentence
const congrats = [
  'Keep it up!',
  'well done!',
  'you are doing great!',
  'You did it, Keep Going',
  'You are awesome',
  'You are crushing it',
  'You are on a streak',
  'You are doing awesome!',
  'You did it! So proud of you!',
  'We knew it was only a matter of time. Well done!',
  'Bravo',
  "You're awesome! Way to go!",
  'Woo-Hoo',
  'Hooray',
  'You are a winner!',
  'Keep up the great work',
  'Wonderful',
  'Amazing',
  'Impressive',
  'Awesome',
  'Good job',
  'Great job',
  'Excellent job',
  'High five',
  'Way to go',
  'You inspire me',
  'You amaze me',
]

export default congrats
