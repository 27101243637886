//TODO Cache favicons

//get favicons of given link and cache them
//TODO add default favicons for localhost / extension urls

export default function getFavicon(link) {
  const size = '32'
  const url = link

  const isLocalHost = link.includes('http://localhost')

  const isChromeExt = link.includes('chrome-extension://')

  const favicon = isLocalHost
    ? 'codefile.svg'
    : isChromeExt
    ? 'puzzle.svg'
    : link.includes('mail.google.com') || link.includes('gmail.com')
    ? 'https://ssl.gstatic.com/ui/v1/icons/mail/rfr/gmail.ico' ||
      'https://mail.google.com/favicon.ico'
    : `https://s2.googleusercontent.com/s2/favicons?domain=${url}&sz=${size}`

  return favicon
}
