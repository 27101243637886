import React, { useContext } from 'react'
import {
  PlusCircle,
  Alarm,
  Clock,
  JournalPlus,
  BarChart,
} from 'react-bootstrap-icons'
import { Popover } from '@headlessui/react'
import WidgetContext from '../../context/WidgetContext'
import { nanoid } from 'nanoid'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import AuthContext from '../../context/AuthContext'
import GetProModal from '../shared/GetProModal'

dayjs.extend(utc)
const AddWidget = () => {
  const { widgets, setWidgets } = useContext(WidgetContext)
  const { user } = useContext(AuthContext)

  const addNewCountDownWidget = () => {
    const newCountdown = {
      type: 'countdown',
      id: nanoid(8),
      title: 'DeadLine',
      date: dayjs().add(1, 'day').format('YYYY-MM-DD'),
      time: '00:00',
    }
    setWidgets([...widgets, newCountdown])
  }

  const addNewTimeZoneWidget = () => {
    const newTimezone = {
      type: 'timezone',
      id: nanoid(8),
      title: 'Pacific Time',
      city: 'Los Angeles',
      timezone: 'America/Los_Angeles',
    }
    setWidgets([...widgets, newTimezone])
  }

  const addNewCounterWidget = () => {
    const newCounter = {
      type: 'counter',
      id: nanoid(8),
      title: 'Streak',
      count: 0,
    }
    setWidgets([...widgets, newCounter])
  }
  // const addNewMetricsWidget = () => {
  //   const newMetrics = {
  //     type: "metrics",
  //     id: nanoid(8),
  //     title: "Metrics",
  //     metric: 0,
  //   };
  //   setWidgets([...widgets, newMetrics]);
  // };

  return (
    <>
      <Popover className="relative ">
        {({ open }) => (
          <div
            className={` group-widgets-hover:block ${
              open ? 'block' : 'hidden'
            }`}
          >
            <Popover.Button
              aria-label="add widget"
              className={`flex ${
                open && 'block'
              } my-card flex-col items-center justify-center gap-1 rounded-lg p-2 text-sm text-slate-800 outline-none dark:text-white `}
            >
              <PlusCircle size={24} />
              <p>Add Widget</p>
            </Popover.Button>
            <Popover.Panel className="my-card-base absolute  top-[70px] right-0 z-50 rounded-lg p-2  ">
              {({ close }) => (
                <div className="flex items-center  gap-4">
                  <button
                    onClick={() => {
                      addNewCounterWidget()
                      close()
                    }}
                    className="hover-item flex flex-col items-center justify-center gap-1 rounded p-2"
                  >
                    <span>
                      <JournalPlus size={24} />
                    </span>
                    <p>Counter</p>
                  </button>
                  <button
                    onClick={() => {
                      addNewCountDownWidget()
                      close()
                    }}
                    className="hover-item flex flex-col items-center justify-center gap-1 rounded p-2"
                  >
                    <span>
                      <Alarm size={24} />
                    </span>
                    <p>Countdown</p>
                  </button>
                  {/* <button
                  onClick={addNewMetricsWidget}
                  className="flex flex-col p-2 rounded items-center justify-center gap-1 hover-item"
                >
                  <span>
                    <BarChart size={24} />
                  </span>
                  <p>Metrics</p>
                </button> */}
                  <button
                    onClick={addNewTimeZoneWidget}
                    className="hover-item flex flex-col items-center justify-center gap-1 rounded p-2"
                  >
                    <span>
                      <Clock size={24} />
                    </span>
                    <p>TimeZone</p>
                  </button>
                </div>
              )}
            </Popover.Panel>
          </div>
        )}
      </Popover>
    </>
  )
}

export default AddWidget
