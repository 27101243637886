import React, { useState } from 'react'
import { Popover } from '@headlessui/react'
import { Box, Lightbulb } from 'react-bootstrap-icons'
import CardWithLink from '../../shared/CardWithLink'

const TipsTricks = () => {
  const tipsList = [
    {
      id: 1,
      title: 'Inbox Zero',
      content: 'Organize your inbox and never miss an in important email',
      link: 'https://www.youtube.com/watch?v=9ql1CQfxWxQ',
    },
    {
      id: 2,
      title: 'Canva tips',
      content: 'Create beautiful designs with Canva',
      link: 'https://www.youtube.com/watch?v=2XqSMu9KVY0',
    },
  ]
  return (
    <>
      <Popover className="md:relative">
        {({ open }) => (
          <>
            <Popover.Button
              aria-label="tips-tricks"
              id="tips-tricks"
              className={` 
            ${
              open
                ? 'md:bg-slate-900/75 md:text-white md:dark:bg-white md:dark:text-slate-800'
                : 'md:my-card-base text-slate-800 dark:text-white'
            } flex flex-col items-center justify-center rounded outline-none  md:h-16 md:w-28`}
            >
              <Lightbulb
                size={20}
                className=" h-12 w-16 px-3 py-2 md:h-7 md:w-7 md:px-0 md:py-0"
              />
              <span className="hidden text-xs md:block">Tips & Tricks</span>
            </Popover.Button>
            <Popover.Panel
              className={`md:my-card md: md:my-card absolute bottom-14 left-5 z-10 h-[80vh] min-w-[90%] max-w-[90vw] cursor-default  overflow-hidden rounded-lg bg-gray-800 p-4 outline-none xs:left-10 sm:w-[400px] sm:max-w-[400px]  md:-bottom-[173px] md:right-0 md:left-[120px] md:h-[550px]`}
            >
              <div className="flex flex-col gap-3">
                <p className="text-center text-xl font-medium">Tips & Tricks</p>

                <div className="scrollbar grid h-[480px] grid-cols-2 gap-2 overflow-hidden overflow-y-auto ">
                  {tipsList.map((tip) => (
                    <CardWithLink
                      key={tip.id}
                      title={tip.title}
                      content={tip.content}
                      link={tip.link}
                    />
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </>
  )
}

export default TipsTricks
