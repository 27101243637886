import axios from 'axios'
import React, { useContext } from 'react'

import { useQuery } from '@tanstack/react-query'
import MainContext from '../../../context/MainContext'
import { CheckCircleFill, Image } from 'react-bootstrap-icons'

const BgWallpapers = () => {
  const { setPhoto, setBackgroundType } = useContext(MainContext)
  const [queryType, setQueryType] = React.useState('collections') // collections or search
  const [query, setQuery] = React.useState('nature')
  const wallpaperInstance = axios.create({
    baseURL: 'https://api.pexels.com/v1/',
    headers: {
      Authorization: `${process.env.REACT_APP_PEXELS_API_KEY}`,
    },
  })
  const fetchPhotos = async () => {
    const response =
      queryType === 'collections'
        ? await wallpaperInstance.get('collections/a8nblwb?per_page=80')
        : await wallpaperInstance.get(
            `search?query=${query}&per_page=80&orientation=landscape&size=medium`,
          )

    return queryType === 'collections'
      ? response.data.media
      : response.data.photos
  }
  const { data, isLoading, isError, error } = useQuery(
    ['photos', query, queryType],
    fetchPhotos,
  )
  const handlePexelPic = (e, photo) => {
    e.preventDefault()
    setPhoto({
      url: photo.src.large2x,
      thumbnail: photo.src.small,
      photographer: photo.photographer,
      photographer_url: photo.photographer_url,
      avgColor: photo.avg_color,
      name: photo.alt,
      credit_url: photo.url,
    })
    setBackgroundType('photo')
  }

  return (
    <div className="flex flex-col gap-2 ">
      <div className="scrollbar max z-10 flex max-w-[310px] flex-row  flex-wrap gap-1 overflow-hidden  overflow-x-auto xs:max-w-[400px]  sm:max-w-[500px]  md:flex-nowrap">
        {/* TODO add tooltip photos by pexels */}
        <span>
          <img
            src="/assets/svg/pexels.svg"
            className="h-8 w-8"
            alt="pexels logo"
          />
        </span>
        <button
          onClick={() => {
            setQueryType('collections')
          }}
          className="btn-sec text-xs sm:text-sm"
        >
          Flow
        </button>
        <button
          onClick={() => {
            setQueryType('search')
            setQuery('nature')
          }}
          className="btn-sec text-xs sm:text-sm"
        >
          Nature
        </button>
        <button
          onClick={() => {
            setQueryType('search')
            setQuery('ocean')
          }}
          className="btn-sec text-xs sm:text-sm"
        >
          Ocean
        </button>
        <button
          onClick={() => {
            setQueryType('search')
            setQuery('art')
          }}
          className="btn-sec text-xs sm:text-sm"
        >
          Art
        </button>
        <button
          onClick={() => {
            setQueryType('search')
            setQuery('cute%20animals')
          }}
          className="btn-sec text-xs sm:text-sm"
        >
          Animals
        </button>
        <button
          onClick={() => {
            setQueryType('search')
            setQuery('space')
          }}
          className="btn-sec text-xs sm:text-sm"
        >
          Space
        </button>
        <button
          onClick={() => {
            setQueryType('search')
            setQuery('calm')
          }}
          className="btn-sec text-xs sm:text-sm"
        >
          Calm
        </button>
      </div>
      <div className="scrollbar grid max-h-[400px] w-full grid-cols-2 place-items-center gap-2 overflow-hidden overflow-y-auto rounded md:gap-2 lg:max-h-[480px]  lg:grid-cols-3">
        {isLoading &&
          Array.from({ length: 20 }).map((_, i) => (
            <div
              key={i}
              role="status"
              className="group relative flex h-[100px] w-[170px] animate-pulse items-center justify-center overflow-hidden  rounded-lg bg-gray-300 dark:bg-gray-600"
            >
              <Image size={30} />

              <span className="sr-only">Loading...</span>
            </div>
          ))}
        {isError && <p>{error.message}</p>}

        {data &&
          data.map((photo) => {
            return (
              <div
                key={photo.id}
                className={`group relative max-h-[100px] max-w-[170px] overflow-hidden rounded-lg`}
              >
                <img
                  className={`h-[100px] w-[170px] object-cover `}
                  style={{
                    backgroundColor: photo.avg_color,
                  }}
                  src={photo.src.small}
                  alt={photo.alt}
                />
                <span>
                  <button
                    onClick={(e) => handlePexelPic(e, photo)}
                    className="absolute bottom-1 right-1 hidden rounded bg-white px-2 py-1 text-black group-hover:block "
                  >
                    <CheckCircleFill size={24} />
                  </button>
                </span>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default BgWallpapers
