import React from 'react'
import Settings from '../settings/Settings'
import Meditation from '../sideFeatures/meditation/Meditation'
import User from '../user/User'
import Soundscapes from '../sideFeatures/soundscapes/Soundscapes'
import ThemeToggle from '../shared/ThemeToggle'
import { UiChecks } from 'react-bootstrap-icons'
import TipsTricks from '../sideFeatures/tips-tricks/TipsTricks'
import MentalModels from '../sideFeatures/mental-Models/MentalModels'
import HabitTracker from '../sideFeatures/habit-tracker'
import { HabitProvider } from '../../context/HabitContext'

const Menu = () => {
  return (
    <div className=" mobile-menu absolute bottom-0 z-20 flex   h-12 w-full flex-row gap-2 rounded-t-sm px-2 py-1 md:relative md:z-10 md:h-auto md:w-auto md:flex-col-reverse md:gap-1 md:rounded-t-none md:px-0 md:py-0 ">
      <span className="nav-item md:hidden">
        <UiChecks size={24} />
      </span>
      <div className="hidden gap-2 md:flex ">
        <span className="">
          <User />
        </span>
        <span className="">
          <Settings />
        </span>
        <span className="">
          <ThemeToggle />
        </span>
      </div>

      <span className="nav-item">
        <Soundscapes />
      </span>
      <HabitProvider>
        <span className="nav-item">
          <HabitTracker />
        </span>
      </HabitProvider>
      <span className="nav-item">
        <TipsTricks />
      </span>
      <span className="nav-item">
        <MentalModels />
      </span>
      <span className="nav-item">
        <Meditation />
      </span>
      <span className="nav-item md:hidden ">
        <Settings />
      </span>
    </div>
  )
}

export default Menu
