const timezones=[
  {
    "name": "alofi",
    "timezone": "pacific/niue",
    "offset": -660,
    "abbreviation": "nut",
    "alternateName": "niue time",
    "country": "niue"
  },
  {
    "name": "midway",
    "timezone": "pacific/midway",
    "offset": -660,
    "abbreviation": "sst",
    "alternateName": "samoa time",
    "country": "united states minor outlying islands"
  },
  {
    "name": "pago pago",
    "timezone": "pacific/pago_pago",
    "offset": -660,
    "abbreviation": "sst",
    "alternateName": "samoa time",
    "country": "american samoa"
  },
  {
    "name": "avarua",
    "timezone": "pacific/rarotonga",
    "offset": -600,
    "abbreviation": "ckt",
    "alternateName": "cook islands time",
    "country": "cook islands"
  },
  {
    "name": "honolulu",
    "timezone": "pacific/honolulu",
    "offset": -600,
    "abbreviation": "hast",
    "alternateName": "hawaii-aleutian time",
    "country": "united states"
  },
  {
    "name": "east honolulu",
    "timezone": "pacific/honolulu",
    "offset": -600,
    "abbreviation": "hast",
    "alternateName": "hawaii-aleutian time",
    "country": "united states"
  },
  {
    "name": "pearl city",
    "timezone": "pacific/honolulu",
    "offset": -600,
    "abbreviation": "hast",
    "alternateName": "hawaii-aleutian time",
    "country": "united states"
  },
  {
    "name": "hilo",
    "timezone": "pacific/honolulu",
    "offset": -600,
    "abbreviation": "hast",
    "alternateName": "hawaii-aleutian time",
    "country": "united states"
  },
  {
    "name": "faaa",
    "timezone": "pacific/tahiti",
    "offset": -600,
    "abbreviation": "taht",
    "alternateName": "tahiti time",
    "country": "french polynesia"
  },
  {
    "name": "papeete",
    "timezone": "pacific/tahiti",
    "offset": -600,
    "abbreviation": "taht",
    "alternateName": "tahiti time",
    "country": "french polynesia"
  },
  {
    "name": "punaauia",
    "timezone": "pacific/tahiti",
    "offset": -600,
    "abbreviation": "taht",
    "alternateName": "tahiti time",
    "country": "french polynesia"
  },
  {
    "name": "marquesas",
    "timezone": "pacific/marquesas",
    "offset": -570,
    "abbreviation": "mart",
    "alternateName": "marquesas time",
    "country": "french polynesia"
  },
  {
    "name": "gambier",
    "timezone": "pacific/gambier",
    "offset": -540,
    "abbreviation": "gamt",
    "alternateName": "gambier time",
    "country": "french polynesia"
  },
  {
    "name": "adak",
    "timezone": "america/adak",
    "offset": -600,
    "abbreviation": "hast",
    "alternateName": "hawaii-aleutian time",
    "country": "united states"
  },
  {
    "name": "anchorage",
    "timezone": "america/anchorage",
    "offset": -540,
    "abbreviation": "akst",
    "alternateName": "alaska time",
    "country": "united states"
  },
  {
    "name": "juneau",
    "timezone": "america/anchorage",
    "offset": -540,
    "abbreviation": "akst",
    "alternateName": "alaska time",
    "country": "united states"
  },
  {
    "name": "fairbanks",
    "timezone": "america/anchorage",
    "offset": -540,
    "abbreviation": "akst",
    "alternateName": "alaska time",
    "country": "united states"
  },
  {
    "name": "eagle river",
    "timezone": "america/anchorage",
    "offset": -540,
    "abbreviation": "akst",
    "alternateName": "alaska time",
    "country": "united states"
  },
  {
    "name": "adamstown",
    "timezone": "pacific/pitcairn",
    "offset": -480,
    "abbreviation": "pst",
    "alternateName": "pitcairn time",
    "country": "pitcairn"
  },
  {
    "name": "hermosillo",
    "timezone": "america/hermosillo",
    "offset": -420,
    "abbreviation": "gmt-7",
    "alternateName": "mexican pacific time",
    "country": "mexico"
  },
  {
    "name": "ciudad obregon",
    "timezone": "america/hermosillo",
    "offset": -420,
    "abbreviation": "gmt-7",
    "alternateName": "mexican pacific time",
    "country": "mexico"
  },
  {
    "name": "nogales",
    "timezone": "america/hermosillo",
    "offset": -420,
    "abbreviation": "gmt-7",
    "alternateName": "mexican pacific time",
    "country": "mexico"
  },
  {
    "name": "san luis rio colorado",
    "timezone": "america/hermosillo",
    "offset": -420,
    "abbreviation": "gmt-7",
    "alternateName": "mexican pacific time",
    "country": "mexico"
  },
  {
    "name": "phoenix",
    "timezone": "america/phoenix",
    "offset": -420,
    "abbreviation": "mst",
    "alternateName": "mountain time",
    "country": "united states"
  },
  {
    "name": "tucson",
    "timezone": "america/phoenix",
    "offset": -420,
    "abbreviation": "mst",
    "alternateName": "mountain time",
    "country": "united states"
  },
  {
    "name": "mesa",
    "timezone": "america/phoenix",
    "offset": -420,
    "abbreviation": "mst",
    "alternateName": "mountain time",
    "country": "united states"
  },
  {
    "name": "chandler",
    "timezone": "america/phoenix",
    "offset": -420,
    "abbreviation": "mst",
    "alternateName": "mountain time",
    "country": "united states"
  },
  {
    "name": "los angeles",
    "timezone": "america/los_angeles",
    "offset": -480,
    "abbreviation": "pst",
    "alternateName": "pacific time",
    "country": "united states"
  },
  {
    "name": "san diego",
    "timezone": "america/los_angeles",
    "offset": -480,
    "abbreviation": "pst",
    "alternateName": "pacific time",
    "country": "united states"
  },
  {
    "name": "san jose",
    "timezone": "america/los_angeles",
    "offset": -480,
    "abbreviation": "pst",
    "alternateName": "pacific time",
    "country": "united states"
  },
  {
    "name": "san francisco",
    "timezone": "america/los_angeles",
    "offset": -480,
    "abbreviation": "pst",
    "alternateName": "pacific time",
    "country": "united states"
  },
  {
    "name": "tijuana",
    "timezone": "america/tijuana",
    "offset": -480,
    "abbreviation": "pst",
    "alternateName": "pacific time",
    "country": "mexico"
  },
  {
    "name": "mexicali",
    "timezone": "america/tijuana",
    "offset": -480,
    "abbreviation": "pst",
    "alternateName": "pacific time",
    "country": "mexico"
  },
  {
    "name": "ensenada",
    "timezone": "america/tijuana",
    "offset": -480,
    "abbreviation": "pst",
    "alternateName": "pacific time",
    "country": "mexico"
  },
  {
    "name": "rosarito",
    "timezone": "america/tijuana",
    "offset": -480,
    "abbreviation": "pst",
    "alternateName": "pacific time",
    "country": "mexico"
  },
  {
    "name": "vancouver",
    "timezone": "america/vancouver",
    "offset": -480,
    "abbreviation": "pst",
    "alternateName": "pacific time",
    "country": "canada"
  },
  {
    "name": "surrey",
    "timezone": "america/vancouver",
    "offset": -480,
    "abbreviation": "pst",
    "alternateName": "pacific time",
    "country": "canada"
  },
  {
    "name": "okanagan",
    "timezone": "america/vancouver",
    "offset": -480,
    "abbreviation": "pst",
    "alternateName": "pacific time",
    "country": "canada"
  },
  {
    "name": "victoria",
    "timezone": "america/vancouver",
    "offset": -480,
    "abbreviation": "pst",
    "alternateName": "pacific time",
    "country": "canada"
  },
  {
    "name": "whitehorse",
    "timezone": "america/whitehorse",
    "offset": -420,
    "abbreviation": "yt",
    "alternateName": "yukon time",
    "country": "canada"
  },
  {
    "name": "fort st. john",
    "timezone": "america/whitehorse",
    "offset": -420,
    "abbreviation": "yt",
    "alternateName": "yukon time",
    "country": "canada"
  },
  {
    "name": "creston",
    "timezone": "america/whitehorse",
    "offset": -420,
    "abbreviation": "yt",
    "alternateName": "yukon time",
    "country": "canada"
  },
  {
    "name": "dawson",
    "timezone": "america/whitehorse",
    "offset": -420,
    "abbreviation": "yt",
    "alternateName": "yukon time",
    "country": "canada"
  },
  {
    "name": "belize city",
    "timezone": "america/belize",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "belize"
  },
  {
    "name": "san ignacio",
    "timezone": "america/belize",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "belize"
  },
  {
    "name": "san pedro",
    "timezone": "america/belize",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "belize"
  },
  {
    "name": "orange walk",
    "timezone": "america/belize",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "belize"
  },
  {
    "name": "guatemala city",
    "timezone": "america/guatemala",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "guatemala"
  },
  {
    "name": "mixco",
    "timezone": "america/guatemala",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "guatemala"
  },
  {
    "name": "villa nueva",
    "timezone": "america/guatemala",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "guatemala"
  },
  {
    "name": "petapa",
    "timezone": "america/guatemala",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "guatemala"
  },
  {
    "name": "managua",
    "timezone": "america/managua",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "nicaragua"
  },
  {
    "name": "leon",
    "timezone": "america/managua",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "nicaragua"
  },
  {
    "name": "masaya",
    "timezone": "america/managua",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "nicaragua"
  },
  {
    "name": "chinandega",
    "timezone": "america/managua",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "nicaragua"
  },
  {
    "name": "san jose",
    "timezone": "america/costa_rica",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "costa rica"
  },
  {
    "name": "limon",
    "timezone": "america/costa_rica",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "costa rica"
  },
  {
    "name": "san francisco",
    "timezone": "america/costa_rica",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "costa rica"
  },
  {
    "name": "alajuela",
    "timezone": "america/costa_rica",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "costa rica"
  },
  {
    "name": "san salvador",
    "timezone": "america/el_salvador",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "el salvador"
  },
  {
    "name": "soyapango",
    "timezone": "america/el_salvador",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "el salvador"
  },
  {
    "name": "santa ana",
    "timezone": "america/el_salvador",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "el salvador"
  },
  {
    "name": "san miguel",
    "timezone": "america/el_salvador",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "el salvador"
  },
  {
    "name": "saskatoon",
    "timezone": "america/regina",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "canada"
  },
  {
    "name": "regina",
    "timezone": "america/regina",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "canada"
  },
  {
    "name": "prince albert",
    "timezone": "america/regina",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "canada"
  },
  {
    "name": "moose jaw",
    "timezone": "america/regina",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "canada"
  },
  {
    "name": "tegucigalpa",
    "timezone": "america/tegucigalpa",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "honduras"
  },
  {
    "name": "san pedro sula",
    "timezone": "america/tegucigalpa",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "honduras"
  },
  {
    "name": "choloma",
    "timezone": "america/tegucigalpa",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "honduras"
  },
  {
    "name": "la ceiba",
    "timezone": "america/tegucigalpa",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "honduras"
  },
  {
    "name": "easter",
    "timezone": "pacific/easter",
    "offset": -360,
    "abbreviation": "east",
    "alternateName": "easter island time",
    "country": "chile"
  },
  {
    "name": "galapagos",
    "timezone": "pacific/galapagos",
    "offset": -360,
    "abbreviation": "galt",
    "alternateName": "galapagos time",
    "country": "ecuador"
  },
  {
    "name": "calgary",
    "timezone": "america/edmonton",
    "offset": -420,
    "abbreviation": "mst",
    "alternateName": "mountain time",
    "country": "canada"
  },
  {
    "name": "edmonton",
    "timezone": "america/edmonton",
    "offset": -420,
    "abbreviation": "mst",
    "alternateName": "mountain time",
    "country": "canada"
  },
  {
    "name": "red deer",
    "timezone": "america/edmonton",
    "offset": -420,
    "abbreviation": "mst",
    "alternateName": "mountain time",
    "country": "canada"
  },
  {
    "name": "sherwood park",
    "timezone": "america/edmonton",
    "offset": -420,
    "abbreviation": "mst",
    "alternateName": "mountain time",
    "country": "canada"
  },
  {
    "name": "ciudad juarez",
    "timezone": "america/ojinaga",
    "offset": -420,
    "abbreviation": "mst",
    "alternateName": "mountain time",
    "country": "mexico"
  },
  {
    "name": "chihuahua",
    "timezone": "america/ojinaga",
    "offset": -420,
    "abbreviation": "mst",
    "alternateName": "mountain time",
    "country": "mexico"
  },
  {
    "name": "culiacan",
    "timezone": "america/ojinaga",
    "offset": -420,
    "abbreviation": "mst",
    "alternateName": "mountain time",
    "country": "mexico"
  },
  {
    "name": "mazatlan",
    "timezone": "america/ojinaga",
    "offset": -420,
    "abbreviation": "mst",
    "alternateName": "mountain time",
    "country": "mexico"
  },
  {
    "name": "denver",
    "timezone": "america/denver",
    "offset": -420,
    "abbreviation": "mst",
    "alternateName": "mountain time",
    "country": "united states"
  },
  {
    "name": "el paso",
    "timezone": "america/denver",
    "offset": -420,
    "abbreviation": "mst",
    "alternateName": "mountain time",
    "country": "united states"
  },
  {
    "name": "albuquerque",
    "timezone": "america/denver",
    "offset": -420,
    "abbreviation": "mst",
    "alternateName": "mountain time",
    "country": "united states"
  },
  {
    "name": "colorado springs",
    "timezone": "america/denver",
    "offset": -420,
    "abbreviation": "mst",
    "alternateName": "mountain time",
    "country": "united states"
  },
  {
    "name": "rio branco",
    "timezone": "america/rio_branco",
    "offset": -300,
    "abbreviation": "act",
    "alternateName": "acre time",
    "country": "brazil"
  },
  {
    "name": "cruzeiro do sul",
    "timezone": "america/rio_branco",
    "offset": -300,
    "abbreviation": "act",
    "alternateName": "acre time",
    "country": "brazil"
  },
  {
    "name": "sena madureira",
    "timezone": "america/rio_branco",
    "offset": -300,
    "abbreviation": "act",
    "alternateName": "acre time",
    "country": "brazil"
  },
  {
    "name": "eirunepe",
    "timezone": "america/rio_branco",
    "offset": -300,
    "abbreviation": "act",
    "alternateName": "acre time",
    "country": "brazil"
  },
  {
    "name": "chicago",
    "timezone": "america/chicago",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "united states"
  },
  {
    "name": "houston",
    "timezone": "america/chicago",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "united states"
  },
  {
    "name": "san antonio",
    "timezone": "america/chicago",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "united states"
  },
  {
    "name": "dallas",
    "timezone": "america/chicago",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "united states"
  },
  {
    "name": "mexico city",
    "timezone": "america/mexico_city",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "mexico"
  },
  {
    "name": "iztapalapa",
    "timezone": "america/mexico_city",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "mexico"
  },
  {
    "name": "ecatepec de morelos",
    "timezone": "america/mexico_city",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "mexico"
  },
  {
    "name": "guadalajara",
    "timezone": "america/mexico_city",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "mexico"
  },
  {
    "name": "winnipeg",
    "timezone": "america/winnipeg",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "canada"
  },
  {
    "name": "brandon",
    "timezone": "america/winnipeg",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "canada"
  },
  {
    "name": "steinbach",
    "timezone": "america/winnipeg",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "canada"
  },
  {
    "name": "kenora",
    "timezone": "america/winnipeg",
    "offset": -360,
    "abbreviation": "cst",
    "alternateName": "central time",
    "country": "canada"
  },
  {
    "name": "bogota",
    "timezone": "america/bogota",
    "offset": -300,
    "abbreviation": "cot",
    "alternateName": "colombia time",
    "country": "colombia"
  },
  {
    "name": "cali",
    "timezone": "america/bogota",
    "offset": -300,
    "abbreviation": "cot",
    "alternateName": "colombia time",
    "country": "colombia"
  },
  {
    "name": "medellin",
    "timezone": "america/bogota",
    "offset": -300,
    "abbreviation": "cot",
    "alternateName": "colombia time",
    "country": "colombia"
  },
  {
    "name": "barranquilla",
    "timezone": "america/bogota",
    "offset": -300,
    "abbreviation": "cot",
    "alternateName": "colombia time",
    "country": "colombia"
  },
  {
    "name": "atikokan",
    "timezone": "america/atikokan",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "canada"
  },
  {
    "name": "cancun",
    "timezone": "america/cancun",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "mexico"
  },
  {
    "name": "chetumal",
    "timezone": "america/cancun",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "mexico"
  },
  {
    "name": "playa del carmen",
    "timezone": "america/cancun",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "mexico"
  },
  {
    "name": "cozumel",
    "timezone": "america/cancun",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "mexico"
  },
  {
    "name": "george town",
    "timezone": "america/cayman",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "cayman islands"
  },
  {
    "name": "kingston",
    "timezone": "america/jamaica",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "jamaica"
  },
  {
    "name": "new kingston",
    "timezone": "america/jamaica",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "jamaica"
  },
  {
    "name": "spanish town",
    "timezone": "america/jamaica",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "jamaica"
  },
  {
    "name": "portmore",
    "timezone": "america/jamaica",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "jamaica"
  },
  {
    "name": "panama",
    "timezone": "america/panama",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "panama"
  },
  {
    "name": "san miguelito",
    "timezone": "america/panama",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "panama"
  },
  {
    "name": "juan diaz",
    "timezone": "america/panama",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "panama"
  },
  {
    "name": "david",
    "timezone": "america/panama",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "panama"
  },
  {
    "name": "guayaquil",
    "timezone": "america/guayaquil",
    "offset": -300,
    "abbreviation": "ect",
    "alternateName": "ecuador time",
    "country": "ecuador"
  },
  {
    "name": "quito",
    "timezone": "america/guayaquil",
    "offset": -300,
    "abbreviation": "ect",
    "alternateName": "ecuador time",
    "country": "ecuador"
  },
  {
    "name": "cuenca",
    "timezone": "america/guayaquil",
    "offset": -300,
    "abbreviation": "ect",
    "alternateName": "ecuador time",
    "country": "ecuador"
  },
  {
    "name": "santo domingo de los colorados",
    "timezone": "america/guayaquil",
    "offset": -300,
    "abbreviation": "ect",
    "alternateName": "ecuador time",
    "country": "ecuador"
  },
  {
    "name": "lima",
    "timezone": "america/lima",
    "offset": -300,
    "abbreviation": "pet",
    "alternateName": "peru time",
    "country": "peru"
  },
  {
    "name": "arequipa",
    "timezone": "america/lima",
    "offset": -300,
    "abbreviation": "pet",
    "alternateName": "peru time",
    "country": "peru"
  },
  {
    "name": "callao",
    "timezone": "america/lima",
    "offset": -300,
    "abbreviation": "pet",
    "alternateName": "peru time",
    "country": "peru"
  },
  {
    "name": "trujillo",
    "timezone": "america/lima",
    "offset": -300,
    "abbreviation": "pet",
    "alternateName": "peru time",
    "country": "peru"
  },
  {
    "name": "manaus",
    "timezone": "america/manaus",
    "offset": -240,
    "abbreviation": "amt",
    "alternateName": "amazon time",
    "country": "brazil"
  },
  {
    "name": "campo grande",
    "timezone": "america/manaus",
    "offset": -240,
    "abbreviation": "amt",
    "alternateName": "amazon time",
    "country": "brazil"
  },
  {
    "name": "cuiaba",
    "timezone": "america/manaus",
    "offset": -240,
    "abbreviation": "amt",
    "alternateName": "amazon time",
    "country": "brazil"
  },
  {
    "name": "porto velho",
    "timezone": "america/manaus",
    "offset": -240,
    "abbreviation": "amt",
    "alternateName": "amazon time",
    "country": "brazil"
  },
  {
    "name": "basseterre",
    "timezone": "america/st_kitts",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "saint kitts and nevis"
  },
  {
    "name": "blanc-sablon",
    "timezone": "america/blanc-sablon",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "canada"
  },
  {
    "name": "brades",
    "timezone": "america/montserrat",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "montserrat"
  },
  {
    "name": "plymouth",
    "timezone": "america/montserrat",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "montserrat"
  },
  {
    "name": "bridgetown",
    "timezone": "america/barbados",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "barbados"
  },
  {
    "name": "castries",
    "timezone": "america/st_lucia",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "saint lucia"
  },
  {
    "name": "chaguanas",
    "timezone": "america/port_of_spain",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "trinidad and tobago"
  },
  {
    "name": "mon repos",
    "timezone": "america/port_of_spain",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "trinidad and tobago"
  },
  {
    "name": "san fernando",
    "timezone": "america/port_of_spain",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "trinidad and tobago"
  },
  {
    "name": "port of spain",
    "timezone": "america/port_of_spain",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "trinidad and tobago"
  },
  {
    "name": "fort-de-france",
    "timezone": "america/martinique",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "martinique"
  },
  {
    "name": "le lamentin",
    "timezone": "america/martinique",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "martinique"
  },
  {
    "name": "le robert",
    "timezone": "america/martinique",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "martinique"
  },
  {
    "name": "sainte-marie",
    "timezone": "america/martinique",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "martinique"
  },
  {
    "name": "gustavia",
    "timezone": "america/st_barthelemy",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "saint barthelemy"
  },
  {
    "name": "kingstown",
    "timezone": "america/st_vincent",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "saint vincent and the grenadines"
  },
  {
    "name": "kingstown park",
    "timezone": "america/st_vincent",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "saint vincent and the grenadines"
  },
  {
    "name": "kralendijk",
    "timezone": "america/kralendijk",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "bonaire, saint eustatius and saba "
  },
  {
    "name": "les abymes",
    "timezone": "america/guadeloupe",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "guadeloupe"
  },
  {
    "name": "baie-mahault",
    "timezone": "america/guadeloupe",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "guadeloupe"
  },
  {
    "name": "le gosier",
    "timezone": "america/guadeloupe",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "guadeloupe"
  },
  {
    "name": "petit-bourg",
    "timezone": "america/guadeloupe",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "guadeloupe"
  },
  {
    "name": "marigot",
    "timezone": "america/marigot",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "saint martin"
  },
  {
    "name": "oranjestad",
    "timezone": "america/aruba",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "aruba"
  },
  {
    "name": "tanki leendert",
    "timezone": "america/aruba",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "aruba"
  },
  {
    "name": "san nicolas",
    "timezone": "america/aruba",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "aruba"
  },
  {
    "name": "philipsburg",
    "timezone": "america/lower_princes",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "sint maarten"
  },
  {
    "name": "road town",
    "timezone": "america/tortola",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "british virgin islands"
  },
  {
    "name": "roseau",
    "timezone": "america/dominica",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "dominica"
  },
  {
    "name": "saint croix",
    "timezone": "america/st_thomas",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "u.s. virgin islands"
  },
  {
    "name": "charlotte amalie",
    "timezone": "america/st_thomas",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "u.s. virgin islands"
  },
  {
    "name": "saint george's",
    "timezone": "america/grenada",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "grenada"
  },
  {
    "name": "saint john's",
    "timezone": "america/antigua",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "antigua and barbuda"
  },
  {
    "name": "san juan",
    "timezone": "america/puerto_rico",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "puerto rico"
  },
  {
    "name": "bayamon",
    "timezone": "america/puerto_rico",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "puerto rico"
  },
  {
    "name": "carolina",
    "timezone": "america/puerto_rico",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "puerto rico"
  },
  {
    "name": "ponce",
    "timezone": "america/puerto_rico",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "puerto rico"
  },
  {
    "name": "santo domingo",
    "timezone": "america/santo_domingo",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "dominican republic"
  },
  {
    "name": "santiago de los caballeros",
    "timezone": "america/santo_domingo",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "dominican republic"
  },
  {
    "name": "santo domingo oeste",
    "timezone": "america/santo_domingo",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "dominican republic"
  },
  {
    "name": "santo domingo este",
    "timezone": "america/santo_domingo",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "dominican republic"
  },
  {
    "name": "the valley",
    "timezone": "america/anguilla",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "anguilla"
  },
  {
    "name": "willemstad",
    "timezone": "america/curacao",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "curacao"
  },
  {
    "name": "la paz",
    "timezone": "america/la_paz",
    "offset": -240,
    "abbreviation": "bot",
    "alternateName": "bolivia time",
    "country": "bolivia"
  },
  {
    "name": "santa cruz de la sierra",
    "timezone": "america/la_paz",
    "offset": -240,
    "abbreviation": "bot",
    "alternateName": "bolivia time",
    "country": "bolivia"
  },
  {
    "name": "cochabamba",
    "timezone": "america/la_paz",
    "offset": -240,
    "abbreviation": "bot",
    "alternateName": "bolivia time",
    "country": "bolivia"
  },
  {
    "name": "sucre",
    "timezone": "america/la_paz",
    "offset": -240,
    "abbreviation": "bot",
    "alternateName": "bolivia time",
    "country": "bolivia"
  },
  {
    "name": "santiago",
    "timezone": "america/santiago",
    "offset": -240,
    "abbreviation": "clt",
    "alternateName": "chile time",
    "country": "chile"
  },
  {
    "name": "puente alto",
    "timezone": "america/santiago",
    "offset": -240,
    "abbreviation": "clt",
    "alternateName": "chile time",
    "country": "chile"
  },
  {
    "name": "antofagasta",
    "timezone": "america/santiago",
    "offset": -240,
    "abbreviation": "clt",
    "alternateName": "chile time",
    "country": "chile"
  },
  {
    "name": "vina del mar",
    "timezone": "america/santiago",
    "offset": -240,
    "abbreviation": "clt",
    "alternateName": "chile time",
    "country": "chile"
  },
  {
    "name": "havana",
    "timezone": "america/havana",
    "offset": -300,
    "abbreviation": "cst",
    "alternateName": "cuba time",
    "country": "cuba"
  },
  {
    "name": "santiago de cuba",
    "timezone": "america/havana",
    "offset": -300,
    "abbreviation": "cst",
    "alternateName": "cuba time",
    "country": "cuba"
  },
  {
    "name": "camaguey",
    "timezone": "america/havana",
    "offset": -300,
    "abbreviation": "cst",
    "alternateName": "cuba time",
    "country": "cuba"
  },
  {
    "name": "holguin",
    "timezone": "america/havana",
    "offset": -300,
    "abbreviation": "cst",
    "alternateName": "cuba time",
    "country": "cuba"
  },
  {
    "name": "cockburn town",
    "timezone": "america/grand_turk",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "turks and caicos islands"
  },
  {
    "name": "nassau",
    "timezone": "america/nassau",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "bahamas"
  },
  {
    "name": "lucaya",
    "timezone": "america/nassau",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "bahamas"
  },
  {
    "name": "freeport",
    "timezone": "america/nassau",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "bahamas"
  },
  {
    "name": "new york city",
    "timezone": "america/new_york",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "united states"
  },
  {
    "name": "brooklyn",
    "timezone": "america/new_york",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "united states"
  },
  {
    "name": "queens",
    "timezone": "america/new_york",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "united states"
  },
  {
    "name": "philadelphia",
    "timezone": "america/new_york",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "united states"
  },
  {
    "name": "port-au-prince",
    "timezone": "america/port-au-prince",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "haiti"
  },
  {
    "name": "carrefour",
    "timezone": "america/port-au-prince",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "haiti"
  },
  {
    "name": "delmas 73",
    "timezone": "america/port-au-prince",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "haiti"
  },
  {
    "name": "petionville",
    "timezone": "america/port-au-prince",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "haiti"
  },
  {
    "name": "toronto",
    "timezone": "america/toronto",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "canada"
  },
  {
    "name": "montreal",
    "timezone": "america/toronto",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "canada"
  },
  {
    "name": "ottawa",
    "timezone": "america/toronto",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "canada"
  },
  {
    "name": "mississauga",
    "timezone": "america/toronto",
    "offset": -300,
    "abbreviation": "est",
    "alternateName": "eastern standard time",
    "country": "canada"
  },
  {
    "name": "georgetown",
    "timezone": "america/guyana",
    "offset": -240,
    "abbreviation": "gyt",
    "alternateName": "guyana time",
    "country": "guyana"
  },
  {
    "name": "linden",
    "timezone": "america/guyana",
    "offset": -240,
    "abbreviation": "gyt",
    "alternateName": "guyana time",
    "country": "guyana"
  },
  {
    "name": "new amsterdam",
    "timezone": "america/guyana",
    "offset": -240,
    "abbreviation": "gyt",
    "alternateName": "guyana time",
    "country": "guyana"
  },
  {
    "name": "asuncion",
    "timezone": "america/asuncion",
    "offset": -240,
    "abbreviation": "pyt",
    "alternateName": "paraguay time",
    "country": "paraguay"
  },
  {
    "name": "ciudad del este",
    "timezone": "america/asuncion",
    "offset": -240,
    "abbreviation": "pyt",
    "alternateName": "paraguay time",
    "country": "paraguay"
  },
  {
    "name": "san lorenzo",
    "timezone": "america/asuncion",
    "offset": -240,
    "abbreviation": "pyt",
    "alternateName": "paraguay time",
    "country": "paraguay"
  },
  {
    "name": "capiata",
    "timezone": "america/asuncion",
    "offset": -240,
    "abbreviation": "pyt",
    "alternateName": "paraguay time",
    "country": "paraguay"
  },
  {
    "name": "caracas",
    "timezone": "america/caracas",
    "offset": -240,
    "abbreviation": "vet",
    "alternateName": "venezuela time",
    "country": "venezuela"
  },
  {
    "name": "maracaibo",
    "timezone": "america/caracas",
    "offset": -240,
    "abbreviation": "vet",
    "alternateName": "venezuela time",
    "country": "venezuela"
  },
  {
    "name": "maracay",
    "timezone": "america/caracas",
    "offset": -240,
    "abbreviation": "vet",
    "alternateName": "venezuela time",
    "country": "venezuela"
  },
  {
    "name": "valencia",
    "timezone": "america/caracas",
    "offset": -240,
    "abbreviation": "vet",
    "alternateName": "venezuela time",
    "country": "venezuela"
  },
  {
    "name": "buenos aires",
    "timezone": "america/argentina/buenos_aires",
    "offset": -180,
    "abbreviation": "art",
    "alternateName": "argentina time",
    "country": "argentina"
  },
  {
    "name": "cordoba",
    "timezone": "america/argentina/buenos_aires",
    "offset": -180,
    "abbreviation": "art",
    "alternateName": "argentina time",
    "country": "argentina"
  },
  {
    "name": "rosario",
    "timezone": "america/argentina/buenos_aires",
    "offset": -180,
    "abbreviation": "art",
    "alternateName": "argentina time",
    "country": "argentina"
  },
  {
    "name": "mendoza",
    "timezone": "america/argentina/buenos_aires",
    "offset": -180,
    "abbreviation": "art",
    "alternateName": "argentina time",
    "country": "argentina"
  },
  {
    "name": "halifax",
    "timezone": "america/halifax",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "canada"
  },
  {
    "name": "moncton",
    "timezone": "america/halifax",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "canada"
  },
  {
    "name": "sydney",
    "timezone": "america/halifax",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "canada"
  },
  {
    "name": "dartmouth",
    "timezone": "america/halifax",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "canada"
  },
  {
    "name": "hamilton",
    "timezone": "atlantic/bermuda",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "bermuda"
  },
  {
    "name": "thule",
    "timezone": "america/thule",
    "offset": -240,
    "abbreviation": "ast",
    "alternateName": "atlantic time",
    "country": "greenland"
  },
  {
    "name": "sao paulo",
    "timezone": "america/sao_paulo",
    "offset": -180,
    "abbreviation": "brt",
    "alternateName": "brasilia time",
    "country": "brazil"
  },
  {
    "name": "rio de janeiro",
    "timezone": "america/sao_paulo",
    "offset": -180,
    "abbreviation": "brt",
    "alternateName": "brasilia time",
    "country": "brazil"
  },
  {
    "name": "salvador",
    "timezone": "america/sao_paulo",
    "offset": -180,
    "abbreviation": "brt",
    "alternateName": "brasilia time",
    "country": "brazil"
  },
  {
    "name": "fortaleza",
    "timezone": "america/sao_paulo",
    "offset": -180,
    "abbreviation": "brt",
    "alternateName": "brasilia time",
    "country": "brazil"
  },
  {
    "name": "palmer",
    "timezone": "antarctica/palmer",
    "offset": -180,
    "abbreviation": "clt",
    "alternateName": "chile time",
    "country": "antarctica"
  },
  {
    "name": "rothera",
    "timezone": "antarctica/palmer",
    "offset": -180,
    "abbreviation": "clt",
    "alternateName": "chile time",
    "country": "antarctica"
  },
  {
    "name": "punta arenas",
    "timezone": "america/punta_arenas",
    "offset": -180,
    "abbreviation": "clt",
    "alternateName": "chile time",
    "country": "chile"
  },
  {
    "name": "puerto natales",
    "timezone": "america/punta_arenas",
    "offset": -180,
    "abbreviation": "clt",
    "alternateName": "chile time",
    "country": "chile"
  },
  {
    "name": "stanley",
    "timezone": "atlantic/stanley",
    "offset": -180,
    "abbreviation": "fkst",
    "alternateName": "falkland islands time",
    "country": "falkland islands"
  },
  {
    "name": "cayenne",
    "timezone": "america/cayenne",
    "offset": -180,
    "abbreviation": "gft",
    "alternateName": "french guiana time",
    "country": "french guiana"
  },
  {
    "name": "matoury",
    "timezone": "america/cayenne",
    "offset": -180,
    "abbreviation": "gft",
    "alternateName": "french guiana time",
    "country": "french guiana"
  },
  {
    "name": "saint-laurent-du-maroni",
    "timezone": "america/cayenne",
    "offset": -180,
    "abbreviation": "gft",
    "alternateName": "french guiana time",
    "country": "french guiana"
  },
  {
    "name": "kourou",
    "timezone": "america/cayenne",
    "offset": -180,
    "abbreviation": "gft",
    "alternateName": "french guiana time",
    "country": "french guiana"
  },
  {
    "name": "paramaribo",
    "timezone": "america/paramaribo",
    "offset": -180,
    "abbreviation": "srt",
    "alternateName": "suriname time",
    "country": "suriname"
  },
  {
    "name": "lelydorp",
    "timezone": "america/paramaribo",
    "offset": -180,
    "abbreviation": "srt",
    "alternateName": "suriname time",
    "country": "suriname"
  },
  {
    "name": "montevideo",
    "timezone": "america/montevideo",
    "offset": -180,
    "abbreviation": "uyt",
    "alternateName": "uruguay time",
    "country": "uruguay"
  },
  {
    "name": "salto",
    "timezone": "america/montevideo",
    "offset": -180,
    "abbreviation": "uyt",
    "alternateName": "uruguay time",
    "country": "uruguay"
  },
  {
    "name": "paysandu",
    "timezone": "america/montevideo",
    "offset": -180,
    "abbreviation": "uyt",
    "alternateName": "uruguay time",
    "country": "uruguay"
  },
  {
    "name": "las piedras",
    "timezone": "america/montevideo",
    "offset": -180,
    "abbreviation": "uyt",
    "alternateName": "uruguay time",
    "country": "uruguay"
  },
  {
    "name": "st. john's",
    "timezone": "america/st_johns",
    "offset": -210,
    "abbreviation": "nst",
    "alternateName": "newfoundland time",
    "country": "canada"
  },
  {
    "name": "mount pearl",
    "timezone": "america/st_johns",
    "offset": -210,
    "abbreviation": "nst",
    "alternateName": "newfoundland time",
    "country": "canada"
  },
  {
    "name": "corner brook",
    "timezone": "america/st_johns",
    "offset": -210,
    "abbreviation": "nst",
    "alternateName": "newfoundland time",
    "country": "canada"
  },
  {
    "name": "conception bay south",
    "timezone": "america/st_johns",
    "offset": -210,
    "abbreviation": "nst",
    "alternateName": "newfoundland time",
    "country": "canada"
  },
  {
    "name": "noronha",
    "timezone": "america/noronha",
    "offset": -120,
    "abbreviation": "fnt",
    "alternateName": "fernando de noronha time",
    "country": "brazil"
  },
  {
    "name": "grytviken",
    "timezone": "atlantic/south_georgia",
    "offset": -120,
    "abbreviation": "gst",
    "alternateName": "south georgia time",
    "country": "south georgia and the south sandwich islands"
  },
  {
    "name": "saint-pierre",
    "timezone": "america/miquelon",
    "offset": -180,
    "abbreviation": "pm",
    "alternateName": "st. pierre & miquelon time",
    "country": "saint pierre and miquelon"
  },
  {
    "name": "nuuk",
    "timezone": "america/nuuk",
    "offset": -180,
    "abbreviation": "wgt",
    "alternateName": "west greenland time",
    "country": "greenland"
  },
  {
    "name": "praia",
    "timezone": "atlantic/cape_verde",
    "offset": -60,
    "abbreviation": "cvt",
    "alternateName": "cape verde time",
    "country": "cabo verde"
  },
  {
    "name": "mindelo",
    "timezone": "atlantic/cape_verde",
    "offset": -60,
    "abbreviation": "cvt",
    "alternateName": "cape verde time",
    "country": "cabo verde"
  },
  {
    "name": "santa maria",
    "timezone": "atlantic/cape_verde",
    "offset": -60,
    "abbreviation": "cvt",
    "alternateName": "cape verde time",
    "country": "cabo verde"
  },
  {
    "name": "cova figueira",
    "timezone": "atlantic/cape_verde",
    "offset": -60,
    "abbreviation": "cvt",
    "alternateName": "cape verde time",
    "country": "cabo verde"
  },
  {
    "name": "ponta delgada",
    "timezone": "atlantic/azores",
    "offset": -60,
    "abbreviation": "azot",
    "alternateName": "azores time",
    "country": "portugal"
  },
  {
    "name": "scoresbysund",
    "timezone": "america/scoresbysund",
    "offset": -60,
    "abbreviation": "egt",
    "alternateName": "east greenland time",
    "country": "greenland"
  },
  {
    "name": "abidjan",
    "timezone": "africa/abidjan",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "ivory coast"
  },
  {
    "name": "abobo",
    "timezone": "africa/abidjan",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "ivory coast"
  },
  {
    "name": "bouake",
    "timezone": "africa/abidjan",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "ivory coast"
  },
  {
    "name": "daloa",
    "timezone": "africa/abidjan",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "ivory coast"
  },
  {
    "name": "accra",
    "timezone": "africa/accra",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "ghana"
  },
  {
    "name": "kumasi",
    "timezone": "africa/accra",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "ghana"
  },
  {
    "name": "tamale",
    "timezone": "africa/accra",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "ghana"
  },
  {
    "name": "takoradi",
    "timezone": "africa/accra",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "ghana"
  },
  {
    "name": "bamako",
    "timezone": "africa/bamako",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "mali"
  },
  {
    "name": "segou",
    "timezone": "africa/bamako",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "mali"
  },
  {
    "name": "sikasso",
    "timezone": "africa/bamako",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "mali"
  },
  {
    "name": "mopti",
    "timezone": "africa/bamako",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "mali"
  },
  {
    "name": "bissau",
    "timezone": "africa/bissau",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "guinea-bissau"
  },
  {
    "name": "bafata",
    "timezone": "africa/bissau",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "guinea-bissau"
  },
  {
    "name": "camayenne",
    "timezone": "africa/conakry",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "guinea"
  },
  {
    "name": "conakry",
    "timezone": "africa/conakry",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "guinea"
  },
  {
    "name": "nzerekore",
    "timezone": "africa/conakry",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "guinea"
  },
  {
    "name": "kindia",
    "timezone": "africa/conakry",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "guinea"
  },
  {
    "name": "dakar",
    "timezone": "africa/dakar",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "senegal"
  },
  {
    "name": "pikine",
    "timezone": "africa/dakar",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "senegal"
  },
  {
    "name": "touba",
    "timezone": "africa/dakar",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "senegal"
  },
  {
    "name": "thies",
    "timezone": "africa/dakar",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "senegal"
  },
  {
    "name": "danmarkshavn",
    "timezone": "america/danmarkshavn",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "greenland"
  },
  {
    "name": "freetown",
    "timezone": "africa/freetown",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "sierra leone"
  },
  {
    "name": "bo",
    "timezone": "africa/freetown",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "sierra leone"
  },
  {
    "name": "kenema",
    "timezone": "africa/freetown",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "sierra leone"
  },
  {
    "name": "koidu",
    "timezone": "africa/freetown",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "sierra leone"
  },
  {
    "name": "jamestown",
    "timezone": "atlantic/st_helena",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "saint helena"
  },
  {
    "name": "lome",
    "timezone": "africa/lome",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "togo"
  },
  {
    "name": "sokode",
    "timezone": "africa/lome",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "togo"
  },
  {
    "name": "kara",
    "timezone": "africa/lome",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "togo"
  },
  {
    "name": "atakpame",
    "timezone": "africa/lome",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "togo"
  },
  {
    "name": "monrovia",
    "timezone": "africa/monrovia",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "liberia"
  },
  {
    "name": "gbarnga",
    "timezone": "africa/monrovia",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "liberia"
  },
  {
    "name": "kakata",
    "timezone": "africa/monrovia",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "liberia"
  },
  {
    "name": "bensonville",
    "timezone": "africa/monrovia",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "liberia"
  },
  {
    "name": "nouakchott",
    "timezone": "africa/nouakchott",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "mauritania"
  },
  {
    "name": "nouadhibou",
    "timezone": "africa/nouakchott",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "mauritania"
  },
  {
    "name": "nema",
    "timezone": "africa/nouakchott",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "mauritania"
  },
  {
    "name": "kaedi",
    "timezone": "africa/nouakchott",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "mauritania"
  },
  {
    "name": "ouagadougou",
    "timezone": "africa/ouagadougou",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "burkina faso"
  },
  {
    "name": "bobo-dioulasso",
    "timezone": "africa/ouagadougou",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "burkina faso"
  },
  {
    "name": "koudougou",
    "timezone": "africa/ouagadougou",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "burkina faso"
  },
  {
    "name": "ouahigouya",
    "timezone": "africa/ouagadougou",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "burkina faso"
  },
  {
    "name": "reykjavik",
    "timezone": "atlantic/reykjavik",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "iceland"
  },
  {
    "name": "kopavogur",
    "timezone": "atlantic/reykjavik",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "iceland"
  },
  {
    "name": "hafnarfjordur",
    "timezone": "atlantic/reykjavik",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "iceland"
  },
  {
    "name": "akureyri",
    "timezone": "atlantic/reykjavik",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "iceland"
  },
  {
    "name": "sao tome",
    "timezone": "africa/sao_tome",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "sao tome and principe"
  },
  {
    "name": "serekunda",
    "timezone": "africa/banjul",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "gambia"
  },
  {
    "name": "brikama",
    "timezone": "africa/banjul",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "gambia"
  },
  {
    "name": "bakau",
    "timezone": "africa/banjul",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "gambia"
  },
  {
    "name": "banjul",
    "timezone": "africa/banjul",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "gambia"
  },
  {
    "name": "algiers",
    "timezone": "africa/algiers",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "algeria"
  },
  {
    "name": "boumerdas",
    "timezone": "africa/algiers",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "algeria"
  },
  {
    "name": "oran",
    "timezone": "africa/algiers",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "algeria"
  },
  {
    "name": "tebessa",
    "timezone": "africa/algiers",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "algeria"
  },
  {
    "name": "tunis",
    "timezone": "africa/tunis",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "tunisia"
  },
  {
    "name": "sfax",
    "timezone": "africa/tunis",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "tunisia"
  },
  {
    "name": "sousse",
    "timezone": "africa/tunis",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "tunisia"
  },
  {
    "name": "kairouan",
    "timezone": "africa/tunis",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "tunisia"
  },
  {
    "name": "douglas",
    "timezone": "europe/isle_of_man",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "isle of man"
  },
  {
    "name": "dublin",
    "timezone": "europe/dublin",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "ireland"
  },
  {
    "name": "cork",
    "timezone": "europe/dublin",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "ireland"
  },
  {
    "name": "luimneach",
    "timezone": "europe/dublin",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "ireland"
  },
  {
    "name": "gaillimh",
    "timezone": "europe/dublin",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "ireland"
  },
  {
    "name": "london",
    "timezone": "europe/london",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "united kingdom"
  },
  {
    "name": "birmingham",
    "timezone": "europe/london",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "united kingdom"
  },
  {
    "name": "liverpool",
    "timezone": "europe/london",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "united kingdom"
  },
  {
    "name": "sheffield",
    "timezone": "europe/london",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "united kingdom"
  },
  {
    "name": "saint helier",
    "timezone": "europe/jersey",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "jersey"
  },
  {
    "name": "saint peter port",
    "timezone": "europe/guernsey",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "guernsey"
  },
  {
    "name": "bangui",
    "timezone": "africa/bangui",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "central african republic"
  },
  {
    "name": "bimbo",
    "timezone": "africa/bangui",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "central african republic"
  },
  {
    "name": "mbaiki",
    "timezone": "africa/bangui",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "central african republic"
  },
  {
    "name": "berberati",
    "timezone": "africa/bangui",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "central african republic"
  },
  {
    "name": "bata",
    "timezone": "africa/malabo",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "equatorial guinea"
  },
  {
    "name": "malabo",
    "timezone": "africa/malabo",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "equatorial guinea"
  },
  {
    "name": "ebebiyin",
    "timezone": "africa/malabo",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "equatorial guinea"
  },
  {
    "name": "brazzaville",
    "timezone": "africa/brazzaville",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "republic of the congo"
  },
  {
    "name": "pointe-noire",
    "timezone": "africa/brazzaville",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "republic of the congo"
  },
  {
    "name": "dolisie",
    "timezone": "africa/brazzaville",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "republic of the congo"
  },
  {
    "name": "kayes",
    "timezone": "africa/brazzaville",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "republic of the congo"
  },
  {
    "name": "cotonou",
    "timezone": "africa/porto-novo",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "benin"
  },
  {
    "name": "abomey-calavi",
    "timezone": "africa/porto-novo",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "benin"
  },
  {
    "name": "djougou",
    "timezone": "africa/porto-novo",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "benin"
  },
  {
    "name": "porto-novo",
    "timezone": "africa/porto-novo",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "benin"
  },
  {
    "name": "douala",
    "timezone": "africa/douala",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "cameroon"
  },
  {
    "name": "yaounde",
    "timezone": "africa/douala",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "cameroon"
  },
  {
    "name": "garoua",
    "timezone": "africa/douala",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "cameroon"
  },
  {
    "name": "kousseri",
    "timezone": "africa/douala",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "cameroon"
  },
  {
    "name": "kinshasa",
    "timezone": "africa/kinshasa",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "democratic republic of the congo"
  },
  {
    "name": "masina",
    "timezone": "africa/kinshasa",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "democratic republic of the congo"
  },
  {
    "name": "kikwit",
    "timezone": "africa/kinshasa",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "democratic republic of the congo"
  },
  {
    "name": "mbandaka",
    "timezone": "africa/kinshasa",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "democratic republic of the congo"
  },
  {
    "name": "lagos",
    "timezone": "africa/lagos",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "nigeria"
  },
  {
    "name": "kano",
    "timezone": "africa/lagos",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "nigeria"
  },
  {
    "name": "ibadan",
    "timezone": "africa/lagos",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "nigeria"
  },
  {
    "name": "port harcourt",
    "timezone": "africa/lagos",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "nigeria"
  },
  {
    "name": "libreville",
    "timezone": "africa/libreville",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "gabon"
  },
  {
    "name": "port-gentil",
    "timezone": "africa/libreville",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "gabon"
  },
  {
    "name": "franceville",
    "timezone": "africa/libreville",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "gabon"
  },
  {
    "name": "oyem",
    "timezone": "africa/libreville",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "gabon"
  },
  {
    "name": "luanda",
    "timezone": "africa/luanda",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "angola"
  },
  {
    "name": "n'dalatando",
    "timezone": "africa/luanda",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "angola"
  },
  {
    "name": "huambo",
    "timezone": "africa/luanda",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "angola"
  },
  {
    "name": "lobito",
    "timezone": "africa/luanda",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "angola"
  },
  {
    "name": "n'djamena",
    "timezone": "africa/ndjamena",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "chad"
  },
  {
    "name": "moundou",
    "timezone": "africa/ndjamena",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "chad"
  },
  {
    "name": "sarh",
    "timezone": "africa/ndjamena",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "chad"
  },
  {
    "name": "abeche",
    "timezone": "africa/ndjamena",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "chad"
  },
  {
    "name": "niamey",
    "timezone": "africa/niamey",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "niger"
  },
  {
    "name": "zinder",
    "timezone": "africa/niamey",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "niger"
  },
  {
    "name": "maradi",
    "timezone": "africa/niamey",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "niger"
  },
  {
    "name": "agadez",
    "timezone": "africa/niamey",
    "offset": 60,
    "abbreviation": "wat",
    "alternateName": "west africa time",
    "country": "niger"
  },
  {
    "name": "casablanca",
    "timezone": "africa/casablanca",
    "offset": 0,
    "abbreviation": "wet",
    "alternateName": "western european time",
    "country": "morocco"
  },
  {
    "name": "rabat",
    "timezone": "africa/casablanca",
    "offset": 0,
    "abbreviation": "wet",
    "alternateName": "western european time",
    "country": "morocco"
  },
  {
    "name": "fes",
    "timezone": "africa/casablanca",
    "offset": 0,
    "abbreviation": "wet",
    "alternateName": "western european time",
    "country": "morocco"
  },
  {
    "name": "sale",
    "timezone": "africa/casablanca",
    "offset": 0,
    "abbreviation": "wet",
    "alternateName": "western european time",
    "country": "morocco"
  },
  {
    "name": "laayoune",
    "timezone": "africa/el_aaiun",
    "offset": 0,
    "abbreviation": "wet",
    "alternateName": "western european time",
    "country": "western sahara"
  },
  {
    "name": "dakhla",
    "timezone": "africa/el_aaiun",
    "offset": 0,
    "abbreviation": "wet",
    "alternateName": "western european time",
    "country": "western sahara"
  },
  {
    "name": "las palmas de gran canaria",
    "timezone": "atlantic/canary",
    "offset": 0,
    "abbreviation": "wet",
    "alternateName": "western european time",
    "country": "spain"
  },
  {
    "name": "santa cruz de tenerife",
    "timezone": "atlantic/canary",
    "offset": 0,
    "abbreviation": "wet",
    "alternateName": "western european time",
    "country": "spain"
  },
  {
    "name": "la laguna",
    "timezone": "atlantic/canary",
    "offset": 0,
    "abbreviation": "wet",
    "alternateName": "western european time",
    "country": "spain"
  },
  {
    "name": "telde",
    "timezone": "atlantic/canary",
    "offset": 0,
    "abbreviation": "wet",
    "alternateName": "western european time",
    "country": "spain"
  },
  {
    "name": "lisbon",
    "timezone": "europe/lisbon",
    "offset": 0,
    "abbreviation": "wet",
    "alternateName": "western european time",
    "country": "portugal"
  },
  {
    "name": "porto",
    "timezone": "europe/lisbon",
    "offset": 0,
    "abbreviation": "wet",
    "alternateName": "western european time",
    "country": "portugal"
  },
  {
    "name": "amadora",
    "timezone": "europe/lisbon",
    "offset": 0,
    "abbreviation": "wet",
    "alternateName": "western european time",
    "country": "portugal"
  },
  {
    "name": "braga",
    "timezone": "europe/lisbon",
    "offset": 0,
    "abbreviation": "wet",
    "alternateName": "western european time",
    "country": "portugal"
  },
  {
    "name": "torshavn",
    "timezone": "atlantic/faroe",
    "offset": 0,
    "abbreviation": "wet",
    "alternateName": "western european time",
    "country": "faroe islands"
  },
  {
    "name": "bujumbura",
    "timezone": "africa/bujumbura",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "burundi"
  },
  {
    "name": "muyinga",
    "timezone": "africa/bujumbura",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "burundi"
  },
  {
    "name": "gitega",
    "timezone": "africa/bujumbura",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "burundi"
  },
  {
    "name": "ruyigi",
    "timezone": "africa/bujumbura",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "burundi"
  },
  {
    "name": "gaborone",
    "timezone": "africa/gaborone",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "botswana"
  },
  {
    "name": "francistown",
    "timezone": "africa/gaborone",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "botswana"
  },
  {
    "name": "molepolole",
    "timezone": "africa/gaborone",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "botswana"
  },
  {
    "name": "selebi-phikwe",
    "timezone": "africa/gaborone",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "botswana"
  },
  {
    "name": "harare",
    "timezone": "africa/harare",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "zimbabwe"
  },
  {
    "name": "bulawayo",
    "timezone": "africa/harare",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "zimbabwe"
  },
  {
    "name": "chitungwiza",
    "timezone": "africa/harare",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "zimbabwe"
  },
  {
    "name": "mutare",
    "timezone": "africa/harare",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "zimbabwe"
  },
  {
    "name": "juba",
    "timezone": "africa/juba",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "south sudan"
  },
  {
    "name": "winejok",
    "timezone": "africa/juba",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "south sudan"
  },
  {
    "name": "yei",
    "timezone": "africa/juba",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "south sudan"
  },
  {
    "name": "malakal",
    "timezone": "africa/juba",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "south sudan"
  },
  {
    "name": "khartoum",
    "timezone": "africa/khartoum",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "sudan"
  },
  {
    "name": "omdurman",
    "timezone": "africa/khartoum",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "sudan"
  },
  {
    "name": "nyala",
    "timezone": "africa/khartoum",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "sudan"
  },
  {
    "name": "port sudan",
    "timezone": "africa/khartoum",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "sudan"
  },
  {
    "name": "kigali",
    "timezone": "africa/kigali",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "rwanda"
  },
  {
    "name": "gisenyi",
    "timezone": "africa/kigali",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "rwanda"
  },
  {
    "name": "butare",
    "timezone": "africa/kigali",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "rwanda"
  },
  {
    "name": "gitarama",
    "timezone": "africa/kigali",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "rwanda"
  },
  {
    "name": "lilongwe",
    "timezone": "africa/blantyre",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "malawi"
  },
  {
    "name": "blantyre",
    "timezone": "africa/blantyre",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "malawi"
  },
  {
    "name": "mzuzu",
    "timezone": "africa/blantyre",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "malawi"
  },
  {
    "name": "zomba",
    "timezone": "africa/blantyre",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "malawi"
  },
  {
    "name": "lubumbashi",
    "timezone": "africa/lubumbashi",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "democratic republic of the congo"
  },
  {
    "name": "mbuji-mayi",
    "timezone": "africa/lubumbashi",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "democratic republic of the congo"
  },
  {
    "name": "kisangani",
    "timezone": "africa/lubumbashi",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "democratic republic of the congo"
  },
  {
    "name": "kananga",
    "timezone": "africa/lubumbashi",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "democratic republic of the congo"
  },
  {
    "name": "lusaka",
    "timezone": "africa/lusaka",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "zambia"
  },
  {
    "name": "kitwe",
    "timezone": "africa/lusaka",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "zambia"
  },
  {
    "name": "ndola",
    "timezone": "africa/lusaka",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "zambia"
  },
  {
    "name": "kabwe",
    "timezone": "africa/lusaka",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "zambia"
  },
  {
    "name": "maputo",
    "timezone": "africa/maputo",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "mozambique"
  },
  {
    "name": "matola",
    "timezone": "africa/maputo",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "mozambique"
  },
  {
    "name": "beira",
    "timezone": "africa/maputo",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "mozambique"
  },
  {
    "name": "nampula",
    "timezone": "africa/maputo",
    "offset": 120,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "mozambique"
  },
  {
    "name": "windhoek",
    "timezone": "africa/windhoek",
    "offset": 60,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "namibia"
  },
  {
    "name": "rundu",
    "timezone": "africa/windhoek",
    "offset": 60,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "namibia"
  },
  {
    "name": "walvis bay",
    "timezone": "africa/windhoek",
    "offset": 60,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "namibia"
  },
  {
    "name": "oshakati",
    "timezone": "africa/windhoek",
    "offset": 60,
    "abbreviation": "cat",
    "alternateName": "central africa time",
    "country": "namibia"
  },
  {
    "name": "amsterdam",
    "timezone": "europe/amsterdam",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "netherlands"
  },
  {
    "name": "rotterdam",
    "timezone": "europe/amsterdam",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "netherlands"
  },
  {
    "name": "the hague",
    "timezone": "europe/amsterdam",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "netherlands"
  },
  {
    "name": "utrecht",
    "timezone": "europe/amsterdam",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "netherlands"
  },
  {
    "name": "andorra la vella",
    "timezone": "europe/andorra",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "andorra"
  },
  {
    "name": "les escaldes",
    "timezone": "europe/andorra",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "andorra"
  },
  {
    "name": "belgrade",
    "timezone": "europe/belgrade",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "serbia"
  },
  {
    "name": "nis",
    "timezone": "europe/belgrade",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "serbia"
  },
  {
    "name": "novi sad",
    "timezone": "europe/belgrade",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "serbia"
  },
  {
    "name": "zemun",
    "timezone": "europe/belgrade",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "serbia"
  },
  {
    "name": "berlin",
    "timezone": "europe/berlin",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "germany"
  },
  {
    "name": "hamburg",
    "timezone": "europe/berlin",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "germany"
  },
  {
    "name": "munich",
    "timezone": "europe/berlin",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "germany"
  },
  {
    "name": "koln",
    "timezone": "europe/berlin",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "germany"
  },
  {
    "name": "birkirkara",
    "timezone": "europe/malta",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "malta"
  },
  {
    "name": "qormi",
    "timezone": "europe/malta",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "malta"
  },
  {
    "name": "mosta",
    "timezone": "europe/malta",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "malta"
  },
  {
    "name": "zabbar",
    "timezone": "europe/malta",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "malta"
  },
  {
    "name": "bratislava",
    "timezone": "europe/bratislava",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "slovakia"
  },
  {
    "name": "kosice",
    "timezone": "europe/bratislava",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "slovakia"
  },
  {
    "name": "presov",
    "timezone": "europe/bratislava",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "slovakia"
  },
  {
    "name": "nitra",
    "timezone": "europe/bratislava",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "slovakia"
  },
  {
    "name": "brussels",
    "timezone": "europe/brussels",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "belgium"
  },
  {
    "name": "antwerpen",
    "timezone": "europe/brussels",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "belgium"
  },
  {
    "name": "gent",
    "timezone": "europe/brussels",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "belgium"
  },
  {
    "name": "charleroi",
    "timezone": "europe/brussels",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "belgium"
  },
  {
    "name": "budapest",
    "timezone": "europe/budapest",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "hungary"
  },
  {
    "name": "debrecen",
    "timezone": "europe/budapest",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "hungary"
  },
  {
    "name": "miskolc",
    "timezone": "europe/budapest",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "hungary"
  },
  {
    "name": "szeged",
    "timezone": "europe/budapest",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "hungary"
  },
  {
    "name": "copenhagen",
    "timezone": "europe/copenhagen",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "denmark"
  },
  {
    "name": "arhus",
    "timezone": "europe/copenhagen",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "denmark"
  },
  {
    "name": "odense",
    "timezone": "europe/copenhagen",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "denmark"
  },
  {
    "name": "aalborg",
    "timezone": "europe/copenhagen",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "denmark"
  },
  {
    "name": "gibraltar",
    "timezone": "europe/gibraltar",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "gibraltar"
  },
  {
    "name": "ljubljana",
    "timezone": "europe/ljubljana",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "slovenia"
  },
  {
    "name": "maribor",
    "timezone": "europe/ljubljana",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "slovenia"
  },
  {
    "name": "celje",
    "timezone": "europe/ljubljana",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "slovenia"
  },
  {
    "name": "kranj",
    "timezone": "europe/ljubljana",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "slovenia"
  },
  {
    "name": "longyearbyen",
    "timezone": "arctic/longyearbyen",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "svalbard and jan mayen"
  },
  {
    "name": "luxembourg",
    "timezone": "europe/luxembourg",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "luxembourg"
  },
  {
    "name": "esch-sur-alzette",
    "timezone": "europe/luxembourg",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "luxembourg"
  },
  {
    "name": "dudelange",
    "timezone": "europe/luxembourg",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "luxembourg"
  },
  {
    "name": "madrid",
    "timezone": "europe/madrid",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "spain"
  },
  {
    "name": "barcelona",
    "timezone": "europe/madrid",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "spain"
  },
  {
    "name": "valencia",
    "timezone": "europe/madrid",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "spain"
  },
  {
    "name": "sevilla",
    "timezone": "europe/madrid",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "spain"
  },
  {
    "name": "monaco",
    "timezone": "europe/monaco",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "monaco"
  },
  {
    "name": "monte-carlo",
    "timezone": "europe/monaco",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "monaco"
  },
  {
    "name": "oslo",
    "timezone": "europe/oslo",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "norway"
  },
  {
    "name": "bergen",
    "timezone": "europe/oslo",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "norway"
  },
  {
    "name": "trondheim",
    "timezone": "europe/oslo",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "norway"
  },
  {
    "name": "stavanger",
    "timezone": "europe/oslo",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "norway"
  },
  {
    "name": "paris",
    "timezone": "europe/paris",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "france"
  },
  {
    "name": "marseille",
    "timezone": "europe/paris",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "france"
  },
  {
    "name": "lyon",
    "timezone": "europe/paris",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "france"
  },
  {
    "name": "toulouse",
    "timezone": "europe/paris",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "france"
  },
  {
    "name": "podgorica",
    "timezone": "europe/podgorica",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "montenegro"
  },
  {
    "name": "niksic",
    "timezone": "europe/podgorica",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "montenegro"
  },
  {
    "name": "herceg novi",
    "timezone": "europe/podgorica",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "montenegro"
  },
  {
    "name": "pljevlja",
    "timezone": "europe/podgorica",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "montenegro"
  },
  {
    "name": "prague",
    "timezone": "europe/prague",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "czechia"
  },
  {
    "name": "brno",
    "timezone": "europe/prague",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "czechia"
  },
  {
    "name": "ostrava",
    "timezone": "europe/prague",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "czechia"
  },
  {
    "name": "pilsen",
    "timezone": "europe/prague",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "czechia"
  },
  {
    "name": "rome",
    "timezone": "europe/rome",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "italy"
  },
  {
    "name": "milan",
    "timezone": "europe/rome",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "italy"
  },
  {
    "name": "naples",
    "timezone": "europe/rome",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "italy"
  },
  {
    "name": "turin",
    "timezone": "europe/rome",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "italy"
  },
  {
    "name": "san marino",
    "timezone": "europe/san_marino",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "san marino"
  },
  {
    "name": "sarajevo",
    "timezone": "europe/sarajevo",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "bosnia and herzegovina"
  },
  {
    "name": "banja luka",
    "timezone": "europe/sarajevo",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "bosnia and herzegovina"
  },
  {
    "name": "zenica",
    "timezone": "europe/sarajevo",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "bosnia and herzegovina"
  },
  {
    "name": "tuzla",
    "timezone": "europe/sarajevo",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "bosnia and herzegovina"
  },
  {
    "name": "skopje",
    "timezone": "europe/skopje",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "north macedonia"
  },
  {
    "name": "bitola",
    "timezone": "europe/skopje",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "north macedonia"
  },
  {
    "name": "kumanovo",
    "timezone": "europe/skopje",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "north macedonia"
  },
  {
    "name": "prilep",
    "timezone": "europe/skopje",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "north macedonia"
  },
  {
    "name": "stockholm",
    "timezone": "europe/stockholm",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "sweden"
  },
  {
    "name": "goteborg",
    "timezone": "europe/stockholm",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "sweden"
  },
  {
    "name": "malmo",
    "timezone": "europe/stockholm",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "sweden"
  },
  {
    "name": "uppsala",
    "timezone": "europe/stockholm",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "sweden"
  },
  {
    "name": "tirana",
    "timezone": "europe/tirane",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "albania"
  },
  {
    "name": "durres",
    "timezone": "europe/tirane",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "albania"
  },
  {
    "name": "elbasan",
    "timezone": "europe/tirane",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "albania"
  },
  {
    "name": "vlore",
    "timezone": "europe/tirane",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "albania"
  },
  {
    "name": "vaduz",
    "timezone": "europe/vaduz",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "liechtenstein"
  },
  {
    "name": "vatican city",
    "timezone": "europe/vatican",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "vatican"
  },
  {
    "name": "vienna",
    "timezone": "europe/vienna",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "austria"
  },
  {
    "name": "graz",
    "timezone": "europe/vienna",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "austria"
  },
  {
    "name": "linz",
    "timezone": "europe/vienna",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "austria"
  },
  {
    "name": "favoriten",
    "timezone": "europe/vienna",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "austria"
  },
  {
    "name": "warsaw",
    "timezone": "europe/warsaw",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "poland"
  },
  {
    "name": "lodz",
    "timezone": "europe/warsaw",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "poland"
  },
  {
    "name": "krakow",
    "timezone": "europe/warsaw",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "poland"
  },
  {
    "name": "wroclaw",
    "timezone": "europe/warsaw",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "poland"
  },
  {
    "name": "zagreb",
    "timezone": "europe/zagreb",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "croatia"
  },
  {
    "name": "split",
    "timezone": "europe/zagreb",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "croatia"
  },
  {
    "name": "rijeka",
    "timezone": "europe/zagreb",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "croatia"
  },
  {
    "name": "osijek",
    "timezone": "europe/zagreb",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "croatia"
  },
  {
    "name": "zurich",
    "timezone": "europe/zurich",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "switzerland"
  },
  {
    "name": "geneve",
    "timezone": "europe/zurich",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "switzerland"
  },
  {
    "name": "basel",
    "timezone": "europe/zurich",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "switzerland"
  },
  {
    "name": "lausanne",
    "timezone": "europe/zurich",
    "offset": 60,
    "abbreviation": "cet",
    "alternateName": "central european time",
    "country": "switzerland"
  },
  {
    "name": "cairo",
    "timezone": "africa/cairo",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "egypt"
  },
  {
    "name": "alexandria",
    "timezone": "africa/cairo",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "egypt"
  },
  {
    "name": "giza",
    "timezone": "africa/cairo",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "egypt"
  },
  {
    "name": "shubra al khaymah",
    "timezone": "africa/cairo",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "egypt"
  },
  {
    "name": "kaliningrad",
    "timezone": "europe/kaliningrad",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "russia"
  },
  {
    "name": "chernyakhovsk",
    "timezone": "europe/kaliningrad",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "russia"
  },
  {
    "name": "sovetsk",
    "timezone": "europe/kaliningrad",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "russia"
  },
  {
    "name": "baltiysk",
    "timezone": "europe/kaliningrad",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "russia"
  },
  {
    "name": "tripoli",
    "timezone": "africa/tripoli",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "libya"
  },
  {
    "name": "benghazi",
    "timezone": "africa/tripoli",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "libya"
  },
  {
    "name": "misratah",
    "timezone": "africa/tripoli",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "libya"
  },
  {
    "name": "tarhuna",
    "timezone": "africa/tripoli",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "libya"
  },
  {
    "name": "troll",
    "timezone": "antarctica/troll",
    "offset": 0,
    "abbreviation": "gmt",
    "alternateName": "greenwich mean time",
    "country": "antarctica"
  },
  {
    "name": "cape town",
    "timezone": "africa/johannesburg",
    "offset": 120,
    "abbreviation": "sast",
    "alternateName": "south africa time",
    "country": "south africa"
  },
  {
    "name": "durban",
    "timezone": "africa/johannesburg",
    "offset": 120,
    "abbreviation": "sast",
    "alternateName": "south africa time",
    "country": "south africa"
  },
  {
    "name": "johannesburg",
    "timezone": "africa/johannesburg",
    "offset": 120,
    "abbreviation": "sast",
    "alternateName": "south africa time",
    "country": "south africa"
  },
  {
    "name": "soweto",
    "timezone": "africa/johannesburg",
    "offset": 120,
    "abbreviation": "sast",
    "alternateName": "south africa time",
    "country": "south africa"
  },
  {
    "name": "manzini",
    "timezone": "africa/mbabane",
    "offset": 120,
    "abbreviation": "sast",
    "alternateName": "south africa time",
    "country": "eswatini"
  },
  {
    "name": "mbabane",
    "timezone": "africa/mbabane",
    "offset": 120,
    "abbreviation": "sast",
    "alternateName": "south africa time",
    "country": "eswatini"
  },
  {
    "name": "lobamba",
    "timezone": "africa/mbabane",
    "offset": 120,
    "abbreviation": "sast",
    "alternateName": "south africa time",
    "country": "eswatini"
  },
  {
    "name": "maseru",
    "timezone": "africa/maseru",
    "offset": 120,
    "abbreviation": "sast",
    "alternateName": "south africa time",
    "country": "lesotho"
  },
  {
    "name": "mafeteng",
    "timezone": "africa/maseru",
    "offset": 120,
    "abbreviation": "sast",
    "alternateName": "south africa time",
    "country": "lesotho"
  },
  {
    "name": "leribe",
    "timezone": "africa/maseru",
    "offset": 120,
    "abbreviation": "sast",
    "alternateName": "south africa time",
    "country": "lesotho"
  },
  {
    "name": "maputsoe",
    "timezone": "africa/maseru",
    "offset": 120,
    "abbreviation": "sast",
    "alternateName": "south africa time",
    "country": "lesotho"
  },
  {
    "name": "al ahmadi",
    "timezone": "asia/kuwait",
    "offset": 180,
    "abbreviation": "ast",
    "alternateName": "arabian time",
    "country": "kuwait"
  },
  {
    "name": "hawalli",
    "timezone": "asia/kuwait",
    "offset": 180,
    "abbreviation": "ast",
    "alternateName": "arabian time",
    "country": "kuwait"
  },
  {
    "name": "as salimiyah",
    "timezone": "asia/kuwait",
    "offset": 180,
    "abbreviation": "ast",
    "alternateName": "arabian time",
    "country": "kuwait"
  },
  {
    "name": "sabah as salim",
    "timezone": "asia/kuwait",
    "offset": 180,
    "abbreviation": "ast",
    "alternateName": "arabian time",
    "country": "kuwait"
  },
  {
    "name": "baghdad",
    "timezone": "asia/baghdad",
    "offset": 180,
    "abbreviation": "ast",
    "alternateName": "arabian time",
    "country": "iraq"
  },
  {
    "name": "basrah",
    "timezone": "asia/baghdad",
    "offset": 180,
    "abbreviation": "ast",
    "alternateName": "arabian time",
    "country": "iraq"
  },
  {
    "name": "al mawsil al jadidah",
    "timezone": "asia/baghdad",
    "offset": 180,
    "abbreviation": "ast",
    "alternateName": "arabian time",
    "country": "iraq"
  },
  {
    "name": "al basrah al qadimah",
    "timezone": "asia/baghdad",
    "offset": 180,
    "abbreviation": "ast",
    "alternateName": "arabian time",
    "country": "iraq"
  },
  {
    "name": "doha",
    "timezone": "asia/qatar",
    "offset": 180,
    "abbreviation": "ast",
    "alternateName": "arabian time",
    "country": "qatar"
  },
  {
    "name": "ar rayyan",
    "timezone": "asia/qatar",
    "offset": 180,
    "abbreviation": "ast",
    "alternateName": "arabian time",
    "country": "qatar"
  },
  {
    "name": "umm salal muhammad",
    "timezone": "asia/qatar",
    "offset": 180,
    "abbreviation": "ast",
    "alternateName": "arabian time",
    "country": "qatar"
  },
  {
    "name": "al wakrah",
    "timezone": "asia/qatar",
    "offset": 180,
    "abbreviation": "ast",
    "alternateName": "arabian time",
    "country": "qatar"
  },
  {
    "name": "manama",
    "timezone": "asia/bahrain",
    "offset": 180,
    "abbreviation": "ast",
    "alternateName": "arabian time",
    "country": "bahrain"
  },
  {
    "name": "al muharraq",
    "timezone": "asia/bahrain",
    "offset": 180,
    "abbreviation": "ast",
    "alternateName": "arabian time",
    "country": "bahrain"
  },
  {
    "name": "ar rifa'",
    "timezone": "asia/bahrain",
    "offset": 180,
    "abbreviation": "ast",
    "alternateName": "arabian time",
    "country": "bahrain"
  },
  {
    "name": "dar kulayb",
    "timezone": "asia/bahrain",
    "offset": 180,
    "abbreviation": "ast",
    "alternateName": "arabian time",
    "country": "bahrain"
  },
  {
    "name": "riyadh",
    "timezone": "asia/riyadh",
    "offset": 180,
    "abbreviation": "ast",
    "alternateName": "arabian time",
    "country": "saudi arabia"
  },
  {
    "name": "jeddah",
    "timezone": "asia/riyadh",
    "offset": 180,
    "abbreviation": "ast",
    "alternateName": "arabian time",
    "country": "saudi arabia"
  },
  {
    "name": "mecca",
    "timezone": "asia/riyadh",
    "offset": 180,
    "abbreviation": "ast",
    "alternateName": "arabian time",
    "country": "saudi arabia"
  },
  {
    "name": "medina",
    "timezone": "asia/riyadh",
    "offset": 180,
    "abbreviation": "ast",
    "alternateName": "arabian time",
    "country": "saudi arabia"
  },
  {
    "name": "sanaa",
    "timezone": "asia/aden",
    "offset": 180,
    "abbreviation": "ast",
    "alternateName": "arabian time",
    "country": "yemen"
  },
  {
    "name": "al hudaydah",
    "timezone": "asia/aden",
    "offset": 180,
    "abbreviation": "ast",
    "alternateName": "arabian time",
    "country": "yemen"
  },
  {
    "name": "taiz",
    "timezone": "asia/aden",
    "offset": 180,
    "abbreviation": "ast",
    "alternateName": "arabian time",
    "country": "yemen"
  },
  {
    "name": "aden",
    "timezone": "asia/aden",
    "offset": 180,
    "abbreviation": "ast",
    "alternateName": "arabian time",
    "country": "yemen"
  },
  {
    "name": "addis ababa",
    "timezone": "africa/addis_ababa",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "ethiopia"
  },
  {
    "name": "dire dawa",
    "timezone": "africa/addis_ababa",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "ethiopia"
  },
  {
    "name": "mek'ele",
    "timezone": "africa/addis_ababa",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "ethiopia"
  },
  {
    "name": "nazret",
    "timezone": "africa/addis_ababa",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "ethiopia"
  },
  {
    "name": "antananarivo",
    "timezone": "indian/antananarivo",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "madagascar"
  },
  {
    "name": "toamasina",
    "timezone": "indian/antananarivo",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "madagascar"
  },
  {
    "name": "antsirabe",
    "timezone": "indian/antananarivo",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "madagascar"
  },
  {
    "name": "fianarantsoa",
    "timezone": "indian/antananarivo",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "madagascar"
  },
  {
    "name": "asmara",
    "timezone": "africa/asmara",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "eritrea"
  },
  {
    "name": "keren",
    "timezone": "africa/asmara",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "eritrea"
  },
  {
    "name": "massawa",
    "timezone": "africa/asmara",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "eritrea"
  },
  {
    "name": "assab",
    "timezone": "africa/asmara",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "eritrea"
  },
  {
    "name": "dar es salaam",
    "timezone": "africa/dar_es_salaam",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "tanzania"
  },
  {
    "name": "mwanza",
    "timezone": "africa/dar_es_salaam",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "tanzania"
  },
  {
    "name": "zanzibar",
    "timezone": "africa/dar_es_salaam",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "tanzania"
  },
  {
    "name": "arusha",
    "timezone": "africa/dar_es_salaam",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "tanzania"
  },
  {
    "name": "djibouti",
    "timezone": "africa/djibouti",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "djibouti"
  },
  {
    "name": "'ali sabieh",
    "timezone": "africa/djibouti",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "djibouti"
  },
  {
    "name": "tadjourah",
    "timezone": "africa/djibouti",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "djibouti"
  },
  {
    "name": "obock",
    "timezone": "africa/djibouti",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "djibouti"
  },
  {
    "name": "kampala",
    "timezone": "africa/kampala",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "uganda"
  },
  {
    "name": "gulu",
    "timezone": "africa/kampala",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "uganda"
  },
  {
    "name": "lira",
    "timezone": "africa/kampala",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "uganda"
  },
  {
    "name": "mbarara",
    "timezone": "africa/kampala",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "uganda"
  },
  {
    "name": "mamoudzou",
    "timezone": "indian/mayotte",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "mayotte"
  },
  {
    "name": "koungou",
    "timezone": "indian/mayotte",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "mayotte"
  },
  {
    "name": "dzaoudzi",
    "timezone": "indian/mayotte",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "mayotte"
  },
  {
    "name": "mogadishu",
    "timezone": "africa/mogadishu",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "somalia"
  },
  {
    "name": "hargeysa",
    "timezone": "africa/mogadishu",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "somalia"
  },
  {
    "name": "berbera",
    "timezone": "africa/mogadishu",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "somalia"
  },
  {
    "name": "kismayo",
    "timezone": "africa/mogadishu",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "somalia"
  },
  {
    "name": "moroni",
    "timezone": "indian/comoro",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "comoros"
  },
  {
    "name": "moutsamoudou",
    "timezone": "indian/comoro",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "comoros"
  },
  {
    "name": "nairobi",
    "timezone": "africa/nairobi",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "kenya"
  },
  {
    "name": "mombasa",
    "timezone": "africa/nairobi",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "kenya"
  },
  {
    "name": "ruiru",
    "timezone": "africa/nairobi",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "kenya"
  },
  {
    "name": "kikuyu",
    "timezone": "africa/nairobi",
    "offset": 180,
    "abbreviation": "eat",
    "alternateName": "east africa time",
    "country": "kenya"
  },
  {
    "name": "aleppo",
    "timezone": "asia/damascus",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "syria"
  },
  {
    "name": "damascus",
    "timezone": "asia/damascus",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "syria"
  },
  {
    "name": "homs",
    "timezone": "asia/damascus",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "syria"
  },
  {
    "name": "hamah",
    "timezone": "asia/damascus",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "syria"
  },
  {
    "name": "amman",
    "timezone": "asia/amman",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "jordan"
  },
  {
    "name": "zarqa",
    "timezone": "asia/amman",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "jordan"
  },
  {
    "name": "irbid",
    "timezone": "asia/amman",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "jordan"
  },
  {
    "name": "russeifa",
    "timezone": "asia/amman",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "jordan"
  },
  {
    "name": "athens",
    "timezone": "europe/athens",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "greece"
  },
  {
    "name": "thessaloniki",
    "timezone": "europe/athens",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "greece"
  },
  {
    "name": "patra",
    "timezone": "europe/athens",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "greece"
  },
  {
    "name": "piraeus",
    "timezone": "europe/athens",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "greece"
  },
  {
    "name": "beirut",
    "timezone": "asia/beirut",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "lebanon"
  },
  {
    "name": "ra's bayrut",
    "timezone": "asia/beirut",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "lebanon"
  },
  {
    "name": "tripoli",
    "timezone": "asia/beirut",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "lebanon"
  },
  {
    "name": "sidon",
    "timezone": "asia/beirut",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "lebanon"
  },
  {
    "name": "bucharest",
    "timezone": "europe/bucharest",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "romania"
  },
  {
    "name": "sector 3",
    "timezone": "europe/bucharest",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "romania"
  },
  {
    "name": "sector 6",
    "timezone": "europe/bucharest",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "romania"
  },
  {
    "name": "sector 2",
    "timezone": "europe/bucharest",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "romania"
  },
  {
    "name": "chisinau",
    "timezone": "europe/chisinau",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "moldova"
  },
  {
    "name": "tiraspol",
    "timezone": "europe/chisinau",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "moldova"
  },
  {
    "name": "balti",
    "timezone": "europe/chisinau",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "moldova"
  },
  {
    "name": "bender",
    "timezone": "europe/chisinau",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "moldova"
  },
  {
    "name": "east jerusalem",
    "timezone": "asia/hebron",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "palestinian territory"
  },
  {
    "name": "gaza",
    "timezone": "asia/hebron",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "palestinian territory"
  },
  {
    "name": "khan yunis",
    "timezone": "asia/hebron",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "palestinian territory"
  },
  {
    "name": "jabalya",
    "timezone": "asia/hebron",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "palestinian territory"
  },
  {
    "name": "helsinki",
    "timezone": "europe/helsinki",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "finland"
  },
  {
    "name": "espoo",
    "timezone": "europe/helsinki",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "finland"
  },
  {
    "name": "tampere",
    "timezone": "europe/helsinki",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "finland"
  },
  {
    "name": "vantaa",
    "timezone": "europe/helsinki",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "finland"
  },
  {
    "name": "kyiv",
    "timezone": "europe/kiev",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "ukraine"
  },
  {
    "name": "kharkiv",
    "timezone": "europe/kiev",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "ukraine"
  },
  {
    "name": "donetsk",
    "timezone": "europe/kiev",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "ukraine"
  },
  {
    "name": "odessa",
    "timezone": "europe/kiev",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "ukraine"
  },
  {
    "name": "mariehamn",
    "timezone": "europe/mariehamn",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "aland islands"
  },
  {
    "name": "nicosia",
    "timezone": "asia/nicosia",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "cyprus"
  },
  {
    "name": "limassol",
    "timezone": "asia/nicosia",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "cyprus"
  },
  {
    "name": "larnaca",
    "timezone": "asia/nicosia",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "cyprus"
  },
  {
    "name": "strovolos",
    "timezone": "asia/nicosia",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "cyprus"
  },
  {
    "name": "riga",
    "timezone": "europe/riga",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "latvia"
  },
  {
    "name": "daugavpils",
    "timezone": "europe/riga",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "latvia"
  },
  {
    "name": "liepaja",
    "timezone": "europe/riga",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "latvia"
  },
  {
    "name": "jelgava",
    "timezone": "europe/riga",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "latvia"
  },
  {
    "name": "sofia",
    "timezone": "europe/sofia",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "bulgaria"
  },
  {
    "name": "plovdiv",
    "timezone": "europe/sofia",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "bulgaria"
  },
  {
    "name": "varna",
    "timezone": "europe/sofia",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "bulgaria"
  },
  {
    "name": "burgas",
    "timezone": "europe/sofia",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "bulgaria"
  },
  {
    "name": "tallinn",
    "timezone": "europe/tallinn",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "estonia"
  },
  {
    "name": "tartu",
    "timezone": "europe/tallinn",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "estonia"
  },
  {
    "name": "narva",
    "timezone": "europe/tallinn",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "estonia"
  },
  {
    "name": "kohtla-jarve",
    "timezone": "europe/tallinn",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "estonia"
  },
  {
    "name": "vilnius",
    "timezone": "europe/vilnius",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "lithuania"
  },
  {
    "name": "kaunas",
    "timezone": "europe/vilnius",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "lithuania"
  },
  {
    "name": "klaipeda",
    "timezone": "europe/vilnius",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "lithuania"
  },
  {
    "name": "siauliai",
    "timezone": "europe/vilnius",
    "offset": 120,
    "abbreviation": "eet",
    "alternateName": "eastern european time",
    "country": "lithuania"
  },
  {
    "name": "jerusalem",
    "timezone": "asia/jerusalem",
    "offset": 120,
    "abbreviation": "ist",
    "alternateName": "israel time",
    "country": "israel"
  },
  {
    "name": "tel aviv",
    "timezone": "asia/jerusalem",
    "offset": 120,
    "abbreviation": "ist",
    "alternateName": "israel time",
    "country": "israel"
  },
  {
    "name": "west jerusalem",
    "timezone": "asia/jerusalem",
    "offset": 120,
    "abbreviation": "ist",
    "alternateName": "israel time",
    "country": "israel"
  },
  {
    "name": "haifa",
    "timezone": "asia/jerusalem",
    "offset": 120,
    "abbreviation": "ist",
    "alternateName": "israel time",
    "country": "israel"
  },
  {
    "name": "minsk",
    "timezone": "europe/minsk",
    "offset": 180,
    "abbreviation": "msk",
    "alternateName": "moscow time",
    "country": "belarus"
  },
  {
    "name": "homyel'",
    "timezone": "europe/minsk",
    "offset": 180,
    "abbreviation": "msk",
    "alternateName": "moscow time",
    "country": "belarus"
  },
  {
    "name": "mahilyow",
    "timezone": "europe/minsk",
    "offset": 180,
    "abbreviation": "msk",
    "alternateName": "moscow time",
    "country": "belarus"
  },
  {
    "name": "vitebsk",
    "timezone": "europe/minsk",
    "offset": 180,
    "abbreviation": "msk",
    "alternateName": "moscow time",
    "country": "belarus"
  },
  {
    "name": "moscow",
    "timezone": "europe/moscow",
    "offset": 180,
    "abbreviation": "msk",
    "alternateName": "moscow time",
    "country": "russia"
  },
  {
    "name": "saint petersburg",
    "timezone": "europe/moscow",
    "offset": 180,
    "abbreviation": "msk",
    "alternateName": "moscow time",
    "country": "russia"
  },
  {
    "name": "nizhniy novgorod",
    "timezone": "europe/moscow",
    "offset": 180,
    "abbreviation": "msk",
    "alternateName": "moscow time",
    "country": "russia"
  },
  {
    "name": "kazan",
    "timezone": "europe/moscow",
    "offset": 180,
    "abbreviation": "msk",
    "alternateName": "moscow time",
    "country": "russia"
  },
  {
    "name": "sevastopol",
    "timezone": "europe/simferopol",
    "offset": 180,
    "abbreviation": "msk",
    "alternateName": "moscow time",
    "country": "ukraine"
  },
  {
    "name": "simferopol",
    "timezone": "europe/simferopol",
    "offset": 180,
    "abbreviation": "msk",
    "alternateName": "moscow time",
    "country": "ukraine"
  },
  {
    "name": "kerch",
    "timezone": "europe/simferopol",
    "offset": 180,
    "abbreviation": "msk",
    "alternateName": "moscow time",
    "country": "ukraine"
  },
  {
    "name": "yevpatoriya",
    "timezone": "europe/simferopol",
    "offset": 180,
    "abbreviation": "msk",
    "alternateName": "moscow time",
    "country": "ukraine"
  },
  {
    "name": "syowa",
    "timezone": "antarctica/syowa",
    "offset": 180,
    "abbreviation": "syot",
    "alternateName": "syowa time",
    "country": "antarctica"
  },
  {
    "name": "istanbul",
    "timezone": "europe/istanbul",
    "offset": 180,
    "abbreviation": "trt",
    "alternateName": "turkey time",
    "country": "turkey"
  },
  {
    "name": "ankara",
    "timezone": "europe/istanbul",
    "offset": 180,
    "abbreviation": "trt",
    "alternateName": "turkey time",
    "country": "turkey"
  },
  {
    "name": "izmir",
    "timezone": "europe/istanbul",
    "offset": 180,
    "abbreviation": "trt",
    "alternateName": "turkey time",
    "country": "turkey"
  },
  {
    "name": "bursa",
    "timezone": "europe/istanbul",
    "offset": 180,
    "abbreviation": "trt",
    "alternateName": "turkey time",
    "country": "turkey"
  },
  {
    "name": "yerevan",
    "timezone": "asia/yerevan",
    "offset": 240,
    "abbreviation": "amt",
    "alternateName": "armenia time",
    "country": "armenia"
  },
  {
    "name": "gyumri",
    "timezone": "asia/yerevan",
    "offset": 240,
    "abbreviation": "amt",
    "alternateName": "armenia time",
    "country": "armenia"
  },
  {
    "name": "vanadzor",
    "timezone": "asia/yerevan",
    "offset": 240,
    "abbreviation": "amt",
    "alternateName": "armenia time",
    "country": "armenia"
  },
  {
    "name": "vagharshapat",
    "timezone": "asia/yerevan",
    "offset": 240,
    "abbreviation": "amt",
    "alternateName": "armenia time",
    "country": "armenia"
  },
  {
    "name": "baku",
    "timezone": "asia/baku",
    "offset": 240,
    "abbreviation": "azt",
    "alternateName": "azerbaijan time",
    "country": "azerbaijan"
  },
  {
    "name": "ganja",
    "timezone": "asia/baku",
    "offset": 240,
    "abbreviation": "azt",
    "alternateName": "azerbaijan time",
    "country": "azerbaijan"
  },
  {
    "name": "sumqayit",
    "timezone": "asia/baku",
    "offset": 240,
    "abbreviation": "azt",
    "alternateName": "azerbaijan time",
    "country": "azerbaijan"
  },
  {
    "name": "lankaran",
    "timezone": "asia/baku",
    "offset": 240,
    "abbreviation": "azt",
    "alternateName": "azerbaijan time",
    "country": "azerbaijan"
  },
  {
    "name": "tbilisi",
    "timezone": "asia/tbilisi",
    "offset": 240,
    "abbreviation": "get",
    "alternateName": "georgia time",
    "country": "georgia"
  },
  {
    "name": "kutaisi",
    "timezone": "asia/tbilisi",
    "offset": 240,
    "abbreviation": "get",
    "alternateName": "georgia time",
    "country": "georgia"
  },
  {
    "name": "batumi",
    "timezone": "asia/tbilisi",
    "offset": 240,
    "abbreviation": "get",
    "alternateName": "georgia time",
    "country": "georgia"
  },
  {
    "name": "sokhumi",
    "timezone": "asia/tbilisi",
    "offset": 240,
    "abbreviation": "get",
    "alternateName": "georgia time",
    "country": "georgia"
  },
  {
    "name": "dubai",
    "timezone": "asia/dubai",
    "offset": 240,
    "abbreviation": "gst",
    "alternateName": "gulf time",
    "country": "united arab emirates"
  },
  {
    "name": "sharjah",
    "timezone": "asia/dubai",
    "offset": 240,
    "abbreviation": "gst",
    "alternateName": "gulf time",
    "country": "united arab emirates"
  },
  {
    "name": "abu dhabi",
    "timezone": "asia/dubai",
    "offset": 240,
    "abbreviation": "gst",
    "alternateName": "gulf time",
    "country": "united arab emirates"
  },
  {
    "name": "ajman city",
    "timezone": "asia/dubai",
    "offset": 240,
    "abbreviation": "gst",
    "alternateName": "gulf time",
    "country": "united arab emirates"
  },
  {
    "name": "muscat",
    "timezone": "asia/muscat",
    "offset": 240,
    "abbreviation": "gst",
    "alternateName": "gulf time",
    "country": "oman"
  },
  {
    "name": "seeb",
    "timezone": "asia/muscat",
    "offset": 240,
    "abbreviation": "gst",
    "alternateName": "gulf time",
    "country": "oman"
  },
  {
    "name": "salalah",
    "timezone": "asia/muscat",
    "offset": 240,
    "abbreviation": "gst",
    "alternateName": "gulf time",
    "country": "oman"
  },
  {
    "name": "bawshar",
    "timezone": "asia/muscat",
    "offset": 240,
    "abbreviation": "gst",
    "alternateName": "gulf time",
    "country": "oman"
  },
  {
    "name": "port louis",
    "timezone": "indian/mauritius",
    "offset": 240,
    "abbreviation": "mut",
    "alternateName": "mauritius time",
    "country": "mauritius"
  },
  {
    "name": "beau bassin-rose hill",
    "timezone": "indian/mauritius",
    "offset": 240,
    "abbreviation": "mut",
    "alternateName": "mauritius time",
    "country": "mauritius"
  },
  {
    "name": "vacoas",
    "timezone": "indian/mauritius",
    "offset": 240,
    "abbreviation": "mut",
    "alternateName": "mauritius time",
    "country": "mauritius"
  },
  {
    "name": "curepipe",
    "timezone": "indian/mauritius",
    "offset": 240,
    "abbreviation": "mut",
    "alternateName": "mauritius time",
    "country": "mauritius"
  },
  {
    "name": "saint-denis",
    "timezone": "indian/reunion",
    "offset": 240,
    "abbreviation": "ret",
    "alternateName": "reunion time",
    "country": "reunion"
  },
  {
    "name": "saint-paul",
    "timezone": "indian/reunion",
    "offset": 240,
    "abbreviation": "ret",
    "alternateName": "reunion time",
    "country": "reunion"
  },
  {
    "name": "saint-pierre",
    "timezone": "indian/reunion",
    "offset": 240,
    "abbreviation": "ret",
    "alternateName": "reunion time",
    "country": "reunion"
  },
  {
    "name": "le tampon",
    "timezone": "indian/reunion",
    "offset": 240,
    "abbreviation": "ret",
    "alternateName": "reunion time",
    "country": "reunion"
  },
  {
    "name": "samara",
    "timezone": "europe/samara",
    "offset": 240,
    "abbreviation": "samt",
    "alternateName": "samara time",
    "country": "russia"
  },
  {
    "name": "saratov",
    "timezone": "europe/samara",
    "offset": 240,
    "abbreviation": "samt",
    "alternateName": "samara time",
    "country": "russia"
  },
  {
    "name": "tolyatti",
    "timezone": "europe/samara",
    "offset": 240,
    "abbreviation": "samt",
    "alternateName": "samara time",
    "country": "russia"
  },
  {
    "name": "ulyanovsk",
    "timezone": "europe/samara",
    "offset": 240,
    "abbreviation": "samt",
    "alternateName": "samara time",
    "country": "russia"
  },
  {
    "name": "victoria",
    "timezone": "indian/mahe",
    "offset": 240,
    "abbreviation": "sct",
    "alternateName": "seychelles time",
    "country": "seychelles"
  },
  {
    "name": "kabul",
    "timezone": "asia/kabul",
    "offset": 270,
    "abbreviation": "aft",
    "alternateName": "afghanistan time",
    "country": "afghanistan"
  },
  {
    "name": "herat",
    "timezone": "asia/kabul",
    "offset": 270,
    "abbreviation": "aft",
    "alternateName": "afghanistan time",
    "country": "afghanistan"
  },
  {
    "name": "mazar-e sharif",
    "timezone": "asia/kabul",
    "offset": 270,
    "abbreviation": "aft",
    "alternateName": "afghanistan time",
    "country": "afghanistan"
  },
  {
    "name": "kandahar",
    "timezone": "asia/kabul",
    "offset": 270,
    "abbreviation": "aft",
    "alternateName": "afghanistan time",
    "country": "afghanistan"
  },
  {
    "name": "tehran",
    "timezone": "asia/tehran",
    "offset": 210,
    "abbreviation": "irst",
    "alternateName": "iran time",
    "country": "iran"
  },
  {
    "name": "mashhad",
    "timezone": "asia/tehran",
    "offset": 210,
    "abbreviation": "irst",
    "alternateName": "iran time",
    "country": "iran"
  },
  {
    "name": "isfahan",
    "timezone": "asia/tehran",
    "offset": 210,
    "abbreviation": "irst",
    "alternateName": "iran time",
    "country": "iran"
  },
  {
    "name": "karaj",
    "timezone": "asia/tehran",
    "offset": 210,
    "abbreviation": "irst",
    "alternateName": "iran time",
    "country": "iran"
  },
  {
    "name": "port-aux-francais",
    "timezone": "indian/kerguelen",
    "offset": 300,
    "abbreviation": "fsat",
    "alternateName": "french southern & antarctic time",
    "country": "french southern territories"
  },
  {
    "name": "male",
    "timezone": "indian/maldives",
    "offset": 300,
    "abbreviation": "mvt",
    "alternateName": "maldives time",
    "country": "maldives"
  },
  {
    "name": "mawson",
    "timezone": "antarctica/mawson",
    "offset": 300,
    "abbreviation": "mawt",
    "alternateName": "mawson time",
    "country": "antarctica"
  },
  {
    "name": "karachi",
    "timezone": "asia/karachi",
    "offset": 300,
    "abbreviation": "pkt",
    "alternateName": "pakistan time",
    "country": "pakistan"
  },
  {
    "name": "lahore",
    "timezone": "asia/karachi",
    "offset": 300,
    "abbreviation": "pkt",
    "alternateName": "pakistan time",
    "country": "pakistan"
  },
  {
    "name": "faisalabad",
    "timezone": "asia/karachi",
    "offset": 300,
    "abbreviation": "pkt",
    "alternateName": "pakistan time",
    "country": "pakistan"
  },
  {
    "name": "rawalpindi",
    "timezone": "asia/karachi",
    "offset": 300,
    "abbreviation": "pkt",
    "alternateName": "pakistan time",
    "country": "pakistan"
  },
  {
    "name": "dushanbe",
    "timezone": "asia/dushanbe",
    "offset": 300,
    "abbreviation": "tjt",
    "alternateName": "tajikistan time",
    "country": "tajikistan"
  },
  {
    "name": "khujand",
    "timezone": "asia/dushanbe",
    "offset": 300,
    "abbreviation": "tjt",
    "alternateName": "tajikistan time",
    "country": "tajikistan"
  },
  {
    "name": "kulob",
    "timezone": "asia/dushanbe",
    "offset": 300,
    "abbreviation": "tjt",
    "alternateName": "tajikistan time",
    "country": "tajikistan"
  },
  {
    "name": "bokhtar",
    "timezone": "asia/dushanbe",
    "offset": 300,
    "abbreviation": "tjt",
    "alternateName": "tajikistan time",
    "country": "tajikistan"
  },
  {
    "name": "ashgabat",
    "timezone": "asia/ashgabat",
    "offset": 300,
    "abbreviation": "tmt",
    "alternateName": "turkmenistan time",
    "country": "turkmenistan"
  },
  {
    "name": "turkmenabat",
    "timezone": "asia/ashgabat",
    "offset": 300,
    "abbreviation": "tmt",
    "alternateName": "turkmenistan time",
    "country": "turkmenistan"
  },
  {
    "name": "dasoguz",
    "timezone": "asia/ashgabat",
    "offset": 300,
    "abbreviation": "tmt",
    "alternateName": "turkmenistan time",
    "country": "turkmenistan"
  },
  {
    "name": "mary",
    "timezone": "asia/ashgabat",
    "offset": 300,
    "abbreviation": "tmt",
    "alternateName": "turkmenistan time",
    "country": "turkmenistan"
  },
  {
    "name": "tashkent",
    "timezone": "asia/tashkent",
    "offset": 300,
    "abbreviation": "uzt",
    "alternateName": "uzbekistan time",
    "country": "uzbekistan"
  },
  {
    "name": "namangan",
    "timezone": "asia/tashkent",
    "offset": 300,
    "abbreviation": "uzt",
    "alternateName": "uzbekistan time",
    "country": "uzbekistan"
  },
  {
    "name": "samarkand",
    "timezone": "asia/tashkent",
    "offset": 300,
    "abbreviation": "uzt",
    "alternateName": "uzbekistan time",
    "country": "uzbekistan"
  },
  {
    "name": "andijon",
    "timezone": "asia/tashkent",
    "offset": 300,
    "abbreviation": "uzt",
    "alternateName": "uzbekistan time",
    "country": "uzbekistan"
  },
  {
    "name": "kyzylorda",
    "timezone": "asia/qyzylorda",
    "offset": 300,
    "abbreviation": "aqtt",
    "alternateName": "west kazakhstan time",
    "country": "kazakhstan"
  },
  {
    "name": "aktobe",
    "timezone": "asia/qyzylorda",
    "offset": 300,
    "abbreviation": "aqtt",
    "alternateName": "west kazakhstan time",
    "country": "kazakhstan"
  },
  {
    "name": "oral",
    "timezone": "asia/qyzylorda",
    "offset": 300,
    "abbreviation": "aqtt",
    "alternateName": "west kazakhstan time",
    "country": "kazakhstan"
  },
  {
    "name": "atyrau",
    "timezone": "asia/qyzylorda",
    "offset": 300,
    "abbreviation": "aqtt",
    "alternateName": "west kazakhstan time",
    "country": "kazakhstan"
  },
  {
    "name": "yekaterinburg",
    "timezone": "asia/yekaterinburg",
    "offset": 300,
    "abbreviation": "yekt",
    "alternateName": "yekaterinburg time",
    "country": "russia"
  },
  {
    "name": "chelyabinsk",
    "timezone": "asia/yekaterinburg",
    "offset": 300,
    "abbreviation": "yekt",
    "alternateName": "yekaterinburg time",
    "country": "russia"
  },
  {
    "name": "ufa",
    "timezone": "asia/yekaterinburg",
    "offset": 300,
    "abbreviation": "yekt",
    "alternateName": "yekaterinburg time",
    "country": "russia"
  },
  {
    "name": "perm",
    "timezone": "asia/yekaterinburg",
    "offset": 300,
    "abbreviation": "yekt",
    "alternateName": "yekaterinburg time",
    "country": "russia"
  },
  {
    "name": "colombo",
    "timezone": "asia/colombo",
    "offset": 330,
    "abbreviation": "ist",
    "alternateName": "indian standard time",
    "country": "sri lanka"
  },
  {
    "name": "dehiwala-mount lavinia",
    "timezone": "asia/colombo",
    "offset": 330,
    "abbreviation": "ist",
    "alternateName": "indian standard time",
    "country": "sri lanka"
  },
  {
    "name": "maharagama",
    "timezone": "asia/colombo",
    "offset": 330,
    "abbreviation": "ist",
    "alternateName": "indian standard time",
    "country": "sri lanka"
  },
  {
    "name": "jaffna",
    "timezone": "asia/colombo",
    "offset": 330,
    "abbreviation": "ist",
    "alternateName": "indian standard time",
    "country": "sri lanka"
  },
  {
    "name": "mumbai",
    "timezone": "asia/kolkata",
    "offset": 330,
    "abbreviation": "ist",
    "alternateName": "indian standard time",
    "country": "india"
  },
  {
    "name": "delhi",
    "timezone": "asia/kolkata",
    "offset": 330,
    "abbreviation": "ist",
    "alternateName": "indian standard time",
    "country": "india"
  },
  {
    "name": "bengaluru",
    "timezone": "asia/kolkata",
    "offset": 330,
    "abbreviation": "ist",
    "alternateName": "indian standard time",
    "country": "india"
  },
  {
    "name": "hyderabad",
    "timezone": "asia/kolkata",
    "offset": 330,
    "abbreviation": "ist",
    "alternateName": "indian standard time",
    "country": "india"
  },
  {
    "name": "kathmandu",
    "timezone": "asia/kathmandu",
    "offset": 345,
    "abbreviation": "npt",
    "alternateName": "nepal time",
    "country": "nepal"
  },
  {
    "name": "pokhara",
    "timezone": "asia/kathmandu",
    "offset": 345,
    "abbreviation": "npt",
    "alternateName": "nepal time",
    "country": "nepal"
  },
  {
    "name": "patan",
    "timezone": "asia/kathmandu",
    "offset": 345,
    "abbreviation": "npt",
    "alternateName": "nepal time",
    "country": "nepal"
  },
  {
    "name": "biratnagar",
    "timezone": "asia/kathmandu",
    "offset": 345,
    "abbreviation": "npt",
    "alternateName": "nepal time",
    "country": "nepal"
  },
  {
    "name": "dhaka",
    "timezone": "asia/dhaka",
    "offset": 360,
    "abbreviation": "bst",
    "alternateName": "bangladesh time",
    "country": "bangladesh"
  },
  {
    "name": "chattogram",
    "timezone": "asia/dhaka",
    "offset": 360,
    "abbreviation": "bst",
    "alternateName": "bangladesh time",
    "country": "bangladesh"
  },
  {
    "name": "khulna",
    "timezone": "asia/dhaka",
    "offset": 360,
    "abbreviation": "bst",
    "alternateName": "bangladesh time",
    "country": "bangladesh"
  },
  {
    "name": "rajshahi",
    "timezone": "asia/dhaka",
    "offset": 360,
    "abbreviation": "bst",
    "alternateName": "bangladesh time",
    "country": "bangladesh"
  },
  {
    "name": "thimphu",
    "timezone": "asia/thimphu",
    "offset": 360,
    "abbreviation": "btt",
    "alternateName": "bhutan time",
    "country": "bhutan"
  },
  {
    "name": "punakha",
    "timezone": "asia/thimphu",
    "offset": 360,
    "abbreviation": "btt",
    "alternateName": "bhutan time",
    "country": "bhutan"
  },
  {
    "name": "tsirang",
    "timezone": "asia/thimphu",
    "offset": 360,
    "abbreviation": "btt",
    "alternateName": "bhutan time",
    "country": "bhutan"
  },
  {
    "name": "phuntsholing",
    "timezone": "asia/thimphu",
    "offset": 360,
    "abbreviation": "btt",
    "alternateName": "bhutan time",
    "country": "bhutan"
  },
  {
    "name": "urumqi",
    "timezone": "asia/urumqi",
    "offset": 360,
    "abbreviation": "cst",
    "alternateName": "china time",
    "country": "china"
  },
  {
    "name": "shihezi",
    "timezone": "asia/urumqi",
    "offset": 360,
    "abbreviation": "cst",
    "alternateName": "china time",
    "country": "china"
  },
  {
    "name": "korla",
    "timezone": "asia/urumqi",
    "offset": 360,
    "abbreviation": "cst",
    "alternateName": "china time",
    "country": "china"
  },
  {
    "name": "aksu",
    "timezone": "asia/urumqi",
    "offset": 360,
    "abbreviation": "cst",
    "alternateName": "china time",
    "country": "china"
  },
  {
    "name": "almaty",
    "timezone": "asia/almaty",
    "offset": 360,
    "abbreviation": "almt",
    "alternateName": "east kazakhstan time",
    "country": "kazakhstan"
  },
  {
    "name": "karagandy",
    "timezone": "asia/almaty",
    "offset": 360,
    "abbreviation": "almt",
    "alternateName": "east kazakhstan time",
    "country": "kazakhstan"
  },
  {
    "name": "shymkent",
    "timezone": "asia/almaty",
    "offset": 360,
    "abbreviation": "almt",
    "alternateName": "east kazakhstan time",
    "country": "kazakhstan"
  },
  {
    "name": "taraz",
    "timezone": "asia/almaty",
    "offset": 360,
    "abbreviation": "almt",
    "alternateName": "east kazakhstan time",
    "country": "kazakhstan"
  },
  {
    "name": "chagos",
    "timezone": "indian/chagos",
    "offset": 360,
    "abbreviation": "iot",
    "alternateName": "indian ocean time",
    "country": "british indian ocean territory"
  },
  {
    "name": "bishkek",
    "timezone": "asia/bishkek",
    "offset": 360,
    "abbreviation": "kgt",
    "alternateName": "kyrgyzstan time",
    "country": "kyrgyzstan"
  },
  {
    "name": "osh",
    "timezone": "asia/bishkek",
    "offset": 360,
    "abbreviation": "kgt",
    "alternateName": "kyrgyzstan time",
    "country": "kyrgyzstan"
  },
  {
    "name": "jalal-abad",
    "timezone": "asia/bishkek",
    "offset": 360,
    "abbreviation": "kgt",
    "alternateName": "kyrgyzstan time",
    "country": "kyrgyzstan"
  },
  {
    "name": "karakol",
    "timezone": "asia/bishkek",
    "offset": 360,
    "abbreviation": "kgt",
    "alternateName": "kyrgyzstan time",
    "country": "kyrgyzstan"
  },
  {
    "name": "omsk",
    "timezone": "asia/omsk",
    "offset": 360,
    "abbreviation": "omst",
    "alternateName": "omsk time",
    "country": "russia"
  },
  {
    "name": "tara",
    "timezone": "asia/omsk",
    "offset": 360,
    "abbreviation": "omst",
    "alternateName": "omsk time",
    "country": "russia"
  },
  {
    "name": "kalachinsk",
    "timezone": "asia/omsk",
    "offset": 360,
    "abbreviation": "omst",
    "alternateName": "omsk time",
    "country": "russia"
  },
  {
    "name": "vostok",
    "timezone": "antarctica/vostok",
    "offset": 360,
    "abbreviation": "vost",
    "alternateName": "vostok time",
    "country": "antarctica"
  },
  {
    "name": "west island",
    "timezone": "indian/cocos",
    "offset": 390,
    "abbreviation": "cct",
    "alternateName": "cocos islands time",
    "country": "cocos islands"
  },
  {
    "name": "yangon",
    "timezone": "asia/yangon",
    "offset": 390,
    "abbreviation": "mmt",
    "alternateName": "myanmar time",
    "country": "myanmar"
  },
  {
    "name": "mandalay",
    "timezone": "asia/yangon",
    "offset": 390,
    "abbreviation": "mmt",
    "alternateName": "myanmar time",
    "country": "myanmar"
  },
  {
    "name": "nay pyi taw",
    "timezone": "asia/yangon",
    "offset": 390,
    "abbreviation": "mmt",
    "alternateName": "myanmar time",
    "country": "myanmar"
  },
  {
    "name": "mawlamyine",
    "timezone": "asia/yangon",
    "offset": 390,
    "abbreviation": "mmt",
    "alternateName": "myanmar time",
    "country": "myanmar"
  },
  {
    "name": "flying fish cove",
    "timezone": "indian/christmas",
    "offset": 420,
    "abbreviation": "cxt",
    "alternateName": "christmas island time",
    "country": "christmas island"
  },
  {
    "name": "davis",
    "timezone": "antarctica/davis",
    "offset": 420,
    "abbreviation": "davt",
    "alternateName": "davis time",
    "country": "antarctica"
  },
  {
    "name": "khovd",
    "timezone": "asia/hovd",
    "offset": 420,
    "abbreviation": "hovt",
    "alternateName": "hovd time",
    "country": "mongolia"
  },
  {
    "name": "olgii",
    "timezone": "asia/hovd",
    "offset": 420,
    "abbreviation": "hovt",
    "alternateName": "hovd time",
    "country": "mongolia"
  },
  {
    "name": "ulaangom",
    "timezone": "asia/hovd",
    "offset": 420,
    "abbreviation": "hovt",
    "alternateName": "hovd time",
    "country": "mongolia"
  },
  {
    "name": "uliastay",
    "timezone": "asia/hovd",
    "offset": 420,
    "abbreviation": "hovt",
    "alternateName": "hovd time",
    "country": "mongolia"
  },
  {
    "name": "bangkok",
    "timezone": "asia/bangkok",
    "offset": 420,
    "abbreviation": "ict",
    "alternateName": "indochina time",
    "country": "thailand"
  },
  {
    "name": "samut prakan",
    "timezone": "asia/bangkok",
    "offset": 420,
    "abbreviation": "ict",
    "alternateName": "indochina time",
    "country": "thailand"
  },
  {
    "name": "mueang nonthaburi",
    "timezone": "asia/bangkok",
    "offset": 420,
    "abbreviation": "ict",
    "alternateName": "indochina time",
    "country": "thailand"
  },
  {
    "name": "udon thani",
    "timezone": "asia/bangkok",
    "offset": 420,
    "abbreviation": "ict",
    "alternateName": "indochina time",
    "country": "thailand"
  },
  {
    "name": "ho chi minh city",
    "timezone": "asia/ho_chi_minh",
    "offset": 420,
    "abbreviation": "ict",
    "alternateName": "indochina time",
    "country": "vietnam"
  },
  {
    "name": "da nang",
    "timezone": "asia/ho_chi_minh",
    "offset": 420,
    "abbreviation": "ict",
    "alternateName": "indochina time",
    "country": "vietnam"
  },
  {
    "name": "bien hoa",
    "timezone": "asia/ho_chi_minh",
    "offset": 420,
    "abbreviation": "ict",
    "alternateName": "indochina time",
    "country": "vietnam"
  },
  {
    "name": "can tho",
    "timezone": "asia/ho_chi_minh",
    "offset": 420,
    "abbreviation": "ict",
    "alternateName": "indochina time",
    "country": "vietnam"
  },
  {
    "name": "phnom penh",
    "timezone": "asia/phnom_penh",
    "offset": 420,
    "abbreviation": "ict",
    "alternateName": "indochina time",
    "country": "cambodia"
  },
  {
    "name": "takeo",
    "timezone": "asia/phnom_penh",
    "offset": 420,
    "abbreviation": "ict",
    "alternateName": "indochina time",
    "country": "cambodia"
  },
  {
    "name": "sihanoukville",
    "timezone": "asia/phnom_penh",
    "offset": 420,
    "abbreviation": "ict",
    "alternateName": "indochina time",
    "country": "cambodia"
  },
  {
    "name": "battambang",
    "timezone": "asia/phnom_penh",
    "offset": 420,
    "abbreviation": "ict",
    "alternateName": "indochina time",
    "country": "cambodia"
  },
  {
    "name": "vientiane",
    "timezone": "asia/vientiane",
    "offset": 420,
    "abbreviation": "ict",
    "alternateName": "indochina time",
    "country": "laos"
  },
  {
    "name": "pakse",
    "timezone": "asia/vientiane",
    "offset": 420,
    "abbreviation": "ict",
    "alternateName": "indochina time",
    "country": "laos"
  },
  {
    "name": "thakhek",
    "timezone": "asia/vientiane",
    "offset": 420,
    "abbreviation": "ict",
    "alternateName": "indochina time",
    "country": "laos"
  },
  {
    "name": "savannakhet",
    "timezone": "asia/vientiane",
    "offset": 420,
    "abbreviation": "ict",
    "alternateName": "indochina time",
    "country": "laos"
  },
  {
    "name": "novosibirsk",
    "timezone": "asia/novosibirsk",
    "offset": 420,
    "abbreviation": "novt",
    "alternateName": "novosibirsk time",
    "country": "russia"
  },
  {
    "name": "krasnoyarsk",
    "timezone": "asia/novosibirsk",
    "offset": 420,
    "abbreviation": "novt",
    "alternateName": "novosibirsk time",
    "country": "russia"
  },
  {
    "name": "barnaul",
    "timezone": "asia/novosibirsk",
    "offset": 420,
    "abbreviation": "novt",
    "alternateName": "novosibirsk time",
    "country": "russia"
  },
  {
    "name": "novokuznetsk",
    "timezone": "asia/novosibirsk",
    "offset": 420,
    "abbreviation": "novt",
    "alternateName": "novosibirsk time",
    "country": "russia"
  },
  {
    "name": "jakarta",
    "timezone": "asia/jakarta",
    "offset": 420,
    "abbreviation": "wib",
    "alternateName": "western indonesia time",
    "country": "indonesia"
  },
  {
    "name": "surabaya",
    "timezone": "asia/jakarta",
    "offset": 420,
    "abbreviation": "wib",
    "alternateName": "western indonesia time",
    "country": "indonesia"
  },
  {
    "name": "medan",
    "timezone": "asia/jakarta",
    "offset": 420,
    "abbreviation": "wib",
    "alternateName": "western indonesia time",
    "country": "indonesia"
  },
  {
    "name": "bandung",
    "timezone": "asia/jakarta",
    "offset": 420,
    "abbreviation": "wib",
    "alternateName": "western indonesia time",
    "country": "indonesia"
  },
  {
    "name": "perth",
    "timezone": "australia/perth",
    "offset": 480,
    "abbreviation": "awst",
    "alternateName": "australian western time",
    "country": "australia"
  },
  {
    "name": "rockingham",
    "timezone": "australia/perth",
    "offset": 480,
    "abbreviation": "awst",
    "alternateName": "australian western time",
    "country": "australia"
  },
  {
    "name": "mandurah",
    "timezone": "australia/perth",
    "offset": 480,
    "abbreviation": "awst",
    "alternateName": "australian western time",
    "country": "australia"
  },
  {
    "name": "bunbury",
    "timezone": "australia/perth",
    "offset": 480,
    "abbreviation": "awst",
    "alternateName": "australian western time",
    "country": "australia"
  },
  {
    "name": "bandar seri begawan",
    "timezone": "asia/brunei",
    "offset": 480,
    "abbreviation": "bnt",
    "alternateName": "brunei darussalam time",
    "country": "brunei"
  },
  {
    "name": "kuala belait",
    "timezone": "asia/brunei",
    "offset": 480,
    "abbreviation": "bnt",
    "alternateName": "brunei darussalam time",
    "country": "brunei"
  },
  {
    "name": "seria",
    "timezone": "asia/brunei",
    "offset": 480,
    "abbreviation": "bnt",
    "alternateName": "brunei darussalam time",
    "country": "brunei"
  },
  {
    "name": "tutong",
    "timezone": "asia/brunei",
    "offset": 480,
    "abbreviation": "bnt",
    "alternateName": "brunei darussalam time",
    "country": "brunei"
  },
  {
    "name": "makassar",
    "timezone": "asia/makassar",
    "offset": 480,
    "abbreviation": "wita",
    "alternateName": "central indonesia time",
    "country": "indonesia"
  },
  {
    "name": "denpasar",
    "timezone": "asia/makassar",
    "offset": 480,
    "abbreviation": "wita",
    "alternateName": "central indonesia time",
    "country": "indonesia"
  },
  {
    "name": "banjarmasin",
    "timezone": "asia/makassar",
    "offset": 480,
    "abbreviation": "wita",
    "alternateName": "central indonesia time",
    "country": "indonesia"
  },
  {
    "name": "manado",
    "timezone": "asia/makassar",
    "offset": 480,
    "abbreviation": "wita",
    "alternateName": "central indonesia time",
    "country": "indonesia"
  },
  {
    "name": "macau",
    "timezone": "asia/macau",
    "offset": 480,
    "abbreviation": "cst",
    "alternateName": "china time",
    "country": "macao"
  },
  {
    "name": "shanghai",
    "timezone": "asia/shanghai",
    "offset": 480,
    "abbreviation": "cst",
    "alternateName": "china time",
    "country": "china"
  },
  {
    "name": "beijing",
    "timezone": "asia/shanghai",
    "offset": 480,
    "abbreviation": "cst",
    "alternateName": "china time",
    "country": "china"
  },
  {
    "name": "shenzhen",
    "timezone": "asia/shanghai",
    "offset": 480,
    "abbreviation": "cst",
    "alternateName": "china time",
    "country": "china"
  },
  {
    "name": "guangzhou",
    "timezone": "asia/shanghai",
    "offset": 480,
    "abbreviation": "cst",
    "alternateName": "china time",
    "country": "china"
  },
  {
    "name": "hong kong",
    "timezone": "asia/hong_kong",
    "offset": 480,
    "abbreviation": "hkt",
    "alternateName": "hong kong time",
    "country": "hong kong"
  },
  {
    "name": "kowloon",
    "timezone": "asia/hong_kong",
    "offset": 480,
    "abbreviation": "hkt",
    "alternateName": "hong kong time",
    "country": "hong kong"
  },
  {
    "name": "victoria",
    "timezone": "asia/hong_kong",
    "offset": 480,
    "abbreviation": "hkt",
    "alternateName": "hong kong time",
    "country": "hong kong"
  },
  {
    "name": "tuen mun",
    "timezone": "asia/hong_kong",
    "offset": 480,
    "abbreviation": "hkt",
    "alternateName": "hong kong time",
    "country": "hong kong"
  },
  {
    "name": "irkutsk",
    "timezone": "asia/irkutsk",
    "offset": 480,
    "abbreviation": "irkt",
    "alternateName": "irkutsk time",
    "country": "russia"
  },
  {
    "name": "ulan-ude",
    "timezone": "asia/irkutsk",
    "offset": 480,
    "abbreviation": "irkt",
    "alternateName": "irkutsk time",
    "country": "russia"
  },
  {
    "name": "bratsk",
    "timezone": "asia/irkutsk",
    "offset": 480,
    "abbreviation": "irkt",
    "alternateName": "irkutsk time",
    "country": "russia"
  },
  {
    "name": "angarsk",
    "timezone": "asia/irkutsk",
    "offset": 480,
    "abbreviation": "irkt",
    "alternateName": "irkutsk time",
    "country": "russia"
  },
  {
    "name": "kota bharu",
    "timezone": "asia/kuala_lumpur",
    "offset": 480,
    "abbreviation": "myt",
    "alternateName": "malaysia time",
    "country": "malaysia"
  },
  {
    "name": "kuala lumpur",
    "timezone": "asia/kuala_lumpur",
    "offset": 480,
    "abbreviation": "myt",
    "alternateName": "malaysia time",
    "country": "malaysia"
  },
  {
    "name": "klang",
    "timezone": "asia/kuala_lumpur",
    "offset": 480,
    "abbreviation": "myt",
    "alternateName": "malaysia time",
    "country": "malaysia"
  },
  {
    "name": "kampung baru subang",
    "timezone": "asia/kuala_lumpur",
    "offset": 480,
    "abbreviation": "myt",
    "alternateName": "malaysia time",
    "country": "malaysia"
  },
  {
    "name": "quezon city",
    "timezone": "asia/manila",
    "offset": 480,
    "abbreviation": "pht",
    "alternateName": "philippine time",
    "country": "philippines"
  },
  {
    "name": "manila",
    "timezone": "asia/manila",
    "offset": 480,
    "abbreviation": "pht",
    "alternateName": "philippine time",
    "country": "philippines"
  },
  {
    "name": "caloocan city",
    "timezone": "asia/manila",
    "offset": 480,
    "abbreviation": "pht",
    "alternateName": "philippine time",
    "country": "philippines"
  },
  {
    "name": "budta",
    "timezone": "asia/manila",
    "offset": 480,
    "abbreviation": "pht",
    "alternateName": "philippine time",
    "country": "philippines"
  },
  {
    "name": "singapore",
    "timezone": "asia/singapore",
    "offset": 480,
    "abbreviation": "sgt",
    "alternateName": "singapore time",
    "country": "singapore"
  },
  {
    "name": "woodlands",
    "timezone": "asia/singapore",
    "offset": 480,
    "abbreviation": "sgt",
    "alternateName": "singapore time",
    "country": "singapore"
  },
  {
    "name": "marine parade",
    "timezone": "asia/singapore",
    "offset": 480,
    "abbreviation": "sgt",
    "alternateName": "singapore time",
    "country": "singapore"
  },
  {
    "name": "taipei",
    "timezone": "asia/taipei",
    "offset": 480,
    "abbreviation": "twt",
    "alternateName": "taipei time",
    "country": "taiwan"
  },
  {
    "name": "kaohsiung",
    "timezone": "asia/taipei",
    "offset": 480,
    "abbreviation": "twt",
    "alternateName": "taipei time",
    "country": "taiwan"
  },
  {
    "name": "taichung",
    "timezone": "asia/taipei",
    "offset": 480,
    "abbreviation": "twt",
    "alternateName": "taipei time",
    "country": "taiwan"
  },
  {
    "name": "tainan",
    "timezone": "asia/taipei",
    "offset": 480,
    "abbreviation": "twt",
    "alternateName": "taipei time",
    "country": "taiwan"
  },
  {
    "name": "ulan bator",
    "timezone": "asia/ulaanbaatar",
    "offset": 480,
    "abbreviation": "ulat",
    "alternateName": "ulaanbaatar time",
    "country": "mongolia"
  },
  {
    "name": "erdenet",
    "timezone": "asia/ulaanbaatar",
    "offset": 480,
    "abbreviation": "ulat",
    "alternateName": "ulaanbaatar time",
    "country": "mongolia"
  },
  {
    "name": "darhan",
    "timezone": "asia/ulaanbaatar",
    "offset": 480,
    "abbreviation": "ulat",
    "alternateName": "ulaanbaatar time",
    "country": "mongolia"
  },
  {
    "name": "hovd",
    "timezone": "asia/ulaanbaatar",
    "offset": 480,
    "abbreviation": "ulat",
    "alternateName": "ulaanbaatar time",
    "country": "mongolia"
  },
  {
    "name": "eucla",
    "timezone": "australia/eucla",
    "offset": 525,
    "abbreviation": "acwst",
    "alternateName": "australian central western time",
    "country": "australia"
  },
  {
    "name": "dili",
    "timezone": "asia/dili",
    "offset": 540,
    "abbreviation": "tlt",
    "alternateName": "east timor time",
    "country": "timor leste"
  },
  {
    "name": "maliana",
    "timezone": "asia/dili",
    "offset": 540,
    "abbreviation": "tlt",
    "alternateName": "east timor time",
    "country": "timor leste"
  },
  {
    "name": "suai",
    "timezone": "asia/dili",
    "offset": 540,
    "abbreviation": "tlt",
    "alternateName": "east timor time",
    "country": "timor leste"
  },
  {
    "name": "likisa",
    "timezone": "asia/dili",
    "offset": 540,
    "abbreviation": "tlt",
    "alternateName": "east timor time",
    "country": "timor leste"
  },
  {
    "name": "ambon",
    "timezone": "asia/jayapura",
    "offset": 540,
    "abbreviation": "wit",
    "alternateName": "eastern indonesia time",
    "country": "indonesia"
  },
  {
    "name": "jayapura",
    "timezone": "asia/jayapura",
    "offset": 540,
    "abbreviation": "wit",
    "alternateName": "eastern indonesia time",
    "country": "indonesia"
  },
  {
    "name": "sorong",
    "timezone": "asia/jayapura",
    "offset": 540,
    "abbreviation": "wit",
    "alternateName": "eastern indonesia time",
    "country": "indonesia"
  },
  {
    "name": "ternate",
    "timezone": "asia/jayapura",
    "offset": 540,
    "abbreviation": "wit",
    "alternateName": "eastern indonesia time",
    "country": "indonesia"
  },
  {
    "name": "tokyo",
    "timezone": "asia/tokyo",
    "offset": 540,
    "abbreviation": "jst",
    "alternateName": "japan time",
    "country": "japan"
  },
  {
    "name": "yokohama",
    "timezone": "asia/tokyo",
    "offset": 540,
    "abbreviation": "jst",
    "alternateName": "japan time",
    "country": "japan"
  },
  {
    "name": "osaka",
    "timezone": "asia/tokyo",
    "offset": 540,
    "abbreviation": "jst",
    "alternateName": "japan time",
    "country": "japan"
  },
  {
    "name": "nagoya",
    "timezone": "asia/tokyo",
    "offset": 540,
    "abbreviation": "jst",
    "alternateName": "japan time",
    "country": "japan"
  },
  {
    "name": "pyongyang",
    "timezone": "asia/pyongyang",
    "offset": 540,
    "abbreviation": "kst",
    "alternateName": "korean time",
    "country": "north korea"
  },
  {
    "name": "hamhung",
    "timezone": "asia/pyongyang",
    "offset": 540,
    "abbreviation": "kst",
    "alternateName": "korean time",
    "country": "north korea"
  },
  {
    "name": "namp'o",
    "timezone": "asia/pyongyang",
    "offset": 540,
    "abbreviation": "kst",
    "alternateName": "korean time",
    "country": "north korea"
  },
  {
    "name": "sunch'on",
    "timezone": "asia/pyongyang",
    "offset": 540,
    "abbreviation": "kst",
    "alternateName": "korean time",
    "country": "north korea"
  },
  {
    "name": "seoul",
    "timezone": "asia/seoul",
    "offset": 540,
    "abbreviation": "kst",
    "alternateName": "korean time",
    "country": "south korea"
  },
  {
    "name": "busan",
    "timezone": "asia/seoul",
    "offset": 540,
    "abbreviation": "kst",
    "alternateName": "korean time",
    "country": "south korea"
  },
  {
    "name": "incheon",
    "timezone": "asia/seoul",
    "offset": 540,
    "abbreviation": "kst",
    "alternateName": "korean time",
    "country": "south korea"
  },
  {
    "name": "daegu",
    "timezone": "asia/seoul",
    "offset": 540,
    "abbreviation": "kst",
    "alternateName": "korean time",
    "country": "south korea"
  },
  {
    "name": "ngerulmud",
    "timezone": "pacific/palau",
    "offset": 540,
    "abbreviation": "pwt",
    "alternateName": "palau time",
    "country": "palau"
  },
  {
    "name": "chita",
    "timezone": "asia/chita",
    "offset": 540,
    "abbreviation": "yakt",
    "alternateName": "yakutsk time",
    "country": "russia"
  },
  {
    "name": "yakutsk",
    "timezone": "asia/chita",
    "offset": 540,
    "abbreviation": "yakt",
    "alternateName": "yakutsk time",
    "country": "russia"
  },
  {
    "name": "blagoveshchensk",
    "timezone": "asia/chita",
    "offset": 540,
    "abbreviation": "yakt",
    "alternateName": "yakutsk time",
    "country": "russia"
  },
  {
    "name": "belogorsk",
    "timezone": "asia/chita",
    "offset": 540,
    "abbreviation": "yakt",
    "alternateName": "yakutsk time",
    "country": "russia"
  },
  {
    "name": "adelaide",
    "timezone": "australia/adelaide",
    "offset": 570,
    "abbreviation": "acst",
    "alternateName": "australian central time",
    "country": "australia"
  },
  {
    "name": "adelaide hills",
    "timezone": "australia/adelaide",
    "offset": 570,
    "abbreviation": "acst",
    "alternateName": "australian central time",
    "country": "australia"
  },
  {
    "name": "mount gambier",
    "timezone": "australia/adelaide",
    "offset": 570,
    "abbreviation": "acst",
    "alternateName": "australian central time",
    "country": "australia"
  },
  {
    "name": "morphett vale",
    "timezone": "australia/adelaide",
    "offset": 570,
    "abbreviation": "acst",
    "alternateName": "australian central time",
    "country": "australia"
  },
  {
    "name": "darwin",
    "timezone": "australia/darwin",
    "offset": 570,
    "abbreviation": "acst",
    "alternateName": "australian central time",
    "country": "australia"
  },
  {
    "name": "alice springs",
    "timezone": "australia/darwin",
    "offset": 570,
    "abbreviation": "acst",
    "alternateName": "australian central time",
    "country": "australia"
  },
  {
    "name": "palmerston",
    "timezone": "australia/darwin",
    "offset": 570,
    "abbreviation": "acst",
    "alternateName": "australian central time",
    "country": "australia"
  },
  {
    "name": "brisbane",
    "timezone": "australia/brisbane",
    "offset": 600,
    "abbreviation": "aest",
    "alternateName": "australian eastern standard time",
    "country": "australia"
  },
  {
    "name": "gold coast",
    "timezone": "australia/brisbane",
    "offset": 600,
    "abbreviation": "aest",
    "alternateName": "australian eastern standard time",
    "country": "australia"
  },
  {
    "name": "logan city",
    "timezone": "australia/brisbane",
    "offset": 600,
    "abbreviation": "aest",
    "alternateName": "australian eastern standard time",
    "country": "australia"
  },
  {
    "name": "townsville",
    "timezone": "australia/brisbane",
    "offset": 600,
    "abbreviation": "aest",
    "alternateName": "australian eastern standard time",
    "country": "australia"
  },
  {
    "name": "sydney",
    "timezone": "australia/sydney",
    "offset": 600,
    "abbreviation": "aest",
    "alternateName": "australian eastern standard time",
    "country": "australia"
  },
  {
    "name": "melbourne",
    "timezone": "australia/sydney",
    "offset": 600,
    "abbreviation": "aest",
    "alternateName": "australian eastern standard time",
    "country": "australia"
  },
  {
    "name": "canberra",
    "timezone": "australia/sydney",
    "offset": 600,
    "abbreviation": "aest",
    "alternateName": "australian eastern standard time",
    "country": "australia"
  },
  {
    "name": "newcastle",
    "timezone": "australia/sydney",
    "offset": 600,
    "abbreviation": "aest",
    "alternateName": "australian eastern standard time",
    "country": "australia"
  },
  {
    "name": "dededo village",
    "timezone": "pacific/guam",
    "offset": 600,
    "abbreviation": "chst",
    "alternateName": "chamorro time",
    "country": "guam"
  },
  {
    "name": "yigo village",
    "timezone": "pacific/guam",
    "offset": 600,
    "abbreviation": "chst",
    "alternateName": "chamorro time",
    "country": "guam"
  },
  {
    "name": "tamuning-tumon-harmon village",
    "timezone": "pacific/guam",
    "offset": 600,
    "abbreviation": "chst",
    "alternateName": "chamorro time",
    "country": "guam"
  },
  {
    "name": "tamuning",
    "timezone": "pacific/guam",
    "offset": 600,
    "abbreviation": "chst",
    "alternateName": "chamorro time",
    "country": "guam"
  },
  {
    "name": "saipan",
    "timezone": "pacific/saipan",
    "offset": 600,
    "abbreviation": "chst",
    "alternateName": "chamorro time",
    "country": "northern mariana islands"
  },
  {
    "name": "chuuk",
    "timezone": "pacific/chuuk",
    "offset": 600,
    "abbreviation": "chut",
    "alternateName": "chuuk time",
    "country": "micronesia"
  },
  {
    "name": "dumontdurville",
    "timezone": "antarctica/dumontdurville",
    "offset": 600,
    "abbreviation": "ddut",
    "alternateName": "dumont-d'urville time",
    "country": "antarctica"
  },
  {
    "name": "port moresby",
    "timezone": "pacific/port_moresby",
    "offset": 600,
    "abbreviation": "pgt",
    "alternateName": "papua new guinea time",
    "country": "papua new guinea"
  },
  {
    "name": "lae",
    "timezone": "pacific/port_moresby",
    "offset": 600,
    "abbreviation": "pgt",
    "alternateName": "papua new guinea time",
    "country": "papua new guinea"
  },
  {
    "name": "mount hagen",
    "timezone": "pacific/port_moresby",
    "offset": 600,
    "abbreviation": "pgt",
    "alternateName": "papua new guinea time",
    "country": "papua new guinea"
  },
  {
    "name": "popondetta",
    "timezone": "pacific/port_moresby",
    "offset": 600,
    "abbreviation": "pgt",
    "alternateName": "papua new guinea time",
    "country": "papua new guinea"
  },
  {
    "name": "vladivostok",
    "timezone": "asia/vladivostok",
    "offset": 600,
    "abbreviation": "vlat",
    "alternateName": "vladivostok time",
    "country": "russia"
  },
  {
    "name": "khabarovsk",
    "timezone": "asia/vladivostok",
    "offset": 600,
    "abbreviation": "vlat",
    "alternateName": "vladivostok time",
    "country": "russia"
  },
  {
    "name": "khabarovsk vtoroy",
    "timezone": "asia/vladivostok",
    "offset": 600,
    "abbreviation": "vlat",
    "alternateName": "vladivostok time",
    "country": "russia"
  },
  {
    "name": "komsomolsk-on-amur",
    "timezone": "asia/vladivostok",
    "offset": 600,
    "abbreviation": "vlat",
    "alternateName": "vladivostok time",
    "country": "russia"
  },
  {
    "name": "lord howe",
    "timezone": "australia/lord_howe",
    "offset": 630,
    "abbreviation": "lhst",
    "alternateName": "lord howe time",
    "country": "australia"
  },
  {
    "name": "arawa",
    "timezone": "pacific/bougainville",
    "offset": 660,
    "abbreviation": "bst",
    "alternateName": "bougainville time",
    "country": "papua new guinea"
  },
  {
    "name": "casey",
    "timezone": "antarctica/casey",
    "offset": 660,
    "abbreviation": "cast",
    "alternateName": "casey time",
    "country": "antarctica"
  },
  {
    "name": "kosrae",
    "timezone": "pacific/kosrae",
    "offset": 660,
    "abbreviation": "kost",
    "alternateName": "kosrae time",
    "country": "micronesia"
  },
  {
    "name": "palikir - national government center",
    "timezone": "pacific/kosrae",
    "offset": 660,
    "abbreviation": "kost",
    "alternateName": "kosrae time",
    "country": "micronesia"
  },
  {
    "name": "noumea",
    "timezone": "pacific/noumea",
    "offset": 660,
    "abbreviation": "nct",
    "alternateName": "new caledonia time",
    "country": "new caledonia"
  },
  {
    "name": "mont-dore",
    "timezone": "pacific/noumea",
    "offset": 660,
    "abbreviation": "nct",
    "alternateName": "new caledonia time",
    "country": "new caledonia"
  },
  {
    "name": "dumbea",
    "timezone": "pacific/noumea",
    "offset": 660,
    "abbreviation": "nct",
    "alternateName": "new caledonia time",
    "country": "new caledonia"
  },
  {
    "name": "kingston",
    "timezone": "pacific/norfolk",
    "offset": 660,
    "abbreviation": "nft",
    "alternateName": "norfolk island time",
    "country": "norfolk island"
  },
  {
    "name": "yuzhno-sakhalinsk",
    "timezone": "asia/sakhalin",
    "offset": 660,
    "abbreviation": "sakt",
    "alternateName": "sakhalin time",
    "country": "russia"
  },
  {
    "name": "magadan",
    "timezone": "asia/sakhalin",
    "offset": 660,
    "abbreviation": "sakt",
    "alternateName": "sakhalin time",
    "country": "russia"
  },
  {
    "name": "korsakov",
    "timezone": "asia/sakhalin",
    "offset": 660,
    "abbreviation": "sakt",
    "alternateName": "sakhalin time",
    "country": "russia"
  },
  {
    "name": "kholmsk",
    "timezone": "asia/sakhalin",
    "offset": 660,
    "abbreviation": "sakt",
    "alternateName": "sakhalin time",
    "country": "russia"
  },
  {
    "name": "honiara",
    "timezone": "pacific/guadalcanal",
    "offset": 660,
    "abbreviation": "sbt",
    "alternateName": "solomon islands time",
    "country": "solomon islands"
  },
  {
    "name": "port-vila",
    "timezone": "pacific/efate",
    "offset": 660,
    "abbreviation": "vut",
    "alternateName": "vanuatu time",
    "country": "vanuatu"
  },
  {
    "name": "suva",
    "timezone": "pacific/fiji",
    "offset": 720,
    "abbreviation": "fjt",
    "alternateName": "fiji time",
    "country": "fiji"
  },
  {
    "name": "lautoka",
    "timezone": "pacific/fiji",
    "offset": 720,
    "abbreviation": "fjt",
    "alternateName": "fiji time",
    "country": "fiji"
  },
  {
    "name": "nadi",
    "timezone": "pacific/fiji",
    "offset": 720,
    "abbreviation": "fjt",
    "alternateName": "fiji time",
    "country": "fiji"
  },
  {
    "name": "labasa",
    "timezone": "pacific/fiji",
    "offset": 720,
    "abbreviation": "fjt",
    "alternateName": "fiji time",
    "country": "fiji"
  },
  {
    "name": "tarawa",
    "timezone": "pacific/tarawa",
    "offset": 720,
    "abbreviation": "gilt",
    "alternateName": "gilbert islands time",
    "country": "kiribati"
  },
  {
    "name": "majuro",
    "timezone": "pacific/majuro",
    "offset": 720,
    "abbreviation": "mht",
    "alternateName": "marshall islands time",
    "country": "marshall islands"
  },
  {
    "name": "kwajalein",
    "timezone": "pacific/majuro",
    "offset": 720,
    "abbreviation": "mht",
    "alternateName": "marshall islands time",
    "country": "marshall islands"
  },
  {
    "name": "rmi capitol",
    "timezone": "pacific/majuro",
    "offset": 720,
    "abbreviation": "mht",
    "alternateName": "marshall islands time",
    "country": "marshall islands"
  },
  {
    "name": "yaren",
    "timezone": "pacific/nauru",
    "offset": 720,
    "abbreviation": "nrt",
    "alternateName": "nauru time",
    "country": "nauru"
  },
  {
    "name": "auckland",
    "timezone": "pacific/auckland",
    "offset": 720,
    "abbreviation": "nzst",
    "alternateName": "new zealand time",
    "country": "new zealand"
  },
  {
    "name": "wellington",
    "timezone": "pacific/auckland",
    "offset": 720,
    "abbreviation": "nzst",
    "alternateName": "new zealand time",
    "country": "new zealand"
  },
  {
    "name": "christchurch",
    "timezone": "pacific/auckland",
    "offset": 720,
    "abbreviation": "nzst",
    "alternateName": "new zealand time",
    "country": "new zealand"
  },
  {
    "name": "manukau city",
    "timezone": "pacific/auckland",
    "offset": 720,
    "abbreviation": "nzst",
    "alternateName": "new zealand time",
    "country": "new zealand"
  },
  {
    "name": "mcmurdo",
    "timezone": "antarctica/mcmurdo",
    "offset": 720,
    "abbreviation": "nzst",
    "alternateName": "new zealand time",
    "country": "antarctica"
  },
  {
    "name": "petropavlovsk-kamchatsky",
    "timezone": "asia/kamchatka",
    "offset": 720,
    "abbreviation": "pett",
    "alternateName": "petropavlovsk-kamchatski time",
    "country": "russia"
  },
  {
    "name": "yelizovo",
    "timezone": "asia/kamchatka",
    "offset": 720,
    "abbreviation": "pett",
    "alternateName": "petropavlovsk-kamchatski time",
    "country": "russia"
  },
  {
    "name": "vilyuchinsk",
    "timezone": "asia/kamchatka",
    "offset": 720,
    "abbreviation": "pett",
    "alternateName": "petropavlovsk-kamchatski time",
    "country": "russia"
  },
  {
    "name": "anadyr",
    "timezone": "asia/kamchatka",
    "offset": 720,
    "abbreviation": "pett",
    "alternateName": "petropavlovsk-kamchatski time",
    "country": "russia"
  },
  {
    "name": "funafuti",
    "timezone": "pacific/funafuti",
    "offset": 720,
    "abbreviation": "tvt",
    "alternateName": "tuvalu time",
    "country": "tuvalu"
  },
  {
    "name": "wake",
    "timezone": "pacific/wake",
    "offset": 720,
    "abbreviation": "wakt",
    "alternateName": "wake island time",
    "country": "united states minor outlying islands"
  },
  {
    "name": "mata-utu",
    "timezone": "pacific/wallis",
    "offset": 720,
    "abbreviation": "wft",
    "alternateName": "wallis & futuna time",
    "country": "wallis and futuna"
  },
  {
    "name": "chatham",
    "timezone": "pacific/chatham",
    "offset": 765,
    "abbreviation": "chast",
    "alternateName": "chatham time",
    "country": "new zealand"
  },
  {
    "name": "apia",
    "timezone": "pacific/apia",
    "offset": 780,
    "abbreviation": "wst",
    "alternateName": "apia time",
    "country": "samoa"
  },
  {
    "name": "fakaofo",
    "timezone": "pacific/fakaofo",
    "offset": 780,
    "abbreviation": "tkt",
    "alternateName": "tokelau time",
    "country": "tokelau"
  },
  {
    "name": "nuku'alofa",
    "timezone": "pacific/tongatapu",
    "offset": 780,
    "abbreviation": "tot",
    "alternateName": "tonga time",
    "country": "tonga"
  },
  {
    "name": "kiritimati",
    "timezone": "pacific/kiritimati",
    "offset": 840,
    "abbreviation": "lint",
    "alternateName": "line islands time",
    "country": "kiribati"
  }
]

export default timezones;