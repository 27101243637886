import React, { useState } from 'react'
import { Popover } from '@headlessui/react'

import {
  CloudRain,
  Tree,
  Keyboard,
  Snow3,
  Soundwave,
  CloudLightning,
  Tsunami,
  Twitter,
  Water,
  Droplet,
  Vinyl,
  MusicPlayer,
} from 'react-bootstrap-icons'
import SoundCard from './SoundCard'

const Soundscapes = () => {
  const soundsList = [
    {
      id: 1,
      name: 'Rain',
      icon: <CloudRain size={32} />,
      audio: 'assets/sounds/rain.mp3',
    },
    {
      id: 2,
      name: 'waves',
      icon: <Tsunami size={32} />,
      audio: 'assets/sounds/waves.mp3',
    },
    {
      id: 3,
      name: 'Tree',
      icon: <Tree size={32} />,
      audio: 'assets/sounds/forest.mp3',
    },
    {
      id: 4,
      name: 'Fire',
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          fill="currentColor"
          className="bi bi-fire"
          viewBox="0 0 16 16"
        >
          <path d="M8 16c3.314 0 6-2 6-5.5 0-1.5-.5-4-2.5-6 .25 1.5-1.25 2-1.25 2C11 4 9 .5 6 0c.357 2 .5 4-2 6-1.25 1-2 2.729-2 4.5C2 14 4.686 16 8 16Zm0-1c-1.657 0-3-1-3-2.75 0-.75.25-2 1.25-3C6.125 10 7 10.5 7 10.5c-.375-1.25.5-3.25 2-3.5-.179 1-.25 2 1 3 .625.5 1 1.364 1 2.25C11 14 9.657 15 8 15Z" />
        </svg>
      ),
      audio: 'assets/sounds/bonfire.mp3',
    },
    {
      id: 5,
      name: 'Water',
      icon: <Water size={32} className="" />,
      audio: 'assets/sounds/water.mp3',
    },
    {
      id: 6,
      name: 'Keyboard',
      icon: <Keyboard size={32} />,
      audio: 'assets/sounds/keyboard.mp3',
    },
    {
      id: 7,
      name: 'River Stream',
      icon: <Droplet size={32} />,
      audio: 'assets/sounds/stream.mp3',
    },
    {
      id: 8,
      name: 'Birds',
      icon: <Twitter className="rotate-45" size={32} />,
      audio: 'assets/sounds/birds.mp3',
    },
    {
      id: 9,
      name: 'Lofi',
      icon: <Vinyl size={32} />,
      audio: 'assets/sounds/lofi1.mp3',
    },
    {
      id: 10,
      name: 'Lofi2',
      icon: <MusicPlayer size={32} />,
      audio: 'assets/sounds/lofi2.mp3',
    },
  ]
  return (
    <Popover className="md:relative">
      {({ open }) => (
        <>
          <Popover.Button
            aria-label="soundscapes"
            id="soundscapes"
            className={` 
            ${
              open
                ? 'md:bg-slate-900/75 md:text-white md:dark:bg-white md:dark:text-slate-800'
                : 'md:my-card-base text-slate-800 dark:text-white'
            } flex flex-col items-center justify-center rounded outline-none  md:h-16 md:w-28`}
          >
            <Soundwave
              size={20}
              className="h-12 w-16 px-3 py-2 md:h-8 md:w-8 md:px-0 md:py-0"
            />
            <span className="hidden text-xs md:block">SoundScapes</span>
          </Popover.Button>
          <Popover.Panel
            static
            className={`${
              open ? `visible` : `hidden`
            } md:my-card md: md:my-card absolute bottom-14 left-5 z-10 h-[80vh] min-w-[90%] max-w-[90vw] cursor-default  overflow-hidden rounded-lg bg-gray-800 p-4 outline-none xs:left-10 sm:w-[400px] sm:max-w-[400px] md:-bottom-[37px] md:right-0 md:left-[120px] md:h-[550px]`}
          >
            <div className="flex flex-col gap-3">
              <p className="text-center text-xl font-medium">Sound scapes</p>
              {/* <p>Presets</p>
              <div className="flex  gap-2 items-center  scrollbar text-black overflow-auto ">
                <div className="h-14 w-28 rounded flex-shrink-0 bg-white"></div>
                <div className="h-14 w-28 rounded flex-shrink-0 bg-white"></div>
                <div className="h-14 w-28 rounded flex-shrink-0 bg-white"></div>
              </div> */}
              <div className="scrollbar grid grid-cols-3 gap-2 overflow-hidden overflow-y-auto pb-10 xs:pb-5 sm:grid-cols-4 ">
                {soundsList.map((sound) => (
                  <SoundCard
                    key={sound.id}
                    sound={sound}
                    soundsList={soundsList}
                  />
                ))}
              </div>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  )
}

export default Soundscapes
