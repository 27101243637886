import React, { useContext, useState } from 'react'
import { PlusCircle, ArrowUpCircle } from 'react-bootstrap-icons'
import ThemeContext from '../../context/ThemeContext'
import TodoContext from '../../context/TodoContext'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'

dayjs.extend(calendar)
const AddTodo = () => {
  const { todo, handleChange, handleAddTodo, timerRef, handleAddTodoToTop } =
    useContext(TodoContext)
  const { theme } = useContext(ThemeContext)
  const { task, priority, deadline, timer } = todo
  // id: '',
  // task: '',
  // priority: 4,
  // isComplete: false,
  // timer: 45,
  // deadline: dayjs().format('YYYY-MM-DD'),
  const priorityColor =
    priority === '1'
      ? 'bg-red-500'
      : priority === '2'
      ? 'bg-orange-500'
      : priority === '3'
      ? 'bg-blue-500'
      : 'bg-gray-600'
  const priorityBorder =
    priority === '1'
      ? 'border-l-red-500'
      : priority === '2'
      ? 'border-l-orange-500'
      : priority === '3'
      ? 'border-l-blue-500'
      : 'border-l-gray-600'

  const [toggleDate, setToggleDate] = useState(true)
  const handleToggleDate = () => {
    setToggleDate(!toggleDate)
  }
  return (
    <>
      <form
        className={` todo-item  ${priorityBorder}`}
        onSubmit={(e) => handleAddTodo(e)}
      >
        <input
          autoComplete="off"
          required={true}
          name="task"
          id="task"
          type="text"
          value={task}
          onChange={handleChange}
          placeholder="Add a task"
          className="input-item"
        />
        <div className="flex items-center justify-between">
          <div className="flex  items-center">
            {toggleDate ? (
              <span
                className="todo-badge px-2 py-1 text-sm"
                onClick={handleToggleDate}
              >
                {dayjs(deadline).isValid()
                  ? dayjs(deadline).calendar(null, {
                      lastDay: '[Yesterday]',
                      sameDay: '[Today]',
                      nextDay: '[Tomorrow]',
                      lastWeek: 'ddd, D MMM',
                      nextWeek: 'ddd, D MMM',
                      sameElse: 'ddd, D MMM',
                    })
                  : 'Add Deadline'}
              </span>
            ) : (
              <input
                // TODO
                autoFocus
                autoComplete="off"
                className="todo-badge w-36  leading-4"
                type="date"
                id="deadline"
                name="deadline"
                value={deadline}
                onChange={handleChange}
                onBlur={handleToggleDate}
              />
            )}
          </div>
          <div className="flex flex-row-reverse items-center gap-2">
            <button>
              <PlusCircle size={20} />
            </button>
            <button
              onClick={(e) => task.trim().length > 0 && handleAddTodoToTop(e)}
            >
              <ArrowUpCircle size={20} />
            </button>

            <select
              name="priority"
              className={`rounded px-2 py-1 text-xs font-medium  text-white  ${priorityColor}  flex appearance-none items-center justify-center  focus:outline-none`}
              value={priority}
              onChange={(e) => handleChange(e)}
            >
              <option
                value="1"
                className="flex items-center justify-center gap-1 rounded-full font-medium before:h-3 before:w-3 before:rounded-full before:bg-red-500  "
              >
                P1
              </option>
              <option
                value="2"
                className="flex h-3 w-3 items-center justify-center gap-1 rounded-full bg-orange-500 font-medium"
              >
                P2
              </option>
              <option
                value="3"
                className="flex h-3 w-3 items-center justify-center gap-1 rounded-full bg-blue-500 font-medium"
              >
                P3
              </option>
              <option
                value="4"
                className="flex h-3 w-3 items-center justify-center gap-1 rounded-full bg-gray-600 font-medium"
              >
                P4
              </option>
            </select>
            <input
              autoComplete="off"
              min={0}
              ref={timerRef}
              step="5"
              className="todo-badge  w-16"
              type="number"
              id="timer"
              name="timer"
              value={timer}
              onChange={handleChange}
            />
          </div>
        </div>
      </form>
    </>
  )
}

export default AddTodo
