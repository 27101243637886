import React, { useContext } from 'react'
import MainContext from '../context/MainContext'
import isMobile from '../helpers/isMobile'

const Background = () => {
  const { backgroundType, photo, video } = useContext(MainContext)
  const photoURL = photo.url
  const videoURL = video.url

  return (
    <>
      <div className="absolute left-0 top-0 flex h-screen w-screen items-center justify-center">
        <img src="%PUBLIC_URL%/assets/logo192.png" alt="Flow" />
      </div>
      <div data-bg={backgroundType}>
        {backgroundType === 'photo' || isMobile() ? (
          <picture
            data-image={photoURL}
            className="absolute left-0 right-0 h-screen w-screen object-cover transition-transform duration-1000 ease-in"
          >
            <img
              src={photoURL}
              alt={photo.name}
              className="absolute left-0 right-0 h-screen w-screen object-cover"
            />
          </picture>
        ) : (
          <div>
            <video
              data-video-url={videoURL}
              key={videoURL}
              autoPlay
              loop
              muted
              className={`fixed h-screen w-screen object-cover`}
            >
              <source src={videoURL} type="video/mp4" />
            </video>
          </div>
        )}
      </div>
    </>
  )
}

export default Background
