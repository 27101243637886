import React, { useContext, useState } from 'react'
import { Soundwave } from 'react-bootstrap-icons'
import ReactHowler from 'react-howler'
import { useDebouncedCallback } from 'use-debounce'
import MainContext from '../../../context/MainContext'
import { useEffect } from 'react'

const SoundCard = ({ sound, soundsList, isSoundPlaying }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [loading, setLoading] = useState(true)
  const [volume, setVolume] = useState(0.5)
  const debounced = useDebouncedCallback((value) => {
    setVolume(value)
  }, 5)
  //   const { isPlaying, setIsPlaying } = useContext(MainContext);

  return (
    <div
      className={`${
        isPlaying ? 'text-slate-800 dark:bg-white' : 'card-item hover-item'
      }  relative flex h-24  flex-col  items-center justify-center overflow-hidden rounded p-2 shadow-md`}
    >
      <div
        onClick={() => setIsPlaying((prev) => !prev)}
        className="flex h-14 w-full cursor-pointer items-center justify-center "
      >
        {sound.icon}
      </div>
      <ReactHowler
        src={sound.audio}
        playing={isPlaying}
        volume={volume}
        onLoad={() => setLoading(false)}
        loop
      />

      <input
        type="range"
        name="volume"
        id="volume"
        value={volume}
        className={`absolute bottom-2 h-1 w-4/6 accent-slate-800 ${
          isPlaying ? 'visible ' : 'invisible'
        }`}
        onChange={(e) => debounced(+e.target.value)}
        min="0"
        max="1"
        step="0.1"
      />
    </div>
  )
}

export default SoundCard
