import React, { useState, useContext } from 'react'
import TodoFocus from './TodoFocus'
import TodoList from './TodoList'
import TodoContext from '../../context/TodoContext'
import { ListTask, RecordCircle } from 'react-bootstrap-icons'
const Todo = () => {
  const { setIsRunning, isRunning, todos } = useContext(TodoContext)
  const [focus, setFocus] = useState(true)
  const handleToggleFocus = () => {
    setFocus((prev) => !prev)
  }

  return (
    <div className="my-card-base flex w-[350px] flex-col gap-2 rounded-lg p-2  ">
      {!isRunning ? (
        <div className="flex items-center justify-between">
          <span className="todo-badge text-base">
            {focus ? 'Focus' : "Today's Focus"}
          </span>
          <div className="flex items-center gap-2">
            {/* <button>
              <SortDown size={20} className="w-5 h-5" />
            </button> */}
            {todos.length > 0 && (
              <button
                aria-label="todo views"
                onClick={() => handleToggleFocus()}
              >
                {focus ? (
                  <div className="flex items-center  gap-1">
                    <ListTask size={24} className="h-6 w-6 " />
                  </div>
                ) : (
                  <RecordCircle size={20} className="h-5 w-5 " />
                )}
              </button>
            )}
          </div>
        </div>
      ) : (
        <p className="flex items-center justify-center px-2 text-lg font-medium leading-8 ">
          Keep Going 🎯
        </p>
      )}
      {focus && todos.length > 0 ? <TodoFocus /> : <TodoList />}
    </div>
  )
}

export default Todo
