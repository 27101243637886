import React, { useContext } from 'react'
import { Popover } from '@headlessui/react'
import WidgetContext from '../../../context/WidgetContext'
import ThemeContext from '../../../context/ThemeContext'
import { DashLg, PlusLg, ArrowClockwise } from 'react-bootstrap-icons'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import WidgetWrapper from '../../ui/WidgetWrapper'

const Counter = ({ counter }) => {
  const { theme } = useContext(ThemeContext)
  const { setWidgets } = useContext(WidgetContext)
  const { title, count, id } = counter

  const handleChange = (e) => {
    setWidgets((prev) =>
      prev.map((widget) =>
        widget.id === id
          ? {
              ...widget,
              [e.target.name]: e.target.value,
            }
          : widget,
      ),
    )
  }

  //Edit countdown object in countdowns array on save
  const onSave = (e) => {
    e.preventDefault()
    setWidgets((prev) =>
      prev.map((widget) => (widget.id === id ? { ...counter } : widget)),
    )
  }
  const onDelete = (e) => {
    e.preventDefault()
    setWidgets((prev) => prev.filter((widget) => widget.id !== id))
  }
  const handleReset = (e) => {
    e.preventDefault()
    const updatedCountItem = {
      ...counter,
      count: 0,
    }

    setWidgets((prev) =>
      prev.map((widget) => (widget.id === id ? updatedCountItem : widget)),
    )
  }

  const increaseCount = (e) => {
    e.preventDefault()
    const updatedCountItem = {
      ...counter,
      count: parseInt(count) + 1,
    }

    setWidgets((prev) =>
      prev.map((widget) => (widget.id === id ? updatedCountItem : widget)),
    )
  }
  const decreaseCount = (e) => {
    e.preventDefault()
    const updatedCountItem = {
      ...counter,
      count: parseInt(count) - 1,
    }

    setWidgets((prev) =>
      prev.map((widget) => (widget.id === id ? updatedCountItem : widget)),
    )
  }

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: counter.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Popover
      as="div"
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={`relative max-w-[150px] ${isDragging ? 'z-20' : ''} `}
    >
      {({ open }) => (
        <>
          <Popover.Button aria-label="counter widget" id="time" as="div">
            <WidgetWrapper open={open} id={counter.id}>
              <div className="z-10 flex w-full items-center justify-between gap-2 ">
                <button
                  className={`small-icon opacity-0  ${
                    count <= 0 ? 'opacity-0' : 'group-hover:opacity-100'
                  } ${open && 'bg-slate-900 text-white dark:text-slate-800'} `}
                  onClick={(e) => decreaseCount(e)}
                  disabled={count === 0}
                >
                  <DashLg size={14} />
                </button>
                <span>{count}</span>
                <button
                  className={`small-icon opacity-0 group-hover:opacity-100 ${
                    open && 'bg-slate-900 text-white dark:text-slate-800'
                  } `}
                  onClick={(e) => increaseCount(e)}
                >
                  <PlusLg size={14} />
                </button>
              </div>
              <span
                className={`text-sm ${
                  open
                    ? 'text-gray-200  dark:text-gray-700'
                    : 'dark:text-gray-300'
                }  `}
              >
                <p className="">
                  {title.length === 0 ? 'Streak' : title.trim()}
                </p>
              </span>
            </WidgetWrapper>
          </Popover.Button>
          <Popover.Panel className="absolute top-[75px] right-0 z-10 w-52">
            {({ close }) => (
              <form className="my-card-base flex flex-col items-center justify-center gap-2 rounded-lg p-2 text-black">
                <div className="w-full">
                  <label htmlFor="title" className="input-label">
                    Title
                  </label>
                  <input
                    autoComplete="off"
                    className="input-item mt-1 w-full"
                    type="text"
                    name="title"
                    id="title"
                    maxLength={18}
                    required
                    value={title}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex w-full items-center justify-between ">
                  <div className="w-full">
                    <label htmlFor="count" className="input-label">
                      Count
                    </label>
                    <input
                      className="input-item mt-1 w-3/5 cursor-default caret-white/0"
                      type="number"
                      name="count"
                      id="count"
                      min={0}
                      required
                      onKeyPress={(e) => {
                        e.preventDefault()
                      }}
                      placeholder="0"
                      value={count}
                      onChange={handleChange}
                    />
                  </div>
                  <span
                    onClick={(e) => handleReset(e)}
                    data-tip="Reset Counter to 0"
                    className="btn-sec tooltip tooltip-down mt-6 flex w-2/5 items-center justify-center gap-2 rounded"
                  >
                    <ArrowClockwise size={14} />
                    <span className="text-sm">Reset</span>
                  </span>
                </div>
                <div className="flex w-full flex-row-reverse items-center justify-between">
                  <button
                    type="submit"
                    onClick={(e) => {
                      onSave(e)
                      close()
                    }}
                    className="btn-cta disabled:bg-gray-300
                  disabled:text-gray-500"
                    disabled={title.length === 0}
                  >
                    Add Counter
                  </button>

                  <button onClick={onDelete} className="btn-sec">
                    Remove
                  </button>
                </div>
              </form>
            )}
          </Popover.Panel>
        </>
      )}
    </Popover>
  )
}

export default Counter
