import React, { Fragment } from 'react'
import isMobile from '../../../helpers/isMobile'
import { Tab } from '@headlessui/react'
import BgCurrentPanel from './BgCurrentPanel'
import BgUploadPanel from './BgUploadPanel'
import BgWallpapers from './BgWallpapers'
import BgVideoPanel from './BgVideoPanel'

const BackgroundSettings = () => {
  return (
    <Tab.Group as="div" className="flex w-full flex-col gap-2">
      <Tab.List className="flex gap-2">
        <Tab as={Fragment}>
          {({ selected }) => (
            <button
              className={` rounded px-2 text-left  text-sm focus:outline-none md:text-base
                        ${
                          selected &&
                          'bg-gray-900 text-white  dark:bg-white dark:text-slate-800'
                        }`}
            >
              Background
            </button>
          )}
        </Tab>
        <Tab as={Fragment}>
          {({ selected }) => (
            <button
              className={` rounded px-2 text-left  text-sm focus:outline-none md:text-base
                        ${
                          selected &&
                          'bg-gray-900 text-white  dark:bg-white dark:text-slate-800'
                        }`}
            >
              Photos
            </button>
          )}
        </Tab>
        {!isMobile() && (
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                className={` rounded px-2 text-left   text-sm focus:outline-none md:text-base
            ${
              selected &&
              'bg-gray-900 text-white  dark:bg-white dark:text-slate-800'
            }`}
              >
                Videos
              </button>
            )}
          </Tab>
        )}
        <Tab as={Fragment}>
          {({ selected }) => (
            <button
              className={` rounded px-2 text-left  text-sm focus:outline-none md:text-base
                        ${
                          selected &&
                          'bg-gray-900 text-white  dark:bg-white dark:text-slate-800'
                        }`}
            >
              Upload
            </button>
          )}
        </Tab>
      </Tab.List>
      <Tab.Panels className=" border-t-2 border-t-gray-300  py-1 dark:border-t-gray-500 md:border-t-0 ">
        <Tab.Panel>
          <BgCurrentPanel />
        </Tab.Panel>
        <Tab.Panel>
          <BgWallpapers />
        </Tab.Panel>
        {!isMobile() && (
          <Tab.Panel>
            <BgVideoPanel />
          </Tab.Panel>
        )}
        <Tab.Panel>
          <BgUploadPanel />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  )
}

export default BackgroundSettings
