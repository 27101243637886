import React, { useContext, useState } from 'react'
import TodoItem from './TodoItem'
import AddTodo from './AddTodo'
import TodoContext from '../../context/TodoContext'

import {
  DndContext,
  closestCenter,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers'
import {
  Inbox,
  TerminalDash,
  Trash,
  Trash2,
  Trash3,
  TrashFill,
} from 'react-bootstrap-icons'

const TodoList = () => {
  const [showFinishedTodos, setShowFinishedTodos] = useState(false)

  const toggleShowFinished = () => {
    setShowFinishedTodos((prev) => !prev)
  }

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  })

  const touchSensor = useSensor(TouchSensor, {
    // Press delay of 250ms, with tolerance of 5px of movement
    activationConstraint: {
      delay: 250,
      tolerance: 5,
    },
  })
  const sensors = useSensors(pointerSensor, touchSensor)
  const { todos, setTodos, finishedTodos, clearFinishedTodos } =
    useContext(TodoContext)

  function handleDragEnd(event) {
    const { active, over } = event

    if (active.id !== over.id) {
      setTodos((todos) => {
        const oldIndex = todos.findIndex((todo) => todo.id === active.id)
        const newIndex = todos.findIndex((todo) => todo.id === over.id)

        return arrayMove(todos, oldIndex, newIndex)
      })
    }
  }

  return (
    <>
      <div
        className="scrollbar flex max-h-[360px] flex-col gap-2
        overflow-hidden overflow-y-auto"
      >
        <DndContext
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={todos} strategy={verticalListSortingStrategy}>
            {todos.length > 0 ? (
              todos.map((todo, index) => (
                <TodoItem key={todo.id} todo={todo} index={index} />
              ))
            ) : (
              <div className="todo-item flex flex-col items-center justify-center border-none">
                <Inbox size={40} />
                <h2 className="text-lg font-medium">There are no tasks here</h2>
                <p className="text-sm">Add new tasks or Take a break</p>
                {/* <a className="btn-cta" href="https://rishikasyap.com">
                  Give me something to do
                </a> */}
              </div>
            )}
          </SortableContext>
        </DndContext>

        {finishedTodos.length > 0 && (
          <>
            <div className="divider text-sm before:bg-gray-400 after:bg-gray-400">
              <div
                className={`flex flex-row ${
                  showFinishedTodos ? 'justify-between' : 'justify-center'
                } text-center`}
              >
                <p
                  className="todo-badge text-center font-normal"
                  onClick={toggleShowFinished}
                >
                  {`${showFinishedTodos ? 'Hide' : 'Show'} Completed`}
                </p>
                {showFinishedTodos && (
                  <span
                    className="todo-badge flex items-center justify-center gap-1 text-sm font-normal"
                    onClick={clearFinishedTodos}
                  >
                    <Trash3 size={14} />
                    <p>Clear completed</p>
                  </span>
                )}
              </div>
            </div>

            {showFinishedTodos && (
              <div className="mt-1 flex cursor-default flex-col gap-2">
                {finishedTodos.map((todo) => (
                  <TodoItem
                    key={todo.id}
                    todo={todo}
                    done={true}
                    id={todo.id}
                  />
                ))}
              </div>
            )}
          </>
        )}
      </div>
      <AddTodo />
    </>
  )
}

export default TodoList
