import { MainProvider } from './context/MainContext'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Main from './components/shared/Main'
import { ThemeProvider } from './context/ThemeContext'
import { WidgetProvider } from './context/WidgetContext'
import { AuthProvider } from './context/AuthContext'
import { SiteBlockerProvider } from './context/SiteBlockerContext'
import { isExtension } from './helpers/isExtension'
import { TodoProvider } from './context/TodoContext'

const queryClient = new QueryClient()
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <AuthProvider>
          <MainProvider>
            <WidgetProvider>
              <TodoProvider>
                {isExtension() ? (
                  <SiteBlockerProvider>
                    <div className="App">
                      <Main />
                    </div>
                  </SiteBlockerProvider>
                ) : (
                  <div className="App">
                    <Main />
                  </div>
                )}
              </TodoProvider>
            </WidgetProvider>
          </MainProvider>
        </AuthProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

export default App
