import React, { useState } from 'react'
import { Popover } from '@headlessui/react'
import { Box } from 'react-bootstrap-icons'
import CardWithLink from '../../shared/CardWithLink'

const MentalModels = () => {
  const mentalModelList = [
    {
      id: 1,
      title: 'Mental Models',
      content: 'Learn How to Think Better and Gain a Mental Edge',
      link: 'https://jamesclear.com/mental-models',
    },
    {
      id: 2,
      title: 'Circle of Competence',
      content: 'avoid problems, identify opportunities, Learn from others',
      link: 'https://fs.blog/circle-of-competence/',
    },
    {
      id: 3,
      title: 'First Principles',
      content: 'Break down problems & Create solutions',
      link: 'https://untools.co/first-principles',
    },
    {
      id: 4,
      title: 'Second-order Thinking',
      content: 'Consider Long-term consquences of your decisions',
      link: 'https://untools.co/second-order-thinking',
    },
    {
      id: 5,
      title: 'Decision Matrix',
      content: 'Choose best option based on multiple factors',
      link: 'https://untools.co/decision-matrix',
    },
    {
      id: 6,
      title: 'Ishikawa Diagram',
      content: 'Identify root causes of the problem',
      link: 'https://untools.co/ishikawa-diagram',
    },
    {
      id: 7,
      title: 'Six Thinking Hats',
      content: 'Choose best option based on multiple factors',
      link: 'https://untools.co/six-thinking-hats',
    },
    {
      id: 8,
      title: 'Hindsight Bias',
      content: 'knew-it-all-along effect',
      link: 'https://www.farnamstreetblog.com/2009/08/what-is-hindsight-bias/',
    },
  ]
  return (
    <>
      <Popover className="md:relative">
        {({ open }) => (
          <>
            <Popover.Button
              aria-label="soundscapes"
              id="soundscapes"
              className={` 
            ${
              open
                ? 'md:bg-slate-900/75 md:text-white md:dark:bg-white md:dark:text-slate-800'
                : 'md:my-card-base text-slate-800 dark:text-white'
            } flex flex-col items-center justify-center rounded outline-none  md:h-16 md:w-28`}
            >
              <Box
                size={20}
                className=" h-12 w-16 px-3 py-2 md:h-7 md:w-7 md:px-0 md:py-0"
              />
              <span className="hidden text-xs md:block">Mental Models</span>
            </Popover.Button>
            <Popover.Panel
              className={`md:my-card md: md:my-card absolute bottom-14 left-5 z-10 h-[80vh] min-w-[90%] max-w-[90vw] cursor-default  overflow-hidden rounded-lg bg-gray-800 p-4 outline-none xs:left-10 sm:w-[400px] sm:max-w-[400px] md:-bottom-[241px] md:right-0 md:left-[120px] md:h-[550px]`}
            >
              <div className="flex flex-col gap-3">
                <p className="text-center text-xl font-medium">Mental Models</p>
                {/* <p>Presets</p>
              <div className="flex  gap-2 items-center  scrollbar text-black overflow-auto ">
                <div className="h-14 w-28 rounded flex-shrink-0 bg-white"></div>
                <div className="h-14 w-28 rounded flex-shrink-0 bg-white"></div>
                <div className="h-14 w-28 rounded flex-shrink-0 bg-white"></div>
              </div> */}
                <div className="scrollbar grid h-[480px] grid-cols-2 gap-2 overflow-hidden overflow-y-auto pb-10 md:pb-4 ">
                  {mentalModelList.map((mentalModel) => (
                    <CardWithLink
                      key={mentalModel.id}
                      title={mentalModel.title}
                      content={mentalModel.content}
                      link={mentalModel.link}
                    />
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </>
  )
}

export default MentalModels
