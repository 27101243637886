// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getAnalytics } from 'firebase/analytics'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAXFno5c0iTmUtrKYyjCAg7vSKCPBuZuCk',
  authDomain: 'getintoflow-app.firebaseapp.com',
  projectId: 'getintoflow-app',
  storageBucket: 'getintoflow-app.appspot.com',
  messagingSenderId: '587125680029',
  appId: '1:587125680029:web:da5998c1aa41530a6d3174',
  measurementId: 'G-NEHLXHKD4F',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const db = getFirestore(app)
export const auth = getAuth(app)

// const analytics = getAnalytics(app);
