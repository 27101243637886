import React, { createContext, useState, useEffect, useCallback } from 'react'
import useLocalStorageState from 'use-local-storage-state'
import { isExtension } from '../helpers/isExtension'
const MainContext = createContext()

export const MainProvider = ({ children }) => {
  // Time and Date

  const getDate = () => {
    return new Date().toLocaleDateString([], {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
    })
  }
  const getCurrentTime = () => {
    return new Date().toLocaleTimeString([], {
      hour: 'numeric',
      minute: '2-digit',
    })
  }

  const [time, setTime] = useState(getCurrentTime())
  const [date, setDate] = useState(getDate())

  useEffect(() => {
    setDate(getDate())
  }, [])

  const updateTime = useCallback(() => {
    setTime(getCurrentTime())
    if (getCurrentTime() === '12:00 AM') {
      setDate(getDate())
    }
  }, [])

  useEffect(() => {
    const timer = setInterval(updateTime, 1000)
    return () => clearInterval(timer)
  }, [updateTime])

  // Background Photo and video

  const [backgroundType, setBackgroundType] = useLocalStorageState(
    'backgroundType',
    {
      defaultValue: 'photo',
    },
  )
  const [photo, setPhoto] = useLocalStorageState('photo', {
    defaultValue: {
      avgColor: '#6E7891',
      credit_url:
        'https://www.pexels.com/photo/photo-of-body-of-water-2649403/',
      name: 'Photo Of Body Of Water ',
      photographer: 'Quang Nguyen Vinh',
      photographer_url: 'https://www.pexels.com/@quang-nguyen-vinh-222549',
      thumbnail:
        'https://images.pexels.com/photos/2649403/pexels-photo-2649403.jpeg?auto=compress&cs=tinysrgb&h=130',
      url: 'https://images.pexels.com/photos/2649403/pexels-photo-2649403.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
    },
  })
  const [video, setVideo] = useLocalStorageState('video', {
    defaultValue: {
      credit_url:
        'https://www.pexels.com/video/drone-view-of-big-waves-rushing-to-the-shore-3571264/',
      name: 'Enrique Hoyos',
      preview_url:
        'https://player.vimeo.com/external/384761655.sd.mp4?s=383ab4dbc773cd0d5ece3af208d8f963368f67e4&profile_id=165&oauth2_token_id=57447761',
      thumbnail:
        'https://images.pexels.com/videos/3571264/free-video-3571264.jpg?auto=compress&cs=tinysrgb&fit=crop&h=630&w=1200',
      url: 'https://player.vimeo.com/external/384761655.hd.mp4?s=5eecd63d94629aa928726912a5601a7577a3ca8a&profile_id=170&oauth2_token_id=57447761',
      videographer: 'Enrique Hoyos',
      videographer_url: 'https://www.pexels.com/@enriquehoyos',
    },
  })

  // Settings - Toggle Widgets
  const [toggleMenu, setToggleMenu] = useLocalStorageState('toggleMenu', {
    defaultValue: {
      links: true,
      todos: true,
      search: true,
      topsites: false,
      widgets: true,
      quotes: true,
      clock: true,
      topSitesSingleRow: true,
      openLinkInNewTab: true,
    },
  })
  const handleToggleMenuChange = (e) => {
    setToggleMenu({ ...toggleMenu, [e.target.name]: e.target.checked })
  }

  // SoundScapes context
  const [isPlaying, setIsPlaying] = useState(false)

  // FIXME Uncomment this for extension

  const [topSites, setTopSites] = useState([])

  useEffect(() => {
    if (isExtension()) {
      chrome.topSites?.get((data) => {
        setTopSites(data || null)
      })
    }
  }, [])

  return (
    <MainContext.Provider
      value={{
        time,
        date,
        backgroundType,
        photo,
        video,
        topSites,
        toggleMenu,
        isPlaying,
        handleToggleMenuChange,
        setVideo,
        setPhoto,
        setBackgroundType,
        setIsPlaying,
      }}
    >
      {children}
    </MainContext.Provider>
  )
}
export default MainContext
