import React, { useContext } from 'react'
import { PlusSquare } from 'react-bootstrap-icons'
import LinksContext from '../../context/LinksContext'

const AddLink = () => {
  const { setIsLinkModalOpen } = useContext(LinksContext)

  return (
    <>
      <button
        onClick={() => setIsLinkModalOpen(true)}
        className="hover-item flex h-24 w-24 flex-col items-center justify-center gap-1 overflow-hidden rounded-lg "
      >
        <PlusSquare size={36} />
        <p className="truncate text-sm capitalize">Add Link</p>
      </button>
    </>
  )
}

export default AddLink
