import React, { useState, useEffect } from 'react'
import { Quote } from 'react-bootstrap-icons'
import quotes from '../data/quotes'

const Quotes = () => {
  //TODO dynamic qoutes from API

  const [quote, setQuote] = useState(quotes[0])
  useEffect(() => {
    const random = Math.floor(Math.random() * quotes.length)
    setQuote(quotes[random])
  }, [])

  return (
    <div
      id="quotes"
      className="my-card group inline-flex max-w-xs cursor-default select-none flex-col duration-200 lg:max-w-xs xl:max-w-lg"
    >
      {/* <span className="absolute font-medium font-serif italic top-0 left-0 text-2xl text-slate-400 z-0 ">
            <Quote />
          </span> */}
      <blockquote className={`z-10 text-center text-base lg:text-lg `}>
        {quote.quote}
      </blockquote>
      <p className="hidden text-center text-sm font-medium capitalize group-hover:block dark:text-gray-300">
        {quote.author}
      </p>
    </div>
  )
}

export default Quotes
