import React, { useContext } from 'react'

import SiteBlockerContext from '../../../context/SiteBlockerContext'
import BlockedSite from '../siteblocker/BlockedSite'

const SiteBlocker = () => {
  const {
    addToBlockList,
    newBlockURL,
    setNewBlockURL,
    blockList,
    enableBlocking,
    setEnableBlocking,
  } = useContext(SiteBlockerContext)
  return (
    <div className="flex flex-col gap-2">
      <p className=" text-xl">Block distracting websites</p>
      {/* <div className="flex gap-2  items-center">
        <p>Focus Mode</p>
        <input
          className="toggle"
          type="checkbox"
          checked={isFocusing}
          onChange={() => setIsFocusing((prev) => !prev)}
        />
      </div> */}
      <div className="flex w-full items-center justify-between gap-2 ">
        <label className="toggle-label">
          <span className="text-lg">Enable Blocking</span>
          <div className="relative">
            <input
              checked={enableBlocking}
              onChange={() => setEnableBlocking((prev) => !prev)}
              name="todos"
              id="todos"
              type="checkbox"
              className="peer sr-only"
            />
            <div className="toggle-line"></div>
            <div className=" toggle-dot"></div>
          </div>
        </label>
      </div>
      <p className="text-lg font-medium">
        Add distracting websites to block list
      </p>
      <form
        onSubmit={(e) => addToBlockList(e, e.target.value)}
        className="flex w-full flex-col gap-2"
      >
        <div className="flex items-center justify-between gap-2">
          <input
            autoComplete="off"
            type="text"
            name="blockUrl"
            id="blockUrl"
            placeholder="example.com"
            className="input-item w-full"
            value={newBlockURL || ''}
            onChange={(e) => setNewBlockURL(e.target.value)}
          />
          <button className="btn-cta">Block</button>
        </div>
      </form>
      <div className="divide"></div>
      <div className="flex flex-col gap-2">
        <p className=" text-lg font-medium">Blocked sites</p>
        {/* TODO if blocklist is empty use svg to prompt users to add websites to blocklist */}
        {blockList.length > 0 ? (
          <ul className="scrollbar flex max-h-[200px] flex-col gap-1 overflow-y-auto rounded-lg border border-white p-1">
            {blockList.map((item) => (
              <BlockedSite key={item.id} id={item.id} url={item.url} />
            ))}
          </ul>
        ) : (
          <div className="flex flex-col justify-center gap-2 text-center">
            <p className=" text-lg font-medium">No sites in Block list</p>
            <p className=" text-base">Add distracting websites to block list</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default SiteBlocker
