import React from 'react'
import { ArrowRight } from 'react-bootstrap-icons'

const CardWithLink = ({ title, content, link }) => {
  return (
    <a
      href={link}
      target="_blank"
      className="card-item group relative flex max-h-60 flex-col justify-between gap-1 rounded-lg p-2 md:h-36"
      rel="noreferrer"
    >
      <p className="tracking-snug text-lg font-semibold leading-tight">
        {title}
      </p>
      <p className="text-sm text-slate-600  dark:text-slate-100">{content}</p>
      <div className="flex  items-center gap-1 leading-tight  group-hover:text-sky-600 dark:group-hover:text-sky-400">
        <span className="text-sm">Learn now</span>
        <ArrowRight size={20} />
      </div>
    </a>
  )
}

export default CardWithLink
