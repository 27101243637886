import React, { useContext } from 'react'
import MainContext from '../../../context/MainContext'
import isMobile from '../../../helpers/isMobile'

const BgCurrentPanel = () => {
  const {
    photo,
    video,
    setPhoto,
    setVideo,
    backgroundType,
    setBackgroundType,
  } = useContext(MainContext)
  const handleBackgroundTypeChange = (e) => {
    e.preventDefault()
    setBackgroundType(e.target.value)
  }
  return (
    <div className=" scrollbar flex w-[100%] flex-col gap-4 divide-y divide-slate-500 overflow-auto">
      {backgroundType === 'photo' ? (
        <div
          className={`card-item flex flex-col items-center justify-center gap-2 overflow-hidden rounded-lg p-5 lg:flex-row`}
        >
          <img
            className={`h-[180px] w-full max-w-xs rounded-lg object-cover ${
              photo.avgColor ? `bg-[${photo.avgColor}]` : 'card-item'
            }`}
            src={photo.url}
            alt={photo.name}
          />
          <div className="flex flex-col gap-4">
            <span className="font-medium">{photo.name}</span>
            {photo.credit_url && (
              <p className="text-sm">
                <a className="underline" href={photo.credit_url}>
                  Photo
                </a>{' '}
                by{' '}
                <a className="underline" href={photo.photographer_url}>
                  {' '}
                  {photo.photographer}
                </a>{' '}
                on Pexels
              </p>
            )}
          </div>
        </div>
      ) : (
        <div className="  card-item flex flex-col gap-1 overflow-hidden rounded-lg p-5">
          <video
            src={video.preview_url}
            className="h-[180px] w-full rounded-lg object-cover"
            autoPlay
            loop
            muted
            alt={video.name || `video by ${video.user.name}`}
          />

          <span className="  flex flex-col justify-center  gap-4">
            {video.credit_url && (
              <p>
                <a className="underline " href={video.credit_url}>
                  Video
                </a>{' '}
                by{' '}
                <a className="underline" href={video.videographer_url}>
                  {video.videographer}
                </a>{' '}
                on Pexels
              </p>
            )}
          </span>
        </div>
      )}
      {/* Radio group to select backgroundType photo or video */}
      {!isMobile() && (
        <div className="flex flex-row items-center justify-between  gap-2">
          <label className="mt-2 text-base font-medium">Background Type</label>
          <form className="mt-2 flex flex-row items-center gap-5">
            <label className="flex items-center gap-1 text-sm font-medium">
              <input
                className="form-radio"
                type="radio"
                name="backgroundType"
                value="photo"
                checked={backgroundType === 'photo'}
                onChange={(e) => handleBackgroundTypeChange(e)}
              />
              Photo
            </label>
            <label className="flex items-center gap-1 text-sm font-medium">
              <input
                className="form-radio"
                type="radio"
                name="backgroundType"
                value="video"
                checked={backgroundType === 'video'}
                onChange={(e) => handleBackgroundTypeChange(e)}
              />
              Video
            </label>
          </form>
        </div>
      )}
    </div>
  )
}

export default BgCurrentPanel
