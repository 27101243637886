import React, { useContext, useState } from 'react'
import { Popover } from '@headlessui/react'
import WidgetContext from '../../../context/WidgetContext'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import CountdownTimer, { zeroPad } from 'react-countdown'
import ThemeContext from '../../../context/ThemeContext'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import WidgetWrapper from '../../ui/WidgetWrapper'

dayjs.extend(relativeTime)
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  // Render a countdown
  const d = days > 0 ? `${days}d ` : ''
  const s = days === 0 ? `${zeroPad(seconds)}s` : ''
  return <span>{`${d} ${hours}hr ${minutes}m ${s}`}</span>
}

const Countdown = ({ countdown }) => {
  const { theme } = useContext(ThemeContext)
  const { setWidgets } = useContext(WidgetContext)
  const { title, date, time, id } = countdown
  const [updatedCountdown, setUpdatedCountdown] = useState(countdown)
  //updatedCountdown - Countdown object with updated date and time
  //updatedCountdownItem - countdown object with updated details used to update the countdown object in countdowns array

  //Handling changes while editing the countdown
  const handleChange = (e) => {
    setUpdatedCountdown((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }))
  }

  //Edit countdown object in countdowns array on save
  const onSave = (e) => {
    e.preventDefault()
    const updatedCountdownItem = {
      ...updatedCountdown,
    }
    setWidgets((prev) =>
      prev.map((widget) => (widget.id === id ? updatedCountdownItem : widget)),
    )
  }
  const onDelete = (e) => {
    e.preventDefault()
    setWidgets((prev) => prev.filter((widget) => widget.id !== id))
  }
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: countdown.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Popover
      as="div"
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className={`relative  ${isDragging ? 'z-20' : ''} `}
    >
      {({ open }) => (
        <>
          <Popover.Button
            aria-label="countdown widget"
            id="time"
            className={`outline-none`}
          >
            <WidgetWrapper open={open} id={id}>
              <CountdownTimer date={`${date} ${time}`} renderer={renderer} />
              <span
                className={`text-sm ${
                  open
                    ? 'text-gray-200  dark:text-gray-700'
                    : 'dark:text-gray-300'
                }  `}
              >
                {title}
              </span>
            </WidgetWrapper>
          </Popover.Button>
          <Popover.Panel className="absolute top-[75px] right-0 z-10 w-60">
            {({ close }) => (
              <form className="my-card-base flex flex-col items-center justify-center gap-2 rounded-lg p-2 text-black ">
                <div className="w-full">
                  <label htmlFor="title" className="input-label">
                    Title
                  </label>
                  <input
                    autoComplete="off"
                    className="input-item mt-1 w-full"
                    type="text"
                    name="title"
                    id="title"
                    required
                    value={updatedCountdown.title}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full">
                  <label htmlFor="date" className="input-label">
                    Date
                  </label>
                  <input
                    className="input-item mt-1 w-full"
                    type="date"
                    name="date"
                    id="date"
                    value={updatedCountdown.date}
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full">
                  <label htmlFor="time" className="input-label">
                    Time
                  </label>
                  <input
                    className="input-item w-full"
                    type="time"
                    name="time"
                    id="time"
                    value={updatedCountdown.time}
                    onChange={handleChange}
                  />
                </div>
                <div className="flex w-full flex-row-reverse items-center justify-between">
                  <button
                    onClick={(e) => {
                      onSave(e)
                      close()
                    }}
                    className="btn-cta disabled:bg-gray-300 disabled:text-gray-500"
                    disabled={updatedCountdown.title.length === 0}
                  >
                    Add Countdown
                  </button>
                  <button onClick={onDelete} className="btn-sec">
                    Remove
                  </button>
                </div>
              </form>
            )}
          </Popover.Panel>
        </>
      )}
    </Popover>
  )
}

export default Countdown
