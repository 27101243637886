import React, { useContext } from 'react'
import dayjs from 'dayjs'
import TodoContext from '../../context/TodoContext'
import { CalendarDate } from 'react-bootstrap-icons'

const FocusTodoItem = ({ todo }) => {
  const {
    id,
    task,
    priority,
    isComplete,
    deadline,
    timeCompleted = '00:00',
  } = todo
  const { handleComplete, handleUndoComplete, formatTime } =
    useContext(TodoContext)
  const priorityBorder =
    priority === '1'
      ? 'border-l-red-500'
      : priority === '2'
      ? 'border-l-orange-500'
      : priority === '3'
      ? 'border-l-blue-500'
      : 'border-l-gray-600'

  const isAfter = dayjs().isAfter(dayjs(deadline), 'day')
  const isSame = dayjs().isSame(dayjs(deadline), 'day')

  return (
    <div className={`todo-item  ${priorityBorder}`}>
      <div className="flex items-center justify-between">
        <span
          className={` todo-badge flex items-center gap-1 px-2 py-1 text-sm ${
            isAfter
              ? 'text-red-500'
              : isSame
              ? 'text-amber-500'
              : 'text-slate-700  dark:text-slate-200'
          } ${isComplete && 'text-emerald-500 dark:text-emerald-400 '} `}
        >
          <CalendarDate size={16} className="text-slate-800 dark:text-white" />
          <span>
            {dayjs(deadline).isValid()
              ? dayjs(deadline).calendar(null, {
                  lastDay: '[Yesterday]',
                  sameDay: '[Today]',
                  nextDay: '[Tomorrow]',
                  lastWeek: 'ddd, D MMM',
                  nextWeek: 'ddd, D MMM',
                  sameElse: 'ddd, D MMM',
                })
              : 'Add Deadline'}
          </span>
        </span>
        {/* <span className="todo-badge flex items-center gap-1 px-2 py-1 text-sm">
          <HourglassSplit size={16} />
          <p>{formatTime(timeCompleted)}</p>
        </span> */}
      </div>
      <div className="flex w-full items-center gap-2">
        <input
          aria-label="todo checkbox"
          className="form-checkbox h-5 w-5 cursor-pointer rounded-full checked:bg-emerald-500 checked:hover:bg-emerald-500 focus:ring-0 focus:checked:bg-emerald-500 active:ring-0 dark:bg-neutral-600 dark:checked:bg-emerald-500"
          name="isComplete"
          type="checkbox"
          checked={isComplete}
          onChange={() =>
            isComplete ? handleUndoComplete(id) : handleComplete(id)
          }
        />
        <p className="w-full text-lg">{task}</p>
      </div>
    </div>
  )
}

export default FocusTodoItem
