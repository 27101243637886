import React, { useContext } from 'react'
import MainContext from '../../context/MainContext'
import TodoContext from '../../context/TodoContext'
import ThemeContext from '../../context/ThemeContext'
import Background from '../../features/Background'
import Search from '../../features/Search'
import Topsites from '../../features/Topsites'
import Links from '../links/Links'
import Widgets from '../../features/Widgets'
import Quotes from '../../features/Quotes'
import Todo from '../todo/Todo'
import ReactCanvasConfetti from 'react-canvas-confetti'
import Menu from './Menu'
import { Toaster } from 'react-hot-toast'
import { isExtension } from '../../helpers/isExtension'
import isMobile from '../../helpers/isMobile'
import { LinksProvider } from '../../context/LinksContext'

const Main = () => {
  const { toggleMenu } = useContext(MainContext)
  const { canvasStyles, getInstance } = useContext(TodoContext)
  const { theme } = useContext(ThemeContext)
  return (
    <div style={{ colorScheme: theme }}>
      <Background />
      <div className="relative">
        <LinksProvider>
          {toggleMenu.links && !isMobile() && <Links />}
        </LinksProvider>
        {
          <div className="hidden sm:block">
            <Widgets />
          </div>
        }
        <div className="flex h-screen flex-col items-center justify-center  ">
          {toggleMenu.search && !isMobile() && (
            <div className="">
              <Search />
            </div>
          )}
          {isExtension() && toggleMenu.topsites && !isMobile() && <Topsites />}
        </div>
        <div className="absolute bottom-0 flex w-full items-end justify-center p-2 md:justify-between">
          <Menu />
          {toggleMenu.quotes && (
            <div className="hidden justify-center md:block lg:absolute lg:bottom-0 lg:left-0 lg:flex lg:w-full lg:p-2 ">
              <div className="">
                <Quotes />
              </div>
            </div>
          )}

          {toggleMenu.todos && (
            <div className="absolute bottom-14 md:relative md:bottom-0 ">
              <Todo />
            </div>
          )}
        </div>
        <ReactCanvasConfetti
          refConfetti={getInstance}
          style={canvasStyles}
          className="z-30"
        />
      </div>

      <Toaster position="top-center" duration="2000" />
    </div>
  )
}

export default Main
